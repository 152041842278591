import { useState, useEffect } from "react";

// import { useResourceState } from '../../contexts/kitchencontext'
import NewSearch from "../../molecules/NewSearch";
import MembersList from '../../components/MembersList'
import ResourceRequestList from '../../components/ResourceRequestList'
import { ResourceType } from "../../data/types";
import { capitalizeString } from "../../utils/helpers";



export const AllMemberRequests: React.FC<{membershipRequests: any}> = ({membershipRequests}) => {
  const [ searchTerm, changeSearchTerm] = useState("")
  const kitchen_capacity =  1

  useEffect(() => {
    document.title = "HomeCook | Requests";
  }, [membershipRequests]);

  return (
    <div className="flex flex-col w-11/12 bg-white mx-auto my-6 rounded-md min-h-[777px]">
      <div className="flex flex-row w-full justify-between">
        <p className=" font-raleway ml-[24px] mt-[24px] text-[#8A8A8A] text-[16px]" >
          Showing 1 - 1 of {membershipRequests && membershipRequests.length ? membershipRequests.length : "0"} Member Requests
        </p>

        <div className="flex ml-auto w-1/4 mr-[24px]">
          <NewSearch searchType="member name" searchTerm={searchTerm} changeSearchTerm={changeSearchTerm} />
        </div>
      </div>

      <div className="w-11/12 mx-auto font-open-sans">
        {
          membershipRequests && membershipRequests.length ? (
            <MembersList kitchen_capacity={kitchen_capacity} members={membershipRequests ? membershipRequests : []} />
          ) : (
            <div className="flex flex-row justify-evenly font-light text-center text-[#013847]/40 ">
              No member requests to show
            </div>
          )
        }
      </div>
      
    </div>
  )
}



export const AllResourceRequests: React.FC<{resourceRequests: any, resourceType: ResourceType}> = ({resourceRequests, resourceType}) => {
  const [ searchTerm, changeSearchTerm] = useState("")
  // const kitchen_capacity =  1

  useEffect(() => {
    document.title = `HomeCook | ${capitalizeString(resourceType)} Requests`;
  }, [resourceType, resourceRequests]);

  return (
    <div className="flex flex-col w-11/12 bg-white mx-auto my-6 rounded-md min-h-[777px]">
      <div className="flex flex-row w-full justify-between">
        <p className=" font-raleway ml-[24px] mt-[24px] text-[#8A8A8A] text-[16px]" >
          Showing 1 - 1 of {resourceRequests && resourceRequests.length ? resourceRequests.length : "0"} {capitalizeString(resourceType)} Requests
        </p>

        <div className="flex ml-auto w-1/4 mr-[24px]">
          <NewSearch searchType="member name" searchTerm={searchTerm} changeSearchTerm={changeSearchTerm} />
        </div>
      </div>

      <div className="w-11/12 mx-auto font-open-sans">
        {
          resourceRequests && resourceRequests.length ? (
            <ResourceRequestList resourceType={resourceType} requests={resourceRequests ? resourceRequests : []} />
          ) : (
            <div className="flex flex-row justify-evenly font-light text-center text-[#013847]/40 ">
              No {resourceType} request to show
            </div>
          )
        }
      </div>
      
    </div>
  )
}


// export default AllMemberRequests;
