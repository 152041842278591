import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AddPlanModal, AddRequirementModal } from "../../molecules/AppModal";

import { ReactComponent as LeftArrow } from "../../icons/ScrollLeftArrow.svg";
import { useLoadCurrentResource, useResourceHook, useLoadResourceOptions, useLoadRequirements } from "../../hooks/kitchenhooks";
import { useLoadResourceBookings } from '../../hooks/memberhooks'
// import { useLoadRequests } from "../../hooks/memberhooks";
// import { useResourceState } from '../../contexts/kitchencontext';
// import { styleThousandCommas } from "../../utils/helpers";
import { ResourceType, SearchFilterOptionType, CommunityOptionType, ResourceSchema, WeekdayOptionFields, ResourceOptionsPropsType, PlanSchema, RequirementSchema } from "../../data/types";
import { capitalizeString, getChangedAttributes } from "../../utils/helpers";
import { ResourcePlansForm, ResourceInfoForm, ResourceImagesForm, ResourceRequirementsForm } from "../../molecules/forms";
import { ResourceProductDetails } from "../../molecules/ResourceProduct";
import { AllResourceRequests} from "../auth/RequestsPage";
import { DeleteConfirmationModal } from "../../molecules/AppModal";
import LoadingScreen from "./LoadingScreen";


// const openInNewTab = (url: string) => {
//   window.open(url, "_blank");
// };

type RouteParams = {
  resourceid: string;
  resourceType: ResourceType
};


type ResourceDetailsProps = {
  searchFilterOptions: SearchFilterOptionType
}
const ResourceDetails: React.FC<ResourceDetailsProps> = () => {

  const navigate = useNavigate()
  var { resourceid, resourceType } = useParams<RouteParams>();
  resourceType = resourceType ? resourceType : "kitchen"

  // var { communityId } = useResourceState()
  resourceid = resourceid ? resourceid : ""
  // communityId = communityId ? communityId : ""
  const { currentResource } = useLoadCurrentResource(resourceid)

  // const handleReloadResource = async () => {
  //   await reloadCurrentResource()
  // }

  // const { memberships } = useLoadMemberships();
  const { resourceBookings } = useLoadResourceBookings(resourceType);

  
  
  const [currentOption, setCurrentOption] = useState<CommunityOptionType>({id: "details", name: `${resourceType ? capitalizeString(resourceType) : "Resource"} Details`});
  const [resource, setResource] = useState<ResourceSchema>(currentResource as ResourceSchema)
  const resourceName = (resource && resource?.name) ? resource.name : "";
  const [isOpenPlan, setIsOpenPlan] = useState(false);
  const [isAddingCustomRequirement, setIsAddingCustomRequirement] = useState(false);

  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const { updateResource, updateOptions, deleteResource, isLoading } = useResourceHook({resourceId: resourceid, resourceType})
  const { requirements, addCustomRequirement } = useLoadRequirements()

  // const {reloadCurrentResource} = useLoadCurrentResource(resourceid)

  const updateResourceData =  async (resource: ResourceSchema) => {
    if (resourceid && currentResource){
      var changes = getChangedAttributes(currentResource, resource);

      // console.log("resource id", resourceid)
      // console.log("resource changes", changes)
      if (Object.keys(changes).length > 0){
        updateResource(resourceid, changes);
      }
    }
  };


  const handleAddCustomRequirement = async (req: RequirementSchema) => {
    if(req?.name){
      await addCustomRequirement(req)
    }
  }

  const handleDelete = () => {
    deleteResource()
    setIsDeleting(false)
    navigate(-1)
  }
  
  useEffect(
    () => {
      document.title = `${resourceType ? capitalizeString(resourceType) : "Resource"} | Details`;
      setResource(currentResource as ResourceSchema)
    }, // eslint-disable-next-line
    [currentResource, resourceName ]
  );


  var resourceManagementOptions: CommunityOptionType[] = [
    {
      id: "details",
      name: `${capitalizeString(resourceType)} Details`
    },
    {
      id: "images",
      name: "Images"
    },
  ]

  if ("kitchen" === resourceType){
    resourceManagementOptions = [
      ...resourceManagementOptions, 
      {
        id: "plans",
        name: "Plans"
      },
      {
        id: "requirements",
        name: "Requirements"
      }
    ]
  }

  if (["kitchen", "training", "farm", "expert"].includes(resourceType)){
    resourceManagementOptions = [
      ...resourceManagementOptions, 
      {
        id: "items",
        name: (["training", "expert"].includes(resourceType)) ? "Schedules" : "Inventory"
      }
    ]
  }

  resourceManagementOptions = [
    ...resourceManagementOptions, 
    {
      id: "requests",
      name: `${capitalizeString(resourceType)} Requests (${resourceBookings && resourceBookings.length > 0 ? resourceBookings.length : 0})`
    }
  ]
  

  const { getResourceIconData } = useLoadResourceOptions()

  const resourceCategory = getResourceIconData(resourceType).name

  // const { updateOptions } = useKitchenHook()


  const updateResourceOptions = async (updateData: ResourceOptionsPropsType) => {
    if (resourceid && currentResource){

      const { resourceOptions, selectedDays } = updateData

      // console.log("resourceOptions", resourceOptions)
      // console.log("selectedDays", selectedDays)

      for (const day in selectedDays) {
        let resourceOption = resourceOptions[day as WeekdayOptionFields]
        if (!selectedDays[day] && resourceOption && resourceOptions[day as WeekdayOptionFields]){
          delete resourceOptions[day as WeekdayOptionFields]
        }
      }

      if (resourceOptions && Object.keys(resourceOptions).length > 0){
        // remove non-operating hours
        if (resourceOptions?.equipments) {delete resourceOptions['equipments']}
        if (resourceOptions?.closed_hours) {delete resourceOptions['closed_hours']}
        if (resourceOptions?.services) {delete resourceOptions['services']}
        if (resourceOptions?.features) {delete resourceOptions['features']}
        if (resourceOptions?.last_updated) {delete resourceOptions['last_updated']}
        
        updateOptions(resourceid, resourceOptions)
      }
    }
  };

  return (
    <>
      <div className="flex flex-row bg-white h-[68px] mt-[72px] w-full">
        <p className="text-[#808080] font-raleway text-[20px] my-auto mx-16">
          {resourceCategory} 
          &nbsp;/&nbsp;
          {currentResource?.name ? currentResource.name : ""}
          &nbsp;/&nbsp;
          <span className="text-primary-200 font-bold">Manage {capitalizeString(resourceType)}</span>
        </p>
        <div className="flex flex-row ml-auto mx-16">
          <button 
            className="flex flex-row mx-2 font-raleway text-[16px] text-[#8A8A8A] my-auto p-2"
            onClick={()=> navigate(-1)}
          >
            <LeftArrow  className="my-auto mx-1"/> 
            <p className="my-auto mx-1">Go Back</p>
          </button>
          <button onClick={()=>setIsDeleting(true)} className="mx-2 font-raleway text-[16px] text-[#D42620] font-medium my-auto border-[1px] border-[#E9E9E9] p-2 rounded-lg">Delete {resourceCategory}</button>
        </div>
      </div>
      <div className="flex flex-row bg-white h-[75px] border-[#E9E9E9] border-[1px] w-full">
        <span className="flex flex-row mx-16">
        {
          resourceManagementOptions.map((option: CommunityOptionType, i: number) => {
            return (
              <div 
                key={i} 
                onClick={()=>setCurrentOption(option)} 
                className={`${(currentOption.id === option.id) ? 'text-white bg-primary-200' : 'text-[#4D4D4D] '} mr-4 my-auto text-[16px] font-raleway border-[1px] border-[#E9E9E9] py-2 px-4 rounded-md cursor-pointer`}
              >
                  {option.name}
              </div>
            )
          })
        }
        </span>
      </div>

      {
        (isLoading) ? (
          <LoadingScreen />
        ) : (
          (currentOption.id === "images" ) ? (
            <ResourceImagesForm resourceType={resourceType} resource={resource} setResource={setResource} updateResourceData={updateResourceData} currentResource={currentResource as ResourceSchema} />
          ) : (
            (currentOption.id === "plans" ) ? (
              <ResourcePlansForm resourceId={currentResource?.id as string} resourcePlans={currentResource?.plans as PlanSchema[]} setIsOpenPlan={setIsOpenPlan} />
            ) : (
              (currentOption.id === "requirements" ) ? (
                <ResourceRequirementsForm 
                  resourceId={resourceid} 
                  resourceRequirements={currentResource?.requirements as RequirementSchema[]} 
                  communityRequirements={requirements} 
                  setIsAddingCustomRequirement={setIsAddingCustomRequirement}
                />
              ) : (
                (currentOption.id === "requests") ? (
                  <AllResourceRequests resourceType={resourceType} resourceRequests={resourceBookings} />
                ) : (
                  (currentOption.id === "items") ? (
                    <ResourceProductDetails 
                      resourceType={resourceType} 
                      resource={resource} 
                      // setResource={setResource}  
                      updateResourceData={updateResourceData} 
                      // currentResource={currentResource as ResourceSchema} 
                      // handleReloadResource={handleReloadResource}
                    />
                  ) : (
                    <ResourceInfoForm 
                      resourceType={resourceType} 
                      resource={resource} 
                      // setResource={setResource}  
                      updateResourceData={updateResourceData} 
                      // currentResource={currentResource as ResourceSchema} 
                      updateResourceOptions={updateResourceOptions}
                    />
                  )
                )
              )
            )
          )
        )
      }
      {
        (currentResource && <AddPlanModal
          resource={currentResource}
          isOpen={isOpenPlan}
          closeAppModal={() => setIsOpenPlan(false)}
          />
        )
      }
      <AddRequirementModal
        isOpen={isAddingCustomRequirement}
        closeAppModal={() => setIsAddingCustomRequirement(false)}
        handleAddCustomRequirement={handleAddCustomRequirement}
      />

      <DeleteConfirmationModal dataName="resource" isOpen={isDeleting} data={currentResource} handleDelete={handleDelete} closeDeleteModal={()=>setIsDeleting(false)} />
    </> 
  )
}


export default ResourceDetails;
