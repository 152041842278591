// import homecook_logo from '../assets/img/homecook_logo.png'
import { useLocation } from 'react-router-dom';
import NewSearch from '../molecules/NewSearch';
import { useState } from 'react';
import { useAuthState } from '../contexts/authcontext'
// import { capitalizeString } from '../utils/helpers';
import { isResourcePath } from '../utils/helpers';

const Navbar = () => {

  const location = useLocation()
  const currentLocation = location.pathname
  var { isloggedin } = useAuthState()

  const [searchTerm, changeSearchTerm] = useState("")

  const getPathName = (input: string): string => {
    return input.replace("/", "").replace(/^\w/, (c) => c.toUpperCase());
  }

  var currentPageName = getPathName(currentLocation)
  const resourceType = isResourcePath(currentPageName)
  // currentPageName = currentPageName ? (resourceType ? `Manage ${capitalizeString(resourceType)}` : currentPageName) : "Dashboard"
  currentPageName = currentPageName ? (resourceType ? `Resources` : currentPageName) : "Dashboard"

  return (
    <div className="flex flex-row bg-[#FFF] border-[1px] border-[#E9E9E9] fixed ml-2 w-full z-10 h-[72px]">
        <h1 className='text-black text-[20px] font-raleway font-bold my-auto ml-4'>{isloggedin ?  currentPageName : ""}</h1>
        <div className="flex flex-row items-center m-auto"> 
          <NewSearch searchType={currentPageName} searchTerm={searchTerm} changeSearchTerm={changeSearchTerm} />
      </div>
    </div>
  );
}

export default Navbar;
