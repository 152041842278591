import moment from "moment";
import { FilterOptionSchema, ResourceType, CredentialSchema, CurrentRequirementsSchema, RequirementSchema } from "../data/types";


export const fetchRequirementsLookUp = (requirements: RequirementSchema[], memberCredentials: CredentialSchema[], paymentMethods: any[]): CurrentRequirementsSchema[] => {
  var currentRequirements: any[] = []
  for (let i=0; i < requirements.length; i++){
    let requirementData = requirements[i] as CurrentRequirementsSchema
    // requirementData.isMet = false
    requirementData.isMet = memberCredentials ? memberCredentials.some((item) => (item["category"] === requirementData.code && item['is_valid'])) : false

    // isMet: (paymentMethods && paymentMethods.length > 0) ? true : false, 
    currentRequirements.push(requirementData)
  }

  currentRequirements = [
    ...currentRequirements,
    {
      code: 'PAY',
      name: 'Payment Method',
      isMet: (paymentMethods && paymentMethods.length > 0) ? true : false

    }
  ]
  return currentRequirements
}


  // Function to round time to nearest 30 minutes
  export const roundToNearest30Minutes = (inputTime: string) => {
    const momentTime = moment(inputTime, 'HH:mm');
    // Round to nearest 30 minutes
    const minutes = momentTime.minutes();
    const roundedMinutes = minutes < 15 ? 0 : minutes < 45 ? 30 : 0;
    return momentTime.minutes(roundedMinutes).format('HH:mm');
  };


  export const isSameOrLess = (timeString1: string, timeString2: string): boolean => {
    const moment1 = moment(timeString1, 'h:mmA');  // Use format string (not deprecated)
    const moment2 = moment(timeString2, 'h:mmA');  // Use format string (not deprecated)
    if (!moment1.isValid() || !moment2.isValid()) {
      throw new Error('Invalid time string format');
    }
    // Use isBefore or isSame for comparison
    return moment1.isBefore(moment2) || moment1.isSame(moment2);
  }


export const areArraysEqualByProps = <T extends object>(arr1: T[],arr2: T[],keys: (keyof T)[]): boolean => {
  if ((!arr1 && !arr2) || (arr1?.length !== arr2?.length)) {
    return false;
  }
  // Create a string representation of each object with only the specified properties
  const stringify = (obj: T): string => {
    const reduced = keys.reduce((acc, key) => {
      acc[key as string] = obj[key];
      return acc;
    }, {} as Record<string, any>);
    return JSON.stringify(reduced);
  };
  // Convert arrays to sorted strings for comparison
  const sortedStr1 = arr1.map(stringify).sort().join('|');
  const sortedStr2 = arr2.map(stringify).sort().join('|');
  // Compare sorted string representations
  return sortedStr1 === sortedStr2;
}


export const isResourcePath = (path: string) => {
  const resourceTypes: ResourceType[] = ['kitchen', 'documentation', 'training' ,'funding' ,'expert', 'farm']
  // return resourceTypes.some((item: ResourceType) => path.toLowerCase().includes(item.toLowerCase()));
  return resourceTypes.find((item: ResourceType) => path.toLowerCase().includes(item.toLowerCase()));
}


export const filterItemsByCategoryAndName = (data: FilterOptionSchema[], category: string, itemName: string): string[] => {
  var categoryData = data.find(cat => cat.category.toLowerCase() === category.toLowerCase());
  if (!categoryData || !categoryData?.items) {
    return [];
  }
  var categoryItems = categoryData.items
  categoryItems = categoryItems.filter(item => item.toLowerCase().includes(itemName.toLowerCase()));
  return categoryItems
}


export const getDirectLinkFromGoogleDriveSharedLink = (link: string | undefined): string => {
  // Regular expressions to match S3 and Google Drive shared links
  // const s3Regex = /^https:\/w\/s3\.amazonaws\.com\/.+\/.+$/;
  const googleDriveRegex = /^https:\/\/drive\.google\.com\/file\/d\/([a-zA-Z0-9_-]+)\/view\?usp=sharing$/;
  // console.log("link", link)
  if (link && link.includes('s3')) {
    // If it matches the S3 regex, return the link as is
    // console.log("s3 link", link)
    return link;
  } else if (link && googleDriveRegex.test(link)) {
    // If it matches the Google Drive regex, convert to direct link format
    const match = link.match(googleDriveRegex);
    if (match) {
      const fileId = match[1];
      return `https://drive.google.com/uc?export=view&id=${fileId}`;
    }
  }
  // If neither regex matches, return an empty string
  return '';
}

const getDayName = (dateString: string): string => {
  // Parse the date string
  const date = moment(dateString, 'YYYY-MM-DD');
  // Get the day name
  return date.format('dddd');
}


const getDayOfMonth = (dateString: string): number  => {
  // Parse the date string
  const date = moment(dateString, 'YYYY-MM-DD');
  // Get the day of the month
  return date.date();
}
// import { membershipStatuses } from "../utils/enums";

// const getBookingStatusColor = (status: string) => {
//   if (status) {
//     status = status.toLowerCase();
//     switch (status) {
//       case "in progress":
//         return "#F69E35";
//       case "approved":
//         return "#9CCE5D";
//       case "pending":
//         return "#85bccb";
//       case "canceled":
//         return "#CE5D5D";
//       case "completed":
//         return "#013847";
//       default:
//         return "#38B2AC";
//     }
//   }
//   return "#38B2AC"
// };


const capitalizeString = (string: string) => string?.replace(/\w/, c => c?.toUpperCase())

export const capitalizeWords = (str: string): string => {
  return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}


type StatusOptionsType = 'pending' | 'verified' | 'declined' | 'inactive' | 'approved' | 'active' | 'removed'

const getMemberStatusOptions = (status: string): StatusOptionsType[] => {
  // var allOptions = membershipStatuses
  if (status) {
    status = status.toLowerCase();
    switch (status) {
      case "pending":
        return [status, "declined"]
        case "verified":
          return [status, "approved", "declined", "removed"]
      case "declined":
        return [status, "approved"]
      case "inactive":
        return [status, "approved"]
      case "approved":
        return [status, "removed"]
      case "active":
        return [status, "removed"]
      case "removed":
        return [status, "declined"]
      default:
        return [status as StatusOptionsType]
    }
  }
  return []
};

const getMemberStatusColor = (status: string): {bgColor: string, primaryTextColor: string, secondaryTextColor: string} => {
  if (status) {
    status = status.toLowerCase();
    switch (status) {
      case "approved":
        return {bgColor: "#E7F5EC", primaryTextColor: '#b9dc8d', secondaryTextColor: '#819a62'};
      case "active":
        return {bgColor: "#ebf5ec", primaryTextColor: '#399f48', secondaryTextColor: '#afd8b5'};
      case "verified":
        return {bgColor: "#fef5ea", primaryTextColor: '#F69E35', secondaryTextColor: '#fce1c2'};
      case "pending":
        return {bgColor: "#edf5f7", primaryTextColor: '#51a0b5', secondaryTextColor: '#a8cfda'};
      case "declined":
        return {bgColor: "#faeeee", primaryTextColor: '#CE5D5D', secondaryTextColor: '#e6aeae'};
      case "inactive":
        return {bgColor: "#eee", primaryTextColor: '#9a9a9a', secondaryTextColor: '#d6d6d6'};
      case "removed":
        return {bgColor: "#eee", primaryTextColor: '#668790', secondaryTextColor: '#696969'};
      default:
        return {bgColor: "#eee", primaryTextColor: '#bbb', secondaryTextColor: '#696969'};
    }
  }
  return {bgColor: "#eee", primaryTextColor: '#bbb', secondaryTextColor: '#696969'};
};


const getBookingStatusColor = (status: string) => { 
  if (status) {
    status = status.toLowerCase();
    switch (status) {
      case "pending":
        return {primaryTextColor: "#F69E35", bgColor: '#fef5ea', secondaryTextColor: '#fce1c2'};
      case "approved":
        return {primaryTextColor: "#399f48", bgColor: '#ebf5ec', secondaryTextColor: '#afd8b5'};
      case "in_progress":
        return {primaryTextColor: "#51a0b5", bgColor: '#edf5f7', secondaryTextColor: '#a8cfda'};
      case "canceled":
        return {primaryTextColor: "#CE5D5D", bgColor: '#faeeee', secondaryTextColor: '#e6aeae'};
      case "completed":
        return {primaryTextColor: "#013847", bgColor: '#e5ebec', secondaryTextColor: '#51a0b5'};
      default:
        return {primaryTextColor: "#202124", bgColor: '#e8e8e9', secondaryTextColor: '#696969'};
    }
  }
  return {primaryTextColor: "#202124", bgColor: '#e8e8e9', secondaryTextColor: '#696969'};
};


const lightenColor = (hex: string, percent: number): string => {
  const color = parseInt(hex.replace(/^#/, ''), 16);
  const R = ((color >> 16) + percent);
  const G = (((color >> 8) & 0x00FF) + percent);
  const B = ((color & 0x0000FF) + percent);
  const newColor = ((R << 16) | (G << 8) | B).toString(16);
  return `#${newColor.padStart(6, '0')}`;
}


const darkenColor = (hex: string, percent: number): string => {
  const color = parseInt(hex.replace(/^#/, ''), 16);
  const R = ((color >> 16) - percent);
  const G = (((color >> 8) & 0x00FF) - percent);
  const B = ((color & 0x0000FF) - percent);
  const newColor = ((R << 16) | (G << 8) | B).toString(16);
  return `#${newColor.padStart(6, '0')}`;
}


type AnyObject = Record<string, any>; // A type for any object with string keys

const getChangedAttributes = <T extends AnyObject>(
  originalObj: T,
  newObj: T
): T => {
  const changedAttributes: T = {} as T;

  for (const key in originalObj) {
    if (originalObj.hasOwnProperty(key)) {
      if (newObj.hasOwnProperty(key) && newObj[key] !== originalObj[key]) {
        changedAttributes[key] = newObj[key];
      }
    }
  }

  for (const key in newObj) {
    if (newObj.hasOwnProperty(key) && (!originalObj.hasOwnProperty(key) || newObj[key] !== originalObj[key])) {
        changedAttributes[key] = newObj[key];
    }
  }

  return changedAttributes;
}


const styleThousandCommas = (number: string | number) => {
  if (typeof number === 'number'){
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } else if (typeof number === 'string'){
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  return number
}


const maxHours = (capacity: number, recurrence: string | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'annual') => {
  if (recurrence && capacity){
    switch (recurrence){
      // case 'daily':
      //   // 24 hrs
      //   return Math.round(16 / capacity)
      case 'weekly':
        // 168 hrs
        return Math.round(115 / capacity)
      case 'monthly':
        // 730 hrs
        return Math.round(500 / capacity)
      case 'annual':
        // 8760 hrs
        return Math.round(6000 / capacity)
      default:
        return 0
    }
  }
  return 0
}

export {
  getDayName,
  getDayOfMonth,
  capitalizeString,
  maxHours,
  getMemberStatusOptions,
  lightenColor,
  darkenColor,
  getBookingStatusColor,
  getMemberStatusColor,
  getChangedAttributes,
  styleThousandCommas
}