import React, {useEffect} from 'react'
import { useNavigate, useLocation } from 'react-router-dom';


import { BiExit } from "react-icons/bi";

// import KitchenIcon from '@mui/icons-material/Kitchen';

import { useAuthState } from '../contexts/authcontext'
import { useLoadRequests } from "../hooks/memberhooks";
// import { useLoadMemberships } from "../hooks/memberhooks";
import { useLoadAuthUser } from "../hooks/authhooks";

// import { ReactComponent as HomeIcon } from '../icons/Home.svg'
import { ReactComponent as CommunityIcon } from '../icons/CommunityIcon.svg'
import { ReactComponent as ResourcesIcon } from '../icons/ResourcesIcon.svg'
import { ReactComponent as DashboardIcon } from '../icons/DashboardIcon.svg'
import { ReactComponent as CollapseIcon } from '../icons/CollapseIcon.svg'
import { ReactComponent as TransactionIcon } from '../icons/TransactionIcon.svg'
import { ReactComponent as SettingsIcon } from '../icons/Settings.svg'
// import { ReactComponent as LoginIcon } from '../icons/Login.svg'
import { ReactComponent as InventoryIcon } from '../icons/InventoryIcon.svg'
import homecook_logo from '../assets/img/homecook_logo.png'
import { isResourcePath } from '../utils/helpers';
import { useAppState } from '../contexts/appcontext';



// import homecook_logo from '../assets/img/homecook_logo.png'
// import DarkModeSwitcher from '../components/switcher'
// const account_avatar: string = `https://upload.wikimedia.org/wikipedia/commons/1/1e/Default-avatar.jpg`



type SideBarOptions = {
    name: string,
    path: string,
    icon: React.FC<React.SVGProps<SVGSVGElement>>
    image?: string
}


var unauthDrawerItems: SideBarOptions[] = [
    {
        name: 'Logout',
        path: '',
        icon: BiExit
    }
]

var authDrawerItems: SideBarOptions[] = [
    {
        name: 'Collapse',
        path: '',
        icon: CollapseIcon
    },
    {
        name: 'Dashboard',
        path: '/',
        icon: DashboardIcon
    },
    {
        name: 'Community',
        path: '/community',
        icon: CommunityIcon
    },
    {
        name: 'Resources',
        path: '/resources',
        icon: ResourcesIcon
    },
    {
        name: 'Inventory',
        path: '/inventory',
        icon: InventoryIcon
    },
    // {
    //     name: 'Reservations',
    //     path: '/kitchens/reservations',
    //     icon: BookingsIcon
    // },
    {
        name: 'Transactions',
        path: '/transactions',
        icon: TransactionIcon
    },
    // {
    //     name: 'Messages',
    //     path: '/messages',
    //     icon: MessageIcon
    // },
    {
        name: 'Settings',
        path: '/settings',
        icon: SettingsIcon
    },
    // {
    //     name: 'Account',
    //     path: '/account',
    //     icon: AiOutlineUser,
    //     image: account_avatar
    // },

    // {
    //     name: 'Logout',
    //     path: '',
    //     icon: BiExit
    // }
]



const SideBar = () => {
    const navigate = useNavigate();

    var { isloggedin, isverified } = useAuthState()
    var { membershipRequests } = useLoadRequests()
    var { signOutUser } = useLoadAuthUser()
    const {isCollapsed, toggleIsCollapsed} = useAppState()
    //  console.log("membershipRequests", membershipRequests)

    var view_postonboard = isloggedin && isverified
    var nMembershipRequests: number = membershipRequests ? membershipRequests.length : 0
    //  var view_otp_form = isloggedin && !isverified

    useEffect(()=> {}, [nMembershipRequests, view_postonboard])

    const clickNav = (route: string, itemName: string) => {
        if (itemName === "Collapse" && toggleIsCollapsed !== undefined){
            toggleIsCollapsed()
        } else {
            navigate(route)
            if (itemName === "Logout") {
                // Handle logout logic here
                // For example, reset the logged-in state to false
                signOutUser()
            }
        }
    }

    const location = useLocation()
    var currentLocation = location.pathname
    var drawerItems = view_postonboard ? authDrawerItems : unauthDrawerItems



    return (
    <div className={`${isCollapsed ? 'w-[90px]' : 'w-[250px]'} top-0 left-0 bottom-0 mt-0 mb-0 ml-0 sm:h-screen fixed bg-[#FFF] border-[1px] border-[#E9E9E9] duration-300 ${isloggedin ? 'visible' : 'invisible'} `}>
        {/* <img src={homecook_logo} className='relative w-20' alt='/' /> */}
        <div className='flex flex-row mx-auto my-2'>
            <img src={homecook_logo} alt="HomeCook Kitchen Logo" className="relative w-20 my-auto" />
            {
                (isCollapsed) ? (<></>) : (<h1 className='text-primary-200 font-raleway font-bold text-xl my-auto'>HomeCook</h1>)
            }
        </div>
        

        <nav>
            <ul className='flex flex-col flex-start text-gray-800 my-6'>
                { 
                    drawerItems.map((item, i) => {
                    return (
                            <li 
                                key={i} 
                                className={`flex flex-row w-[232px] h-[50px] cursor-pointer font-raleway text-[16px] my-auto ${(currentLocation === item.path || (item.path === "/resources" && isResourcePath(currentLocation))) ? `bg-[#E6EBED] w-full mx-auto rounded-lg text-[#013847] font-semibold ${(["/"].includes(item.path)) ? 'fill-[#013847]' : ''}` : 'text-[#737373] hover:text-[#b8dae4]'} `} 
                                onClick={() => clickNav(item.path, item.name)}
                            >
                                <item.icon className={`relative my-auto w-[24px] h-[24px] ml-7`} />
                                {
                                    (isCollapsed) ? (<></>) : (<p className={`my-auto mx-4`}>{item.name}</p>)
                                }
                            </li>
                        )
                    })
                }
        
                {/* <DarkModeSwitcher /> */}

            </ul> 
        </nav>
    </div>
  )
}

export default SideBar


