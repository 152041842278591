import { 
  // useState,
   useEffect } from "react";
   import { useNavigate } from 'react-router-dom';

   import {useLoadCommunity} from "../../hooks/authhooks"; 
   import { useAuthState } from '../../contexts/authcontext'

import { useMemberState } from "../../contexts/membercontext";
// import { useResourceState } from "../../contexts/kitchencontext";
import { useLoadMemberships } from "../../hooks/memberhooks";
import EditMultipleMembers from '../../molecules/EditMultipleMembers'
import { ReactComponent as DashboardIcon } from '../../icons/DashboardIcon.svg'

const MembersPage = () => {
  const navigate = useNavigate();
  var { memberships } = useLoadMemberships();
  const { membershipRequests, addingMembers, setAddingMembers } = useMemberState();
  // const [add_member, toggleNewMember] = useState(false);


  var { authUser } = useAuthState()
  var communityId = (authUser && authUser.primary_community) ? authUser.primary_community : ""
  var { primaryCommunity } = useLoadCommunity(communityId)

  // const { currentKitchen } = useResourceState()
  // let kitchen_capacity = currentKitchen?.capacity ? parseInt(currentKitchen.capacity) : 1
  


  const closeAppModal = () => {
    // toggleNewMember(false);
    setAddingMembers(false)
  };

  useEffect(() => {
    document.title = "HomeCook | Dashboard";
    // eslint-disable-next-line
  }, [memberships, membershipRequests]);

  return addingMembers ? (
    <div className="md:p-[3%] w-full bg-back-400 pt-16 md:pt-16 font-open-sans flex flex-col gap-10">
      <div>
        <h1 className="text-3xl font-bold text-center justify-self-center">Add New Member Details</h1>
        <h1 className="text-md text-center justify-self-center">Include plans & other membership details for new members.</h1>
      </div>
      <EditMultipleMembers closeAppModal={closeAppModal} cancelEditMember={() => setAddingMembers(false)}/>
    </div>
  ) : (
    <div className="w-full bg-[#F5F5F5] font-open-sans mt-[72px] h-screen">
  
      <div className="flex flex-col w-11/12 mx-auto">

        <div className="flex flex-col bg-white mt-6 h-[105px] w-full rounded-md">
          <p className="text-[20px] font-raleway text-black ml-[24px] mt-auto mb-1">Hi, 👋</p>
          <p className="text-[16px] font-open-sans text-[#4D4D4D] ml-[24px] font-light mb-auto mt-1">Manage your community and resources with ease.</p>
        </div>

        <div className="flex flex-row mt-4 w-full justify-start">
          <div className="flex flex-col bg-white h-[177px] w-1/3 mr-4 rounded-md">
            <p className="text-[16px] font-raleway text-[#4D4D4D] ml-[24px] mb-auto mt-[24px]">Total Resource Categories</p>
            <p className="text-[20px] font-raleway font-bold text-black ml-[24px] mb-1">{primaryCommunity?.resource_categories_count ? primaryCommunity.resource_categories_count : 0}</p>
            <button className="bg-primary-200 text-white mx-auto text-[18px] font-medium font-raleway my-auto border-0 w-[315px] h-[46px] rounded-md">
              Manage Categories
            </button>
          </div>

          <div className="flex flex-col bg-white h-[177px] w-1/3 mx-2 rounded-md">
            <p className="text-[16px] font-raleway text-[#4D4D4D] ml-[24px] mb-auto mt-[24px]">Total Resources</p>
            <p className="text-[20px] font-raleway font-bold text-black ml-[24px] mb-1">{primaryCommunity?.resource_count ? primaryCommunity.resource_count : 0}</p>
            <button onClick={()=>navigate(`/resources`)} className="bg-primary-200 text-white mx-auto text-[18px] font-medium font-raleway my-auto border-0 w-[315px] h-[46px] rounded-md">
              Manage Resources
            </button>
          </div>

          <div className="flex flex-col bg-white h-[177px] w-1/3 ml-5 rounded-md">
            <p className="text-[16px] font-raleway text-[#4D4D4D] ml-[24px] mb-auto mt-[24px]">Total Requests</p>
            <p className="text-[20px] font-raleway font-bold text-black ml-[24px] mb-1">0</p>
            <button onClick={()=>navigate(`/community`)} className="bg-primary-200 text-white mx-auto text-[18px] font-medium font-raleway my-auto border-0 w-[315px] h-[46px] rounded-md">
              Manage Memberships
            </button>
          </div>
        </div>


        <div className="flex flex-row mt-4 w-full justify-start h-[484px]">
          <div className="flex flex-col bg-white  w-9/12 mr-4 rounded-md">
            <p className="text-[20px] font-raleway text-black ml-[24px] mb-[14px] mt-[24px] font-medium">Recent Activities</p>

            <div className="flex flex-col mx-[24px] w-[10/12] h-[400px] border-[#E9E9E9] border-[1px] rounded-lg">

              <div className="flex mx-auto rounded-full h-[80px] w-[80px] bg-[#EFF8FA] mt-auto">
                <DashboardIcon className="m-auto fill-primary-300 text-transparent h-[40px] w-[40px]" />
              </div>

              <p className="text-[20px] mx-auto text-primary-200 font-raleway font-bold my-3">
                  No recent activities to display.
              </p>
              <p className=" font-open-sans text-[16px] text-[#333] mx-auto">
                Start creating and managing your resources to enhance your community!
              </p>

              <button onClick={()=>navigate(`/resources`)} className="bg-primary-200 text-white mx-auto text-[18px] font-medium font-raleway mt-5 mb-auto border-0 w-[315px] h-[46px] rounded-md">
                Add a Resource
              </button>

            </div>
          </div>

          <div className="flex flex-col bg-white w-4/12 mx-2 rounded-md">
            <p className="text-[20px] font-raleway text-black ml-[24px] mb-[14px] mt-[24px] font-medium">Quick Actions</p>

            <div className="flex flex-col mx-[24px] mb-[8px] w-[10/12] h-[190px] border-[#E9E9E9] border-[1px] rounded-lg">
              <p className="text-[18px] font-raleway text-black w-10/12 mx-auto mt-[24px] font-medium">Add a Resource</p>
              <p className="w-10/12 font-open-sans text-[16px] text-[#333] mx-auto my-2">
                Get started by adding a new resource to your community!
              </p>
              <button onClick={()=>navigate(`/resources`)} className="bg-primary-200 text-white mx-auto text-[18px] font-medium font-raleway mb-auto border-0  w-10/12 h-[46px] rounded-md">
                Add a Resource
              </button>
            </div>

            <div className="flex flex-col mx-[24px] mt-[8px] w-[10/12] h-[190px] border-[#E9E9E9] border-[1px] rounded-lg">
              <p className="text-[18px] font-raleway text-black w-10/12 mx-auto mt-[24px] font-medium">Add Membership Plans</p>
              <p className="w-10/12 font-open-sans text-[16px] text-[#333] mx-auto my-2">
                Get started by adding membership plans to your community!
              </p>
              <button onClick={()=>navigate(`/community`)} className="bg-primary-200 text-white mx-auto text-[18px] font-medium font-raleway mb-auto border-0 w-10/12 h-[46px] rounded-md">
                  Add Membership Plan
              </button>
            </div>
          </div>
        </div>

      

      </div>
    </div>


  );
};

export default MembersPage;
