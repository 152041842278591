import React from 'react'
import moment from 'moment-timezone';

import { Cancel, CheckCircle, ReportProblem} from "@mui/icons-material";
import { ReactComponent as ProfileIcon } from "../icons/ProfileIcon.svg";
import { ReactComponent as CredentialIcon } from "../icons/credentialIcon.svg";

import {  CredentialSchema, CurrentRequirementsSchema, MembershipSchema } from "../data/types";
import { useLoadMember, useGetCredentials, useGetPaymentMethods } from '../hooks/memberhooks';
import { useLoadRequirements } from "../hooks/kitchenhooks";


import { styleThousandCommas, fetchRequirementsLookUp } from "../utils/helpers";

type MemberDetailsModalProps = {
    member: MembershipSchema
    handleUpdateMember: (member: MembershipSchema) => void
    handleClose: () => void;
  }

const MemberDetails: React.FC<MemberDetailsModalProps> = ({member, handleUpdateMember, handleClose}) => {

    const {currentMember} = useLoadMember(member?.id ? member.id : "")
    var memberUsername = currentMember?.username ? currentMember.username : "";

    const { memberCredentials } = useGetCredentials(memberUsername)
    const { requirements } = useLoadRequirements()
    // console.log("requirements", requirements)
    
    const { paymentMethods } = useGetPaymentMethods(memberUsername)
    const currentRequirements = fetchRequirementsLookUp(requirements, memberCredentials as CredentialSchema[], paymentMethods)

    const currentMemberJoined = moment(currentMember?.joined).tz('America/New_York').startOf('second').fromNow()

    // const isExpired = (dateString: string) => {
    //     // Parse the 'MM/YYYY' formatted date into a TypeScript Date object
    //     const dateParts = dateString.split('/');
    //     if (dateParts.length === 2) {
    //     const month = parseInt(dateParts[0], 10); // Parse the month part
    //     const year = parseInt(dateParts[1], 10);  // Parse the year part

    //         // Check if month and year are valid numbers
    //         if (!isNaN(month) && !isNaN(year)) {
    //             // Create a Date object using the parsed values
    //             const parsedDate = new Date(year, month - 1, 1); // Subtract 1 from the month since months are zero-based

    //             // Get the current date
    //             const currentDate = new Date();

    //             // Compare the parsed date with the current date
    //             if (parsedDate < currentDate) {
    //                 return true
    //             }   
    //         }
    //     }
    //     return false
    // }


    const pay_requirements_length = (currentMember && currentMember?.plan_recurrence && !['hourly', 'daily'].includes(currentMember.plan_recurrence) && (requirements?.length || requirements?.length === 0)) ? requirements.length + 1 : requirements?.length


    const percentRequirementsMet = () => {
        let count = 0;
        const attributeSet = new Set<any>();
        if (requirements && requirements.length > 0){
          // Loop through requirements and add unique attribute values to a set
          for (const req of requirements) {
            attributeSet.add(req['name']);
          }
        }
    
        if (memberCredentials){
          // Loop through memberCredentials and check for matches in the set
          for (const cred of memberCredentials) {
            if (attributeSet.has(cred['category']) && cred.is_valid) {
              count++;
              attributeSet.delete(cred['category']); // Avoid double counting duplicates within list2
            }
          }
        }
    
        if (currentMember && currentMember?.plan_recurrence && !['hourly', 'daily'].includes(currentMember.plan_recurrence) && (requirements?.length || requirements?.length === 0)){
          if (paymentMethods && paymentMethods.length > 0){
            count++;
          }
        }
    
        return pay_requirements_length ? (count / pay_requirements_length) * 100 : 100
      }
    
      const requirementsMet = percentRequirementsMet()

    // const capitalizeWords = (str: string) => {
    //     if (str){
    //       // Split the string into an array of words
    //       const words = str.split(' ');
    //       // Capitalize the first letter of each word
    //       const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    //       // Join the capitalized words back into a string
    //       const capitalizedString = capitalizedWords.join(' ');
    //       return capitalizedString;      
    //     }
    // }

    return (
    <div  className='flex flex-col justify-around'>
        <span onClick={handleClose} className="ml-auto w-full flex cursor-pointer">
            <Cancel className="text-[#A1A1A1] hover:text-[#777] h-[18px] w-[18px] ml-auto" />
        </span>
        <div className='my-auto mx-2 font-raleway text-[20px] text-black text-left font-semibold p-1'>
            Member Profile
        </div>
        <div className='flex flex-row p-1 my-2'>
            {/* <div className='bg-[#eee] rounded-full h-[56px] w-[56px]'>

            </div> */}

            {currentMember && currentMember?.avatar ? (
                  <div
                    className="flex border-[#E9E9E9] border-[1px] rounded-full h-[56px] w-[56px] mr-2"
                    // onClick={() => toggleUserOptions(true)}
                  >
                <img
                    id="image-input-avatar"
                    src={currentMember.avatar}
                    alt="User Avatar"
                    className="h-[56px] w-[56px] m-auto rounded-full cursor-pointer"
                />
                </div>
            ) : (
                <ProfileIcon
                    // onClick={() => toggleUserOptions(true)}
                    className="ml-auto text-[#E6E6E6] w-auto m-auto h-8 my-auto cursor-pointer hover:text-primary-400 mr-2"
                />
            )}


            <div className='flex flex-col mx-2'>
                <p className='text-[16px] font-raleway text-black font-medium'>
                    {currentMember?.first_name} &nbsp;
                    {currentMember?.last_name}
                </p>
                <p className='text-[14px] font-open-sans text-[#4D4D4D] font-normal'>
                    Joined {currentMemberJoined}
                </p>
            </div>


            <div className={`flex ml-auto rounded-md bg-[#E7F5EC]  w-[187px] h-[41px] ${(requirementsMet < 50) ? 'bg-[#FBE9E9] text-[#D42620]' : 'bg-[#E7F5EC] text-[#0F973D]'} `}>
                <p className='m-auto text-[14px] font-raleway font-semibold'>{requirementsMet.toFixed(0)}% Profile Completion</p>
            </div>
        </div>
    
        <div className='flex flex-col p-1'>
            <p className='text-[14px] font-raleway text-[#4D4D4D] font-medium my-1'>Contact Details</p>
            <div className='flex flex-row w-full'>
                <div className='flex flex-col border-[1px] border-[#E9E9E9] rounded-md p-2 w-1/2'>
                    <p className='text-[#808080] text-[16px] font-raleway'>Email</p>
                    <p className='font-open-sans text-black text-[16px]'>{currentMember?.email}</p>
                </div>
                <div className='flex flex-col ml-4 border-[1px] border-[#E9E9E9] rounded-md p-2 w-1/2'>
                    <p className='text-[#808080] text-[16px] font-raleway'>Location</p>
                    <p className='font-open-sans text-black text-[16px]'>{currentMember?.state}</p>
                </div>
            </div>
        </div>

        <div className='flex flex-col p-1'>
            <p className='text-[14px] font-raleway text-[#4D4D4D] font-medium my-1'>Plan</p>
            <div className='flex flex-row w-full'>
                <div className='flex flex-col border-[1px] border-[#E9E9E9] rounded-md p-2 w-full'>
                {
                    (currentMember && currentMember?.plan_rate) ? (
                        <>
                            <p className='text-black text-[16px] font-raleway font-medium'>
                                {
                                    currentMember.plan_name
                                }
                            </p>
                            <p className='text-[#4D4D4D] text-[14px] font-open-sans'>
                                {
                                    (currentMember?.plan_rate.toString().includes(".") ) ? (
                                        `$${styleThousandCommas(currentMember.plan_rate.toString())} (${currentMember.plan_recurrence})`
                                    ) : (
                                        `$${styleThousandCommas(currentMember?.plan_rate.toString())}.00 (${currentMember.plan_recurrence})`
                                    )
                                }
                            </p>
                        </>
                    ) : (
                        <></>
                    )
                }
                {
                    
                }
                </div>
            </div>
        </div>


        <div className='flex flex-col p-1'>
            <p className='text-[14px] font-raleway text-[#4D4D4D] font-medium my-1'>Credentials</p>
            <div className='flex flex-row w-full'>
                <div className='flex flex-col  w-full'>
                    {
                        currentRequirements.map((requirement: CurrentRequirementsSchema, i: number)=> (
                            <div key={i} className='flex flex-row border-[1px] border-[#E9E9E9] rounded-md p-2 my-1 w-full'>
                                <div className='flex h-[32px] w-[32px] bg-[#F5F5F5] rounded-md mr-2'>
                                    <CredentialIcon className='text-[#808080] m-auto' />
                                </div>
                                
                                <p className='my-auto font-raleway text-[16px] text-black font-medium'>{requirement.name}</p>
                                {
                                    (requirement.isMet) ? (
                                        <CheckCircle className='ml-auto my-auto text-[#0F973D]' />
                                    ) : (
                                        <ReportProblem color="warning" className="my-auto ml-auto" />

                                        // </div>
                                    )
                                }
                                
                            </div>
                        ))
                    }

                </div>
            </div>
        </div>

    </div>
    )
}

export default MemberDetails