import {useState} from 'react'

import { DefaultModal } from "../molecules/AppModal";
// import { useResourceState } from "../contexts/kitchencontext";
import { AddCircle } from "@mui/icons-material";
import { usStates, USStatesEnum } from "../utils/enums"
import { ResourceType, SearchFilterOptionType } from "../data/types";
import { capitalizeString } from '../utils/helpers';
// import StaggeredDropDown from "../molecules/Dropdown";

type FilterBoxProps = {
  // setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  searchType: ResourceType
  searchFilterOptions: SearchFilterOptionType
  setIsAddingResource: React.Dispatch<React.SetStateAction<boolean>>;
};

const FilterBox: React.FC<FilterBoxProps> = ({ setIsAddingResource, searchType, searchFilterOptions}) => {
  // let { kitchens } = useResourceState();
  const [defaultIsOpen, defaultSetIsOpen] = useState(false);
  const { currentFilter, setCurrentFilter } = searchFilterOptions

  const handleSubmitFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault()
    const {name, value} = e.target
    if (name === 'county' && value && setCurrentFilter){
        setCurrentFilter(prev => ({...prev, county: value === 'statewide' ? '' : value}))

    } else if (name === 'state' && value && setCurrentFilter !== undefined){
        // setCurrentFilter({state: value, county: USStatesCounties[value] ? USStatesCounties[value][0] : ''})
        setCurrentFilter({state: value === 'nationwide' ? '' : value, county: ''})
    } else if (name === 'stage' && value && setCurrentFilter !== undefined){
        setCurrentFilter(prev => ({...prev, stage: value}))
    } else if (name === 'active' && setCurrentFilter !== undefined){
        const activeBoolean =  (value === "active") ? true : ((value === "inactive") ? false : undefined)
        setCurrentFilter(prev => ({...prev, active: activeBoolean}))
    }
    
}
  // const navigate = useNavigate();
  // const numberOfKitchens = kitchens?.length;
  let buttonLabel = (
    <div>
      <AddCircle className="mr-2 my-auto" />
      Add Kitchen Space
    </div>
  );
  if (searchType === "documentation") {
    buttonLabel = (
      <div>
        <AddCircle className="mr-2 my-auto" />
        Add Documentation
      </div>
    );
  } else if (searchType === "funding") {
    buttonLabel = (
      <div>
        <AddCircle className="mr-2 my-auto" />
        Add Funding Resource
      </div>
    );
  } else if (searchType === "training") {
    buttonLabel = (
    <div>
      <AddCircle className="mr-2 my-auto" />
      Add Training
    </div>
  );
  } else if (searchType === "farm") {
    buttonLabel = (
      <div>
        <AddCircle className="mr-2 my-auto" />
        Add Local Fresh Food
      </div>
    );
  } else if (searchType === "expert") {
    buttonLabel = (
      <div>
        <AddCircle className="mr-2 my-auto" />
        Add Expert Resource
      </div>
    );
  }

  const activeOptions = ["all", "active", "inactive"]
  const activeValue = (!currentFilter?.active) ? ((currentFilter?.active === false) ? "inactive" : "all") : "active"

  return (
    <div className="flex w-11/12 mx-auto">
      <div className="flex flex-row my-3 text-[16px] font-raleway text-[#808080]">
        <p className="my-auto">Filter by</p>
        <select name="active" value={activeValue} onChange={handleSubmitFilter} className="mx-2 border-[1px] border-[#E9E9E9] rounded-lg text-primary-200">
          {
            activeOptions.map((option, i)=> (
              <option key={i} value={option}>{capitalizeString(option)}</option>
            ))
          }
        </select>

        <select 
          name="state" 
          value={currentFilter.state ? currentFilter.state : "nationwide"} 
          className="mx-2 border-[1px] border-[#E9E9E9] rounded-lg text-primary-200"
          onChange={handleSubmitFilter}
        >
          <option value="">-- Select State --</option>
          <option value="nationwide">All</option>
          {
              (usStates && usStates.length > 0) && usStates.map((state, i) => (
                  <option value={state} key={i}>{USStatesEnum[state]?.fullName}</option>
              ))
          }
        </select>
      </div>
      <div className="pt-2 text-right w-1/2 ml-auto">
        {(searchType) && (
          <button
            onClick={() =>   setIsAddingResource(true)}
            className="bg-primary-200 text-white rounded-lg w-30 h-10 p-2"
            data-testid="add-button"
          >
            {buttonLabel}
          </button>
        )}
      </div>
      <DefaultModal
        isOpen={defaultIsOpen}
        closeAppModal={() => defaultSetIsOpen(false)}
      />
    </div>
  );
};

export default FilterBox;




type ProductFilterBoxProps = {
};

export const ProductFilterBox: React.FC<ProductFilterBoxProps> = () => {
  // const [defaultIsOpen, defaultSetIsOpen] = useState(false);
  const handleSubmitFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault()
  }
  // let buttonLabel = (
  //   <div>
  //     <AddCircle className="mr-2 my-auto" />
  //     Add Kitchen Space
  //   </div>
  // );

  const activeOptions = ["all", "pending", "approved", "completed"]

  return (
    <div className="flex w-11/12 mx-auto">
      <div className="flex flex-row my-3 text-[16px] font-raleway text-[#808080]">
        <p className="my-auto">Filter by</p>
        <select name="active" onChange={handleSubmitFilter} className="mx-2 border-[1px] border-[#E9E9E9] rounded-lg text-primary-200">
          {
            activeOptions.map((option, i)=> (
              <option key={i} value={option}>{capitalizeString(option)}</option>
            ))
          }
        </select>
      </div>
    </div>
  );
};

