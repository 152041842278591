import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import moment from 'moment'

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
// import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import InfoIcon from "@mui/icons-material/Info";
// import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

// import CheckIcon from '@mui/icons-material/Check';
// import EditIcon from '@mui/icons-material/Edit';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

// import { DeleteConfirmationModal } from "../components/AppModal";
// import { useUpdateMembership } from '../contexts/memberhooks'
import { useResourceState } from '../contexts/kitchencontext'

// import { membershipStatuses } from "../utils/enums";
import { styleThousandCommas, maxHours, getMemberStatusOptions } from "../utils/helpers";
import { MembershipSchema } from "../data/types";


// const colors: {[key: string]: string} = statusColors();
// const account_avatar = `https://upload.wikimedia.org/wikipedia/commons/1/1e/Default-avatar.jpg`

// const circle = (status: string) => {
//   return (
//     <div
//       className={`z-0 w-2 h-2 animate-pulse ${colors[status]} rounded-full`}
//     ></div>
//   );
// };

type EditMemberRecordProps = {
  member: any
  kitchen_capacity: number
  allSelected: boolean
  isRequests?: boolean
  submitUpdate: (memberUpdates: MembershipSchema) => void
  cancelEditMember: () => void
}

const EditMemberRecord: React.FC<EditMemberRecordProps> = (props) => {

  var { kitchen_capacity, member, isRequests, submitUpdate, cancelEditMember } = props
  const { username, first_name, last_name, team_name, plan_id, plan_name, plan_description, plan_rate, plan_recurrence, status } = member
  // const { updateMembership } = useUpdateMembership()
  var { plans } = useResourceState()

  const [selected, updateSelect] = useState(false);
  const [membershipChanges, setMembershipChanges] = useState<MembershipSchema>({username, first_name, last_name, team_name, plan_id, plan_name, plan_description, plan_rate, plan_recurrence});
  const [selectedPlan, setSelectedPlan] = useState<string>(member.plan_id);
  const [selectedStatus, setSelectedStatus] = useState<string>(status)  

  var membershipStatuses = getMemberStatusOptions(status)

  const handleUpdateMembership = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    var { name, value } = e.target
    if (name){
      setMembershipChanges(prevData => ({...prevData, [name]: value}))
    }
  }

  const handlePlanChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    var { value: plan_id } = event.target
    if (plan_id){
      setSelectedPlan(plan_id)
      let plan_details = plans?.find(plan => plan_id === plan.plan_id)
      // console.log("plan_details", plan_details)
      if (plan_details){
        let { plan_name, plan_description, plan_recurrence, plan_rate } = plan_details
        setMembershipChanges((prevMembership) => ({
          ...prevMembership, plan_id, plan_name, plan_description, plan_recurrence, plan_rate,
          member_balance: (kitchen_capacity && plan_recurrence) ? maxHours(kitchen_capacity, plan_recurrence) : 0
        }))
      }
    }
  }

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    type statusType = 'pending' | 'declined' | 'approved' | 'verified' | 'active' | 'inactive' | 'removed'
    var status = event.target.value as statusType
    if (status){
      setSelectedStatus(status)
      setMembershipChanges(prevData => ({
        ...prevData,
        status
      }))
    }
  }

  // const updateOpenView = () => {
  //   navigate(`/kitchens/members/${member.id}`) 
  //   // openCheck(true);
  // }

  // const updateCloseView = () => {
  //   openCheck(false);
  // };

  // const submitUpdate = () => {
  //   updateMembership(membershipChanges)
  // }

    // const declineRequest = () => {
    //   if (updateMembership !== undefined) {
    //     updateMembership({username: member.username, status: member.status === 'declined' ? 'removed' : 'declined'})
    //  } 
    // }



  return member ? (
    <tr className="font-open-sans border-x-2 border-s-2 border border-stone-500">
      <td className="pl-5s w-auto p-1">
        {/* Uses the allselected prop from the kitchen list to set the check box*/}
        {props.allSelected ? (
          <CheckBoxIcon
            style={{ cursor: "pointer" }}
            onClick={() => updateSelect(false)}
          />
        ) : selected ? (
          <CheckBoxIcon
            style={{ cursor: "pointer" }}
            onClick={() => updateSelect(false)}
          />
        ) : (
          <CheckBoxOutlineBlankIcon
            style={{ cursor: "pointer" }}
            onClick={() => updateSelect(true)}
          />
        )}
      </td>

      {/* <td className="w-48 pr-5 text-center border-r-0 border-stone-500"> 
        {member.id}
      </td> */}

      <td className="recordItem w-auto border-r-0">
        {/* This provides a test if the edit button has been checked and if so to open a text field to change the value. */}
        {
          member.username
        }
      </td>

      <td className="recordItem w-full flex flex-row border-r-0">
        {/* This provides a test if the edit button has been checked and if so to open a text field to change the value. */}
        <input value={membershipChanges.first_name} type="text" name="first_name" placeholder="First Name" onChange={handleUpdateMembership} className="formInput bg-transparent p-2 rounded-md text-[#000000] text-start w-auto h-full m-1" />
        <input value={membershipChanges.last_name} type="text" name="last_name" placeholder="Last Name" onChange={handleUpdateMembership} className="formInput bg-transparent p-2 rounded-md text-[#000000] text-start w-auto h-full m-1" />
       
      </td>

      <td className="recordItem w-auto border-r-0"> 
        <input value={membershipChanges?.team_name} type="text" name="team_name" placeholder="Team Name" onChange={handleUpdateMembership} className="formInput bg-transparent p-2 rounded-md text-[#000000] text-start w-auto h-full m-1" />
      </td>

      <td className="recordItem w-auto border-r-0">
        <div className="flex justify-center items-center">
          <select
            value={plan_id === selectedPlan ?  selectedStatus : membershipChanges.status}
            disabled={plan_id !== selectedPlan}
            onChange={handleStatusChange}
            className="formInput h-full w-full m-1 bg-transparent p-2 rounded-md mt-1 text-center text-black"
          >
            <option value="">-- Change Status --</option>
            {
              (membershipStatuses) ? membershipStatuses.map((status, i) => (
                <option key={i} value={status}>
                  {status.replace(/\b\w/g, (status: string) => status.toUpperCase())}
                </option>
              )) : null 
            }
          </select>
        </div>
        
      </td>

      <td className="recordItem w-auto border-r-0">
        <select
          value={(status === selectedStatus) ?  selectedPlan : membershipChanges.plan_id}
          disabled={status !== selectedStatus}
          onChange={handlePlanChange}
          className="formInput w-full h-full m-1 bg-transparent p-2 rounded-md mt-1 text-center text-black mx-auto"
        >
          <option value="">-- Select a plan --</option>
          {
            (plans) ? plans.map((plan, i) => (
              <option key={i} value={plan.plan_id}>
                {
                  (plan && plan?.plan_rate && plan?.plan_rate.toString().includes(".") )? (
                    `$${styleThousandCommas(plan.plan_rate.toString())} (${plan.plan_recurrence})`
                  ) : (
                    `$${styleThousandCommas(plan?.plan_rate ? plan?.plan_rate.toString() : "")}.00 (${plan.plan_recurrence})`
                  )
                }
              </option>
            )) : null 
          }
        </select>
      </td>

      <td className="recordItem w-auto border-r-0">
        {
          (member && (member?.member_balance || member?.member_balance === 0))? (
            `${styleThousandCommas(member.member_balance.toString())} hours`
          ) : (
            ` - `
          )
        }
      </td>

      <td className="recordItem w-auto border-r-0">
        {moment.utc(member?.joined).fromNow()}
      </td>

      <td className={`grid ${isRequests ? 'grid-cols-2' : 'grid-cols-1'}  text-slate-400`}>
        {
          (isRequests) ? (
            <>
              <CheckCircleIcon
                className="cursor-pointer text-[#013847] m-auto"
                onClick={() => submitUpdate(membershipChanges)}
              />
                
              <CloseIcon
                className="cursor-pointer text-[#EB001B] m-auto"
                onClick={() => cancelEditMember()}
              />
              
        
            </>
          ) : (
            <div className="flex flex-row justify-evenly">
              <CheckCircleIcon
                className="cursor-pointer text-[#013847] m-auto"
                onClick={() => submitUpdate(membershipChanges)}
              />
              <CloseIcon
                className="cursor-pointer text-[#EB001B] m-auto"
                onClick={() => cancelEditMember()}
              />
            </div>
          )
        }
        

        {/* {is_open ? (
          <RemoveCircleIcon
            style={{ cursor: "pointer", color: "#777", margin: 2 }}
            onClick={() => updateCloseView()}
          />
        ) : (
          <InfoIcon
            style={{ cursor: "pointer", color: "#5DB6CE" }}
            onClick={() => updateOpenView()}
          />
        )} */}
        
      </td>
    </tr>
  ) : null;
};

export default EditMemberRecord;
