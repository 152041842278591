import React, { useEffect, useState } from "react";
import { ResourceInventorySchema, InventorySchema, ResourceType, ResourceSchema, ResourceOptionsPropsType, TrainingInventorySchema } from "../data/types";

import { AddCircle } from "@mui/icons-material";

import { ProductFilterBox } from "../components/FilterBox";
import { AddProductModal, DeleteConfirmationModal } from "./AppModal";
import { capitalizeString, getDirectLinkFromGoogleDriveSharedLink } from "../utils/helpers";
import { useResourceHook } from "../hooks/kitchenhooks";
import moment from "moment";



type ResourceProductDetailsProps = {
    resource: ResourceSchema
    // currentResource: ResourceSchema
    resourceType: ResourceType
    updateResourceData?: (resource: ResourceSchema, resourceOptionTypes?: ResourceOptionsPropsType) => void;
    // setResource: React.Dispatch<React.SetStateAction<ResourceSchema>>
    // handleReloadResource: () => void
}
  
export const ResourceProductDetails: React.FC<ResourceProductDetailsProps> = ({resource, resourceType}) => {

    // resource = resource ? resource : currentResource
    // const [resource, setResource] = useState<ResourceSchema>(currentResource)
    const resourceInventory =  (resource && resource?.resource_inventory) ? resource.resource_inventory : {} as ResourceInventorySchema
    const resourceInventoryItems = resourceInventory['items'] as InventorySchema[]
    const [currentInventoryList, setCurrentInventoryList] = useState<InventorySchema[]>(resourceInventoryItems ? resourceInventoryItems : [])


    const resourceSchedulesList = resourceInventory['schedule'] as TrainingInventorySchema[]
    const [currentTrainingList, setCurrentTrainingList] = useState<TrainingInventorySchema[]>(resourceSchedulesList ? resourceSchedulesList : [])

    const [isAddingProduct, setIsAddingProduct] = useState<boolean>(false)

    var productType = "item"
    if (["training", "expert"].includes(resourceType)){
        productType = "schedule"
    }

    useEffect(() => {
        // setResource(currentResource)
        setCurrentInventoryList(resourceInventoryItems ? resourceInventoryItems : [])
        setCurrentTrainingList(resourceSchedulesList ? resourceSchedulesList : [])
    }, [
        resourceInventoryItems, resourceSchedulesList
    ])

    return (
    <>
        <div className="flex flex-row bg-white border-[1px] border-[#E9E9E9] py-2 w-full">
        <p className='text-[#4D4D4D] text-[16px] font-light font-open-sans mx-[24px] my-auto'>Add your individual {productType}s here, including names, prices, and images.</p>
            
        <button 
            className="mx-[24px] text-[16px] font-raleway ml-auto font-medium flex flex-row h-[44px]  border-[#E9E9E9] border-[1px] text-white px-[24px] py-[12px] bg-primary-200 rounded-lg my-auto"
            onClick={()=> setIsAddingProduct(true)}
        >
            <AddCircle className="m-auto mx-2 text-white" style={{height: 17, width: 17}} />
            <p className="m-auto">Add {capitalizeString(productType)}</p>
        </button>
        </div>
        <ProductFilterBox />

        {
            (["training", "expert"].includes(resourceType)) ? (
                <>
                    <ProductScheduleList 
                        resource={resource} 
                        currentTrainingList={currentTrainingList}
                        setCurrentTrainingList={setCurrentTrainingList}
                    />
                    <AddProductModal 
                        resourceType={resourceType} 
                        resourceId={resource?.id? resource?.id : ""}
                        setIsOpen={setIsAddingProduct} 
                        isOpen={isAddingProduct} 
                        setCurrentInventoryList={setCurrentTrainingList}
                        // handleReloadResource={handleReloadResource}
                        closeAppModal={()=>setIsAddingProduct(false)}
                    />
                </>
            ) : (
                <>
                    <ProductInventoryList 
                        resource={resource} 
                        currentInventoryList={currentInventoryList} 
                        setCurrentInventoryList={setCurrentInventoryList}
                    />
                    <AddProductModal 
                        resourceType={resourceType} 
                        resourceId={resource?.id? resource?.id : ""}
                        setIsOpen={setIsAddingProduct} 
                        isOpen={isAddingProduct} 
                        setCurrentInventoryList={setCurrentInventoryList}
                        // handleReloadResource={handleReloadResource}
                        closeAppModal={()=>setIsAddingProduct(false)}
                    />
                </>
            )
        }


    </>
    )
}



type ProductInventoryListProps = {
    resource: ResourceSchema
    currentInventoryList: InventorySchema[]
    setCurrentInventoryList: React.Dispatch<React.SetStateAction<InventorySchema[]>>
}

const ProductInventoryList: React.FC<ProductInventoryListProps> = ({resource, currentInventoryList, setCurrentInventoryList}) => {

    const resourceInventory =  (resource && resource?.resource_inventory) ? resource.resource_inventory : {} as ResourceInventorySchema
    const resourceInventoryItems = resourceInventory['items'] as InventorySchema[]

    const { deleteInventoryItem } = useResourceHook({resourceId: resource.id})

    const [isDeleting, setIsDeleting] = useState<boolean>(false)

    const handleDelete = (itemId: string) => {
        if (resource && resource?.id && itemId){
            deleteInventoryItem(resource.id, itemId)
        }
        setProduct(undefined)
        setIsDeleting(false)
    }

    const [product, setProduct] = useState<InventorySchema>()

    useEffect(() => {
        setCurrentInventoryList(resourceInventoryItems ? resourceInventoryItems : [])
    }, [setCurrentInventoryList, resourceInventoryItems])

    return (
        <>
        <div className="grid grid-flow-row grid-cols-4 w-full font-open-sans h-auto">
        {
            (currentInventoryList.length > 0) && currentInventoryList.map((inventory, i) => (
                <div key={i} className='flex flex-col my-4 bg-white h-auto w-[282px] rounded-lg mx-auto'>
                    {
                        getDirectLinkFromGoogleDriveSharedLink(inventory?.image_url) ? (
                        <img className='col-span-1 h-[152px] w-[274px] mx-auto m-2 rounded-xl position-absolute object-cover' src={getDirectLinkFromGoogleDriveSharedLink(inventory?.image_url)} alt={inventory?.item_name} /> 
                        ) : (
                            <>
                            <div className='mx-auto col-span-1 rounded-2xl position-absolute bg-[#D9D9D9]/70 h-[152px] w-[274px] m-2'> </div>
                            </>
                        )
                    }
                    <div className='flex flex-col'>
                        <p className='mx-4 mt-4 font-raleway text-[18px] text-black font-semibold'>{inventory.item_name}</p>
                        <p className='font-open-sans text-[16px] text-[#4D4D4D] mx-4'>{inventory.item_description}</p>
                        <div className='flex flex-row font-open-sans justify-between border-t-[1px] border-b-[1px] border-[#E9E9E9] h-[70px] my-4 w-11/12 mx-auto'>
                            <p className='my-auto'>{inventory.total_quantity} units</p>
                            <p className='my-auto'>${inventory.unit_price ? inventory.unit_price : 0} per {inventory.measure_unit}</p>
                        </div>
                    </div>
                    <div className='flex flex-row bg-primary-200 h-[75px] font-raleway w-full mt-auto'>
                        <p className="my-auto text-white mx-4">
                            0 completed orders
                        </p>
                        <div className="flex flex-row ml-auto my-auto m-2">
                            <button onClick={()=>{
                                setIsDeleting(true)
                                setProduct(inventory)
                                }} className="bg-primary-200 text-white text-[16px] font-raleway font-semibold p-2 m-2 ">
                               Delete
                            </button>
                            <button className="text-primary-200 bg-white text-[16px] font-raleway font-semibold p-2 m-2 rounded-md ">
                                Edit
                            </button>
                        </div>

                        
                    </div>
                </div>
                
            ))
        }
        </div>

        <DeleteConfirmationModal  isOpen={isDeleting} data={product} dataName="inventory item" handleDelete={handleDelete} closeDeleteModal={()=>setIsDeleting(false)} />
        </>
    )
}



type ProductScheduleListProps = {
    resource: ResourceSchema
    currentTrainingList: TrainingInventorySchema[]
    setCurrentTrainingList: React.Dispatch<React.SetStateAction<TrainingInventorySchema[]>>
}

const ProductScheduleList: React.FC<ProductScheduleListProps> = ({resource, currentTrainingList, setCurrentTrainingList}) => {

    const resourceInventory =  (resource && resource?.resource_inventory) ? resource.resource_inventory : {} as ResourceInventorySchema
    const resourceSchedulesList = resourceInventory['schedule'] as TrainingInventorySchema[]

    const { deleteInventoryItem } = useResourceHook({resourceId: resource.id})

    const [isDeleting, setIsDeleting] = useState<boolean>(false)

    const [product, setProduct] = useState<TrainingInventorySchema>()

    const handleDelete = (itemId: string) => {
        if (resource && resource?.id && itemId){
            deleteInventoryItem(resource.id, itemId)
        }
        setProduct(undefined)
        setIsDeleting(false)
    }

    useEffect(() => {
        setCurrentTrainingList(resourceSchedulesList ? resourceSchedulesList : [])
    }, [resourceSchedulesList, setCurrentTrainingList])

    return (
        <>
            <div className="grid grid-flow-row grid-cols-4 w-full font-open-sans h-auto">
            {
                (currentTrainingList.length > 0) && currentTrainingList
                .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).map((training, i) => (
                    <div key={i} className='flex flex-col my-4 bg-white h-auto w-[282px] rounded-lg mx-auto'>
                        {
                            getDirectLinkFromGoogleDriveSharedLink(resource.bgPhoto) ? (
                            <img className='col-span-1 h-[152px] w-[274px] mx-auto m-2 rounded-xl position-absolute object-cover' src={getDirectLinkFromGoogleDriveSharedLink(resource.bgPhoto)} alt={training?.date} /> 
                            ) : (
                                <>
                                <div className='mx-auto col-span-1 rounded-2xl position-absolute bg-[#D9D9D9]/70 h-[152px] w-[274px] m-2'> </div>
                                </>
                            )
                        }
                        <div className='flex flex-col'>
                            <div className='flex flex-col mx-4 font-raleway text-[18px] text-black font-semibold'>
                                <b>{(resource.resource_type === 'training') ? 'Training' : 'Session'} Date</b> 
                                <p className="font-medium">{moment(training.date).format('ddd MMM DD, YYYY')}</p>
                            </div>
                            <div className='flex flex-row font-open-sans border-t-[1px] border-b-[1px] border-[#E9E9E9] h-auto my-4 w-11/12 mx-auto'>
                                <div className="grid grid-cols-2">
                                    {
                                        training.times?.map((time, i)=> (
                                            <p key={i} className='flex flex-col text-center my-auto mr-6'>
                                                <b>Option {i+1}</b>
                                                <span className="p-1 bg-primary-300 rounded-md text-white my-1">{moment(time, 'HH:mm').format('hh:mm a')}</span>
                                            </p>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row bg-primary-200 h-[75px] font-raleway rounded-b-lg w-full mt-auto'>
                            <p className="my-auto text-white mx-4">
                                0 {resource.resource_type} sessions
                            </p>
                            <div className="flex flex-row ml-auto my-auto m-2">
                                <button onClick={()=>{
                                    setIsDeleting(true)
                                    setProduct(training)
                                    }} className="bg-primary-200 text-white text-[16px] font-raleway font-semibold p-2 m-2 ">
                                Delete
                                </button>
                                <button className="text-primary-200 bg-white text-[16px] font-raleway font-semibold p-2 m-2 rounded-md ">
                                    Edit
                                </button>
                            </div>
                        </div>
                    </div>
                    
                ))
            }
            </div>

            <DeleteConfirmationModal isOpen={isDeleting} data={product} dataName="schedule" handleDelete={handleDelete} closeDeleteModal={()=>setIsDeleting(false)} />
        </>
    )
}