import { useEffect, useState} from "react"
import AuthView from './Auth'
import UnAuthView from './UnAuth'
import Navbar from "../components/Navbar";
import SideBar from "../components/SideBar"
import LoadingScreen from "../pages/shared/LoadingScreen"

import { useAuthState } from '../contexts/authcontext'
import { useLoadAuthUser } from "../hooks/authhooks";
import { useLoadCommunityResources } from '../hooks/kitchenhooks'

import { FilterType, SearchFilterOptionType } from '../data/types';
import { useAppState } from "../contexts/appcontext";

const DashboardRouter = () => {
  const { loadAuthUser, isLoading } = useLoadAuthUser()
  let { isLoadingResources } = useLoadCommunityResources()


  const defaultUSState = ''
  const [searchTerm, changeSearchTerm] = useState<string>("");
  const [currentFilter, setCurrentFilter] = useState<FilterType>({state: defaultUSState});
  const [signInModalIsOpen, setSignInModalIsOpen] = useState(false);
  const { isCollapsed } = useAppState()
  // const [loading, setLoading] = useState(true);

  const searchFilterOptions: SearchFilterOptionType = {
    searchTerm,
    currentFilter,
    signInModalIsOpen,
    changeSearchTerm,
    setCurrentFilter,
    setSignInModalIsOpen,
  }

  useEffect(() => {
    loadAuthUser()
     // eslint-disable-next-line
  }, [])

  var { isloggedin, isverified, primaryCommunity } = useAuthState()
  var view_postonboard = isloggedin && isverified

  if (isLoading || isLoadingResources){
    return (
      <LoadingScreen />
    )
  } else { 
  return (
    <div className={`flex h-screen ${isloggedin ? ((isCollapsed) ? 'ml-[80px]' : 'ml-[240px]') :''} `}>
  
      <SideBar />
      <Navbar />
      
      {
        view_postonboard ? <AuthView searchFilterOptions={searchFilterOptions} primaryCommunity={primaryCommunity} /> : <UnAuthView />
      }
    </div>
    )
  }
}


export default DashboardRouter;
