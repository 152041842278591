// import { Dialog } from "@mui/material";
import { AddKitchenForm, AddReservationForm, AddMemberForm, DeleteConfirmationForm, AddPlanForm, AddInventoryForm, AddCredentialForm, AddRequirementForm, NewMessageForm } from "./forms";
// import KitchenDetails from "../molecules/KitchenDetails";
import MemberDetails from "./MemberDetails"
import CredentialDetails from "./CredentialDetails"

import LoadingScreen from "../pages/shared/LoadingScreen"

// import { deleteKitchen } from "../api/crud";
// import { useAuthState } from "../contexts/authcontext";
import { useKitchenHook, useAddInventory, useResourceHook, useLoadResourceOptions } from "../hooks/kitchenhooks";

import { BookingSchema, DSCommunitySchema, PlanSchema, CredentialSchema, RequirementSchema, KitchenSpaceSchema, InventorySchema, ResourceType, ResourceSchema, TrainingInventorySchema, MembershipSchema } from "../data/types";
import AddResourceForm from "./forms/AddResourceForm";
import { AddProductForm } from "./forms/AddProductForm";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { capitalizeWords } from "../utils/helpers";
// import { useState } from "react";
// import { useResourceState } from "../contexts/kitchencontext";
// import { usePreferences } from "../contexts/preferencecontext";

type ModalProps = {
  isOpen: boolean;
};


type MemberDetailsModalProps = ModalProps & {
  closeAppModal: () => void;
  member: MembershipSchema
  handleUpdateMember: (member: MembershipSchema) => void
}

export const MemberDetailsModal: React.FC<MemberDetailsModalProps> = (props) => {
  var { isOpen, member, handleUpdateMember, closeAppModal } = props

  return (
    <div
      className={`inset-0 ${isOpen ? "block" : "hidden"}`}
    >
      <div className="fixed inset-0 bg-white opacity-90" onClick={() => closeAppModal()}></div>

      <dialog
        data-testid="view-member-modal"
        className="z-50 top-20 w-[628px] h-auto bg-[#fff] p-4 rounded-xl shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)]"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <MemberDetails member={member} handleUpdateMember={handleUpdateMember} handleClose={closeAppModal} />
      </dialog>
    </div>
  );
}


type AddCredentialModalProps = ModalProps & {
  closeAppModal: () => void;
  handleSubmitCredential: (credential: CredentialSchema) => void
}

const AddCredentialModal: React.FC<AddCredentialModalProps> = (props) => {
  var { isOpen, handleSubmitCredential, closeAppModal } = props

  return (
    <div
      className={`inset-0 ${isOpen ? "block" : "hidden"}`}
    >
      <div className="fixed inset-0 bg-white opacity-90" onClick={() => closeAppModal()}></div>

      <dialog
        data-testid="add-credential-modal"
        className="z-50 top-20 bg-[#EEE] p-4 rounded-md shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)] md:w-1/2"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <AddCredentialForm handleSubmitCredential={handleSubmitCredential} handleClose={closeAppModal} />
      </dialog>
    </div>
  );
}

type CredentialDetailsModalProps = ModalProps & {
  closeAppModal: () => void;
  credential: CredentialSchema
  handleUpdateCredential: (credential: CredentialSchema) => void
}

const CredentialDetailsModal: React.FC<CredentialDetailsModalProps> = (props) => {
  var { isOpen, credential, handleUpdateCredential, closeAppModal } = props

  return (
    <div
      className={`inset-0 ${isOpen ? "block" : "hidden"}`}
    >
      <div className="fixed inset-0 bg-white opacity-90" onClick={() => closeAppModal()}></div>

      <dialog
        data-testid="view-kitchen-modal"
        className="z-50 top-20 bg-[#EEE] p-4 rounded-md shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)] w-auto"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <CredentialDetails credential={credential} handleUpdateCredential={handleUpdateCredential} />
      </dialog>
    </div>
  );
}

type AddKitchenModalProps = ModalProps & {
  closeAppModal: () => void;
};


const DefaultModal: React.FC<AddKitchenModalProps> = (props) => {
  var { isOpen, closeAppModal } = props
  const { isLoading, addKitchenSpace } =  useKitchenHook()
  if (isLoading){
    return (
      <LoadingScreen />
    )
  }
  

  const handleAddKitchen = (kitchen: KitchenSpaceSchema | {}) => {
    addKitchenSpace(kitchen)
    closeAppModal();
  }

  return (
    <div
      className={` inset-0 ${isOpen ? "block" : "hidden"} max-w-full`}
    >
      <div className="bg-white opacity-90 absolute inset-0 w-full max-h-full"></div>

      <dialog
        data-testid="add-kitchen-modal"
        className="z-50 bg-white p-4 rounded-md shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)] md:w-[1158px] w-full max-w-screen-md"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <AddKitchenForm handleClose={closeAppModal} handleAddKitchen={handleAddKitchen} />
      </dialog>
    </div>
  );
};


const AddKitchenModal: React.FC<AddKitchenModalProps> = (props) => {
  var { isOpen, closeAppModal } = props
  const { isLoading, addKitchenSpace } =  useKitchenHook()
  if (isLoading){
    return (
      <LoadingScreen />
    )
  }
  const handleAddKitchen = (kitchen: DSCommunitySchema | {}) => {
    addKitchenSpace(kitchen)
    closeAppModal();
  }

  return (
    <div
      className={` inset-0 ${isOpen ? "block" : "hidden"} w-full `}
    >
      <div className="fixed inset-0 bg-white opacity-90" onClick={() => closeAppModal()}></div>
      <dialog
        data-testid="add-kitchen-modal"
        className="absolute z-50 top-20 p-4 rounded-md shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)] md:w-[1158px} ml-14 md:mx-36"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <AddKitchenForm handleClose={closeAppModal} handleAddKitchen={handleAddKitchen} />
      </dialog>
    </div>
  );
};

type AddPlanModalProps = ModalProps & {
  resource?: KitchenSpaceSchema | ResourceSchema
  closeAppModal: () => void;
}

const AddPlanModal: React.FC<AddPlanModalProps> = (props) => {
  var { isOpen, resource, closeAppModal } = props
  const { isLoading, addCommunityPlan } = useResourceHook({});
  if (isLoading){
    return (
      <LoadingScreen bgColor="white" height="h-auto" />
    )
  }
  const handleAddPlan = (plan: PlanSchema) => {
    if(plan !== undefined){
      addCommunityPlan(plan)
    }
    closeAppModal();
  }

  return (
    <div
      className={`inset-0 ${isOpen ? "block" : "hidden"}`}
    >
      <div className="fixed inset-0 bg-white opacity-90" onClick={() => closeAppModal()}></div>

      <dialog
        data-testid="add-plan-modal"
        className="z-50 top-20 w-[628px] h-auto bg-[#fff] p-4 rounded-xl shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)]"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <AddPlanForm resource={resource} handleClose={closeAppModal} handleAddPlan={handleAddPlan} />
      </dialog>
    </div>
  );
}


type AddRequirementProps = ModalProps & {
  closeAppModal: () => void;
  handleAddCustomRequirement: (req: RequirementSchema) => void
}

const AddRequirementModal: React.FC<AddRequirementProps> = (props) => {
  var { isOpen, closeAppModal, handleAddCustomRequirement } = props
  // const { isLoading, addKitchenRequirement} = useKitchenHook();
  // if (isLoading){
  //   return (
  //     <LoadingScreen />
  //   )
  // }
  const handleAddRequirement = (req: RequirementSchema| {}) => {
    if(req !== undefined){
      // addKitchenRequirement(req)
      handleAddCustomRequirement(req)
    }
    closeAppModal();
  }

  return (
    <div
      className={`inset-0 ${isOpen ? "block" : "hidden"}`}
    >
      <div className="fixed inset-0 bg-white opacity-90" onClick={() => closeAppModal()}></div>

      <dialog
        data-testid="add-custom-requirement"
        className="z-50 top-20 w-[628px] h-auto bg-[#fff] p-4 rounded-xl shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)]"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <AddRequirementForm handleClose={closeAppModal} handleAddRequirement={handleAddRequirement} />
      </dialog>
    </div>
  );
}


type AddInventoryModalProps = ModalProps & {
  kitchen?: KitchenSpaceSchema
  closeAppModal: () => void;
}

const AddInventoryModal: React.FC<AddInventoryModalProps> = (props) => {
  var { isOpen, closeAppModal } = props
  const { isLoadingInventory, addInventoryItem} = useAddInventory();
  if (isLoadingInventory){
    return (
      <LoadingScreen />
    )
  }
  const handleAddInventory = (inventory: InventorySchema) => {
    if(inventory !== undefined){addInventoryItem(inventory)}

    closeAppModal();
  }

  return (
    <div
      className={`inset-0 ${isOpen ? "block" : "hidden"}`}
    >
      <div className="fixed inset-0 bg-white opacity-90" onClick={() => closeAppModal()}></div>

      <dialog
        data-testid="add-kitchen-modal"
        className="fixed z-50 top-20 bg-[#EEE] px-4 rounded-md shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)] md:w-1/4 "
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <AddInventoryForm handleClose={closeAppModal} handleAddInventory={handleAddInventory} />
      </dialog>
    </div>
  );
}


type AddReservationModalProps = ModalProps & {
  closeAppModal: () => void;
  bookings: BookingSchema[];
}

const AddReservationModal: React.FC<AddReservationModalProps> = (props) => {
  var { isOpen, closeAppModal } = props
  return (
    <div
      className={`inset-0 ${isOpen ? "block" : "hidden"}`}
    >
      <div className="fixed inset-0 bg-white opacity-90" onClick={() => closeAppModal()}></div>

      <dialog
        data-testid="add-kitchen-modal"
        className="z-50 top-20 bg-[#EEE] p-4 rounded-md shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)] w-fit"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <AddReservationForm isOpen={isOpen} handleClose={closeAppModal} />
      </dialog>
    </div>
  );
};



type AddMemberFormModalProps = ModalProps & {
  closeAppModal: () => void;
};

const AddMemberFormModal: React.FC<AddMemberFormModalProps> = (props) => {
  var { isOpen, closeAppModal } = props
  const handleAddMember = (kitchen: DSCommunitySchema | {}) => {
    closeAppModal();
  }

  return (
    <div
      className={` inset-0 ${isOpen ? "block" : "hidden"}`}
    >
      <div className="fixed inset-0 bg-white opacity-90" onClick={() => closeAppModal()}></div>

      <dialog
        data-testid="add-kitchen-modal"
        className="z-50 top-20 bg-back-400 p-4 rounded-md shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)] md:w-[1158px}"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <AddMemberForm isOpen={isOpen} handleClose={closeAppModal} handleAddMember={handleAddMember} />
      </dialog>
    </div>
  );
};



type NewMessageModalProps = ModalProps & {
  closeAppModal: () => void;
};

const NewMessageModal: React.FC<NewMessageModalProps> = (props) => {
  var { isOpen, closeAppModal } = props
  


  return (
    <div
      className={` inset-0 ${isOpen ? "block" : "hidden"} flex items-center justify-center`}
    >
      <div className="fixed inset-0 bg-white opacity-90 z-50" onClick={() => closeAppModal()}></div>

      <dialog
        className="absolute z-50 bg-slate-50 p-4 rounded-md shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)] md:w-[1158px} top-10  "
        
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <NewMessageForm handleClose={closeAppModal} />
      </dialog>
    </div>
  );
};


type AddResourceModalProps = ModalProps & {
  resourceType: ResourceType ;
  handleAddResource: (resource: ResourceSchema) => void
  closeAppModal: () => void;
};

export const AddResourceModal: React.FC<AddResourceModalProps> = (props) => {
  let { isOpen, resourceType, handleAddResource, closeAppModal } = props

  return (
    <div
    className={` inset-0 ${isOpen ? "block" : "hidden"} flex items-center justify-center`}
    >
      <div className="fixed inset-0 bg-white opacity-90 z-50"></div>

      <dialog
        data-testid="add-resource-modal"
        className="z-50 top-20 w-[628px] h-auto bg-[#fff] p-4 rounded-xl shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)]"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <AddResourceForm resourceType={resourceType} handleClose={closeAppModal} handleAddResource={handleAddResource} />
      </dialog>
    </div>
  );
};


type AddProductModalProps = ModalProps & {
  resourceType: ResourceType ;
  resourceId: string
  setCurrentInventoryList: React.Dispatch<React.SetStateAction<InventorySchema[]>> | React.Dispatch<React.SetStateAction<TrainingInventorySchema[]>>
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  // handleReloadResource: () => void
  closeAppModal: () => void;
};

export const AddProductModal: React.FC<AddProductModalProps> = (props) => {
  let { isOpen, resourceType, resourceId, setIsOpen, setCurrentInventoryList, closeAppModal } = props
  const { getResourceIconData } = useLoadResourceOptions()
  const resourceIconData = getResourceIconData(resourceType)

  return (
    <div
    className={` inset-0 ${isOpen ? "block" : "hidden"} flex items-center justify-center`}
    >
      <div className="fixed inset-0 bg-white opacity-90 z-50"></div>

      <dialog
        data-testid="add-resource-modal"
        className="z-50 top-20 w-[628px] h-auto bg-[#fff] p-4 rounded-xl shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)]"
        open={isOpen}
        onClose={closeAppModal}
      >
        <span onClick={closeAppModal} className="ml-auto w-full flex cursor-pointer">
            <Cancel className="text-[#A1A1A1] hover:text-[#777] h-[18px] w-[18px] ml-auto" />
        </span>
        <div className="w-11/12 mx-auto">
          <AddProductForm 
            resourceType={resourceType} 
            resourceId={resourceId}
            resourceCategory={resourceIconData.name} 
            setCurrentInventoryList={setCurrentInventoryList} 
            setIsAddingProduct={setIsOpen}
            // handleReloadResource={handleReloadResource}
            isModal={true}
          />
        </div>
        
      </dialog>
    </div>
  );
};


type DeleteConfirmationModalProps = ModalProps & {
  closeDeleteModal: () => void;
  handleDelete: (dataId: string) => void
  dataName: string
  data: any;
};

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = (props) => {
  let { isOpen, data, dataName, closeDeleteModal, handleDelete } = props;
  return (

    <div
      className={` inset-0 ${isOpen ? "block" : "hidden"} flex items-center justify-center`}
    >
      <div className="fixed inset-0 bg-white opacity-90 z-50"></div>
      <dialog
        data-testid="delete-data-modal"
        className="z-50 top-20 w-[628px] h-auto bg-[#fff] p-4 px-1 rounded-xl shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)]"
        open={isOpen ? true : false}
        onClose={() => closeDeleteModal()}
      >
     
        <DeleteConfirmationForm
          data={data}
          dataName={dataName}
          handleClose={() => closeDeleteModal()}
          handleDelete={handleDelete}
        />

      </dialog>
    </div>
  );
};




type ConfirmationModalProps = ModalProps & {
  message: string
  closeModal: () => void;
};

export const ConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {
  let { isOpen , message, closeModal } = props;
  // const [isConfirmBoxOpen, toggleConfirmBox] = useState<boolean>(isOpen)
  return (
    <div
      className={` inset-0 ${isOpen ? "block" : "hidden"} flex items-center justify-center`}
    >
      <div className="fixed inset-0 bg-white opacity-90 z-50"></div>
      <dialog
        data-testid="delete-data-modal"
        className="z-50 top-20 w-[300px] rounded-[32px] h-auto bg-[#fff] p-4 px-1 shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)]"
        open={isOpen ? true : false}
        onClose={closeModal}
      >
        <div className="w-11/12 mx-auto font-raleway text-[16px] text-[#333] font-medium">
          <CheckCircle className="text-[#0F973D]" /> &nbsp;
          {capitalizeWords(message)}
        </div>
      </dialog>
    </div>
  );
};

// type InformationModalProps = ModalProps & {
//   closeInfoModal: () => void;
//   kitchen: DSCommunitySchema;
// };

// const InformationModal: React.FC<InformationModalProps> = (props) => {
//   let { isOpen, closeInfoModal, kitchen } = props;
//   return (
//     <Dialog
//       fullWidth
//       open={isOpen ? true : false}
//       onClose={() => closeInfoModal()}
//     >
//       <div className="grid">
//         <div className="grid">
//           <KitchenDetails kitchen={kitchen} />
//         </div>
//       </div>
//     </Dialog>
//   );
// };

export { 
  AddKitchenModal, DeleteConfirmationModal, AddInventoryModal,
  NewMessageModal, AddReservationModal, AddMemberFormModal, DefaultModal, 
  AddPlanModal,AddRequirementModal, AddCredentialModal, CredentialDetailsModal 
};
