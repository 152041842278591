import { 
  // useState, 
  useEffect } from "react";

// import SearchBox from "../../components/SearchBox";
// import MembersList from "../../components/MembersList";
// import { AddMemberFormModal } from "../../components/AppModal";
// import { useMemberState } from "../../contexts/membercontext";
// import { useResourceState } from "../../contexts/kitchencontext";
import { useLoadMemberships } from "../../hooks/memberhooks";
// import EditMultipleMembers from '../../molecules/EditMultipleMembers'

const TransactionsPage = () => {
  var { memberships } = useLoadMemberships();
  // const { membershipRequests, addingMembers, setAddingMembers } = useMemberState();
  // const [add_member, toggleNewMember] = useState(false);

  // const { currentKitchen } = useResourceState()
  // let kitchen_capacity = currentKitchen?.capacity ? parseInt(currentKitchen.capacity) : 1
  


  // const closeAppModal = () => {
  //   toggleNewMember(false);
  //   setAddingMembers(false)
  // };

  useEffect(() => {
    document.title = "HomeCook | Transactions";
    // eslint-disable-next-line
  }, [memberships]);

  
  // addingMembers ? (
  //   <div className="md:p-[3%] w-full bg-back-400 pt-16 md:pt-16 font-open-sans flex flex-col gap-10">
  //     <div>
  //       <h1 className="text-3xl font-bold text-center justify-self-center">Add New Member Details</h1>
  //       <h1 className="text-md text-center justify-self-center">Include plans & other membership details for new members.</h1>
  //     </div>
  //     {/* <EditMultipleMembers closeAppModal={closeAppModal} cancelEditMember={() => setAddingMembers(false)}/> */}
  //   </div>
  // ) : 
  return (
    <div className="p-[1%] w-full bg-[#F5F5F5] font-open-sans mt-[72px] h-screen">
      <div className="grid">
      </div>
    </div>


  );
};

export default TransactionsPage;
