import { useState, useEffect } from "react";

  import { AddCircle, CheckBox, CheckBoxOutlineBlank, CheckCircle, Delete } from "@mui/icons-material";
// import SearchBox from "../../components/SearchBox";
import MembersList from "../../components/MembersList";
// import { AddMemberFormModal } from "../../components/AppModal";
import { useMemberState } from "../../contexts/membercontext";
// import { useResourceState } from "../../contexts/kitchencontext";
import {useLoadCommunity} from "../../hooks/authhooks"; 
import { useLoadMemberships, useLoadRequests } from "../../hooks/memberhooks";
import { useLoadPlans, useLoadRequirements, useResourceHook } from "../../hooks/kitchenhooks";
import EditMultipleMembers from '../../molecules/EditMultipleMembers'
import NewSearch from "../../molecules/NewSearch";
import { ReactComponent as KitchenIcon } from '../../icons/KitchenIcon.svg'
import { CommunityOptionType, DSCommunitySchema, PlanSchema, RequirementSchema, RequirementsLookupSchema, ResourceSchema } from "../../data/types";
// import { AddCircle } from "@mui/icons-material";
import { capitalizeString, getChangedAttributes } from "../../utils/helpers";
import { requirementsLookUp, usStates, USStatesEnum } from "../../utils/enums";
import { areArraysEqualByProps } from "../../utils/helpers";
import { useAuthState } from '../../contexts/authcontext'
// import { ReactComponent as EditProfileIcon } from '../../icons/EditProfileIcon.svg'
// import { ReactComponent as AddImageIcon } from '../../icons/AddImageIcon.svg'
import { CustomAddImageBox } from '../../components/AddImageBox'
// import { CommunityRequirementsForm } from "../../molecules/forms";
import { AllMemberRequests } from "./RequestsPage";
import { AddPlanModal, AddRequirementModal, ConfirmationModal, DeleteConfirmationModal } from "../../molecules/AppModal";
import LoadingScreen from "../shared/LoadingScreen";





const CommunityPage = () => {

  var { authUser } = useAuthState()
  var communityId = (authUser && authUser.primary_community) ? authUser.primary_community : ""
  var { primaryCommunity } = useLoadCommunity(communityId)

  var { memberships } = useLoadMemberships();
  const { membershipRequests, addingMembers, setAddingMembers } = useMemberState();
  const [currentOption, setCurrentOption] = useState<CommunityOptionType>({id: "details", name: "Community Details"});

  const closeAppModal = () => {
    setAddingMembers(false)
  };

  const communityOptions = [
    {
      id: "details",
      name: "Community Details"
    },
    {
      id: "images",
      name: "Logo and Images"
    },
    {
      id: "plans",
      name: "Plans"
    },
    {
      id: "requirements",
      name: "Requirements"
    },
    {
      id: "memberships",
      name: "Memberships"
    },
    {
      id: "reviews",
      name: "Reviews"
    }
  ]


  const [currentCommunity, setCurrentCommunity] = useState<DSCommunitySchema>(primaryCommunity as DSCommunitySchema)
  const [isOpenPlan, setIsOpenPlan] = useState<boolean>(false);
  // const { memberships } = useLoadMemberships();
  // const { membershipRequests } = useLoadRequests();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target
    if (name && (value || value === "")){
      setCurrentCommunity(prev => (
        {
          ...prev,
          [name]: value
        }
      ))
    }
  }

  useEffect(() => {
    document.title = `HomeCook | ${currentOption.name}`;
    // eslint-disable-next-line
  }, [memberships, membershipRequests]);

  return addingMembers ? (
    <div className="md:p-[3%] w-full bg-back-400 pt-16 md:pt-16 font-open-sans flex flex-col gap-10">
      <div>
        <h1 className="text-3xl font-bold text-center justify-self-center">Add New Member Details</h1>
        <h1 className="text-md text-center justify-self-center">Include plans & other membership details for new members.</h1>
      </div>
      <EditMultipleMembers closeAppModal={closeAppModal} cancelEditMember={() => setAddingMembers(false)}/>
    </div>
  ) : (
    <div className="w-full bg-[#F5F5F5] font-open-sans mt-[72px] h-auto">
      <div className="flex flex-row bg-white h-[75px] w-full">
        {
          communityOptions.map((option, i) => {
            return (
              <div 
                key={i} 
                onClick={()=>setCurrentOption(option)} 
                className={`${(currentOption.id === option.id) ? 'text-white bg-primary-200' : 'text-[#4D4D4D] '} ml-6 my-auto text-[16px] font-raleway border-[1px] border-[#E9E9E9] py-2 px-4 rounded-md cursor-pointer`}
              >
                  {option.name}
              </div>
            )
          })
        }
      </div>
      {
        (currentOption.id === "details") ? (<CommunityDetails currentCommunity={primaryCommunity as DSCommunitySchema} community={currentCommunity} handleChange={handleChange} />) : (
          (currentOption.id === "memberships") ? (<Membership communityId={communityId} />) : (
            (currentOption.id === "plans") ? (<MemberPlans openAddModal={()=> setIsOpenPlan(true)} />) : (
              (currentOption.id === "requirements") ? (<CommunityRequirementsForm communityId={communityId} />) : (
                (currentOption.id === "images") ? (<LogoAndImages currentCommunity={primaryCommunity as DSCommunitySchema} community={currentCommunity} handleChange={handleChange} />) : (
                  <Reviews/>
                )
              )
            )
          )
        )
      }

      <AddPlanModal isOpen={isOpenPlan} closeAppModal={()=> setIsOpenPlan(false)} />
    </div>
  );
};


type CommunityDetailsProps = {
  community: DSCommunitySchema
  currentCommunity: DSCommunitySchema
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void
}

const CommunityDetails: React.FC<CommunityDetailsProps> = ({community, currentCommunity, handleChange}) => {
  const communityId = community?.id ? community.id : ""
  var { updateCommunityDetails, isUpdateSuccess, isUpdateError, isUpdatePending } = useLoadCommunity(communityId)
  const [isConfirmBoxOpen, toggleConfirmBox] = useState<boolean>(!isUpdatePending && (isUpdateSuccess || isUpdateError))

  const handleSaveChanges = () => {
    var changes = getChangedAttributes<DSCommunitySchema>(currentCommunity as DSCommunitySchema, community as DSCommunitySchema);
    // console.log("changes", changes)
    if (Object.keys(changes).length > 0){
      updateCommunityDetails(communityId, changes)
    }
  }


  const zipCodeRegex = /^\d{5}(-\d{4})?$/;

  useEffect(() => {
    document.title = "HomeCook | Community Details";
    // eslint-disable-next-line
  }, [community]);
  return (isUpdatePending) ? (
    <LoadingScreen />
  ) : (
    <>
      <div className="flex flex-row bg-white h-[75px] w-full border-t-[#E6E6E6] border-t-[1px]">
        <p className="mx-[24px] my-auto font-open-sans text-[16px] text-[#4D4D4D]/70">
            View and edit your community details.
        </p>
        {
          (
            ((community?.name !== undefined && currentCommunity?.name !== undefined) && (community?.name !== currentCommunity?.name)) ||
            ((community?.description !== undefined && currentCommunity?.description !== undefined ) && (community?.description !== currentCommunity?.description)) ||
            ((community?.address !== undefined && currentCommunity?.address !== undefined) && (community?.address !== currentCommunity?.address)) ||
            ((community?.city !== undefined && currentCommunity?.city !== undefined) && (community?.city !== currentCommunity?.city)) ||
            ((community?.state !== undefined && currentCommunity?.state !== undefined) && (community?.state !== currentCommunity?.state)) ||
            ((community?.zipcode !== undefined && currentCommunity?.zipcode !== undefined && zipCodeRegex.test(currentCommunity?.zipcode)) && (community?.zipcode !== currentCommunity?.zipcode)) ||
            ((community?.website !== undefined && currentCommunity?.website !== undefined) && (community?.website !== currentCommunity?.website))
          ) ? (
            <div className="flex ml-auto mx-[24px] h-auto">
              <button
                className="text-primary-200 h-[43px] px-2 border-[1px] border-[#E9E9E9] ml-auto rounded-lg my-auto"
                onClick={handleSaveChanges}
              >
                Save Changes
              </button>
            </div>
          ) : (
            <></>
          )
        }
      </div> 
      
      <div className="flex flex-col w-11/12 mx-auto">
        <div className="flex flex-col bg-white my-3 h-[178px] w-full rounded-lg">
          <div className="m-[24px] my-auto">
            <p className="text-[#808080] text-[16px] font-raleway my-2">Community Name</p>
            <input name="name" onChange={handleChange} className="border-[#E9E9E9] h-[51px] border-[1px] w-full rounded-md" value={community.name} />
          </div>
        </div>

        <div className="flex flex-col bg-white my-3 h-[247px] w-full rounded-lg">
          <div className="m-[24px] my-auto">
            <p className="text-[#808080] text-[16px] font-raleway my-2">Community Description</p>
            <textarea name="description"  onChange={handleChange} className="border-[#E9E9E9] h-[120px] border-[1px] w-full rounded-md" value={community.description} />
          </div>
        </div>

        <div className="flex flex-row bg-white my-3 h-[178px] w-full rounded-lg">
          <div className="m-[24px] w-full my-auto">
            <p className="text-[#808080] text-[16px] font-raleway my-2">Address</p>
            <input name="address" onChange={handleChange} className="border-[#E9E9E9] h-[51px] border-[1px] w-full rounded-md" value={community.address} />
          </div>
        </div>


        <div className="flex flex-col bg-white my-3 h-[178px] w-full rounded-lg">
          <div className='flex flex-row my-auto m-[24px]'>
            <div className='my-4 mr-1 w-1/4'>
              <p className="text-[#808080] text-[16px] font-raleway my-2">City</p>
              <input 
                type='text' 
                placeholder='Enter City' 
                className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
                name="city"
                value={community?.city}
                onChange={handleChange}
              />
            </div>
            <div className='my-4 mr-1 w-1/4'>
              <p className="text-[#808080] text-[16px] font-raleway my-2">State</p>
              <select 
                name="state" 
                value={community?.state} 
                className="border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]"
                onChange={handleChange}
              >
                <option value="">-- Select State --</option>
                <option value="nationwide">All</option>
                {
                    (usStates && usStates.length > 0) && usStates.map((state, i) => (
                        <option value={state} key={i}>{USStatesEnum[state]?.fullName}</option>
                    ))
                }
              </select>
            </div>
            <div className='my-4 w-1/4'>
              <p className="text-[#808080] text-[16px] font-raleway my-2">Zipcode</p>
              <input 
                type='text' 
                placeholder='Enter Zipcode' 
                className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
                name="zipcode"
                value={community?.zipcode}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-row bg-white my-3 h-[178px] w-full rounded-lg">
          <div className="m-[24px] w-full my-auto">
            <p className="text-[#808080] text-[16px] font-raleway my-2">Website Link</p>
            <input name="website" onChange={handleChange} className="border-[#E9E9E9] h-[51px] border-[1px] w-full rounded-md" value={community.website} />
          </div>
        </div>

      </div>

      <ConfirmationModal  
        isOpen={isConfirmBoxOpen} 
        message={isUpdateSuccess ? "All changes have been saved": "Update Failed"} 
        closeModal={()=>toggleConfirmBox(false)}
      />
    </>
  )
}


type MembershipProps = {
  communityId: string
}

const Membership: React.FC<MembershipProps> = ({communityId}) => {

  const membershipOptions = [
    {
      id: "members",
      name: "Current Members",
    },
    // {
    //   id: "plans",
    //   name: "Plans",
    // },
    // {
    //   id: "requirements",
    //   name: "Requirements",
    // },
    {
      id: "requests",
      name: "Member Requests",
    }
  ]

  const [currentOption, setCurrentOption] = useState<CommunityOptionType>(membershipOptions[0])
  const [isOpenPlan, setIsOpenPlan] = useState<boolean>(false);
  const { memberships } = useLoadMemberships();
  const { membershipRequests } = useLoadRequests();

  useEffect(() => {
    document.title = "HomeCook | Memberships";
  }, []);


  return (
    <>
      <div className="flex flex-row bg-white h-[75px] w-full my-1">
        {
          membershipOptions.map((membershipOption: CommunityOptionType, i: number) => {
            return (
              <div 
                key={i} 
                onClick={()=>setCurrentOption(membershipOption)} 
                className={`${(currentOption.id === membershipOption.id) ? 'text-white bg-primary-200/80' : 'text-[#4D4D4D] '} ml-6 my-auto text-[16px] font-raleway border-[1px] border-[#E9E9E9] py-2 px-4 rounded-md cursor-pointer`}
              >
                  {membershipOption.name}&nbsp;
                  {
                    (memberships && (membershipOption.name === "Current Members")) && `(${memberships?.length})`
                  }
                  {
                    (membershipRequests && (membershipOption.name === "Member Requests")) && `(${membershipRequests?.length})`
                  }
              </div>
            )
          })
        }
      </div>
      {
        (currentOption.name === "Current Members") ? (<AllMembers memberships={memberships} />) : (
          (currentOption.name === "Plans") ? (<MemberPlans openAddModal={()=> setIsOpenPlan(true)} />) : (
            <AllMemberRequests membershipRequests={membershipRequests} />
          )
        )
      }
      <AddPlanModal
        isOpen={isOpenPlan}
        closeAppModal={() => setIsOpenPlan(false)}
      />
  </>
  )
}


const AllMembers: React.FC<{memberships: any}> = ({memberships}) => {
  const [ searchTerm, changeSearchTerm] = useState("")
  const kitchen_capacity =  1


  useEffect(() => {
    document.title = "HomeCook | Members";
  }, [memberships]);

  return (
    <div className="flex flex-col w-11/12 bg-white mx-auto my-6 rounded-md min-h-[777px]">
      <div className="flex flex-row w-full justify-between">
        <p className=" font-raleway mx-[24px] mt-[24px] text-[#8A8A8A] text-[16px]" >
          Showing 1 - 1 of {memberships && memberships.length ? memberships.length : "0"} Members
        </p>

        <div className="flex ml-auto w-1/4 mr-[24px]">
          <NewSearch searchType="member name" searchTerm={searchTerm} changeSearchTerm={changeSearchTerm} />
        </div>
        
      </div>

      <div className="w-auto mx-[24px]">
        {
          memberships && memberships.length ? (
            <MembersList kitchen_capacity={kitchen_capacity} members={memberships ? memberships : []} />
          ) : (
            <div className="flex flex-row justify-evenly font-light text-center text-[#013847]/40 ">
              No members to show yet
            </div>
          )
        }
      </div>
      
    </div>
  )
}


type MemberPlansProps = {
  openAddModal: ()=> void
}

const MemberPlans: React.FC<MemberPlansProps> = ({openAddModal}) => {

  const {plans} = useLoadPlans()
  const { deletePlan } = useResourceHook({})
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const handleDelete = (planId: string) => {
    if (planId){
      deletePlan(planId)
    }
    setIsDeleting(false)
  }

  useEffect(() => {
    document.title = "HomeCook | Community Plans";
  }, [plans]);

  return (
    (plans && plans.length > 0 ) ? (
      <>
        <div className="flex flex-row mx-auto w-11/12">
          <button 
            className="bg-primary-200 text-[16px] font-raleway text-white p-2 rounded-md mt-2 mx-2  w-auto h-[54px] ml-auto"
            onClick={openAddModal}
          >
            <AddCircle className="my-auto mx-2 text-white" style={{height: 20, width: 20}} />
            Add Custom Plan
          </button>
        </div>
        <div className="grid grid-cols-2 grid-flow-dense w-11/12 mx-auto">
          {
            plans.map((plan: PlanSchema, i: number) => (
              <div key={i} className="flex flex-col h-[241px] bg-white mx-2 my-4 rounded-lg">
                <div className="m-[24px] text-black font-raleway text-[20px] font-medium">
                  {plan.plan_name}
                </div>
                <hr className="border-[#E9E9E9] b-0 border-b-[1px]" />
                <div className="flex flex-row m-[24px]">
                  <div className="flex flex-col">
                    <p className="text-[#808080] text-[16px] font-raleway">Plan Rate</p>
                    <p className="text-[16px] font-open-sans">${plan.plan_rate}</p>
                  </div>
                  <div className="w-[2px] h-[32px] bg-[#E9E9E9] mx-auto" />
                  <div className="flex flex-col">
                    <p className="text-[#808080] text-[16px] font-raleway">Frequency</p>
                    <p className="text-[16px] font-open-sans">{capitalizeString((plan && plan?.plan_recurrence) ? plan.plan_recurrence : "N/A")}</p>
                  </div>
                  <div className="w-[2px] h-[32px] bg-[#E9E9E9] mx-auto" />
                  <div className="flex flex-col">
                    <p className="text-[#808080] text-[16px] font-raleway">Max Hours</p>
                    <p className="text-[16px] font-open-sans">{plan.plan_max_hours}</p>
                  </div>

                  <div className="w-[2px] h-[32px] bg-[#E9E9E9] mx-auto" />
                  <div className="flex flex-col">
                    <p className="text-[#808080] text-[16px] font-raleway">Capacity</p>
                    <p className="text-[16px] font-open-sans">0 Members</p>
                  </div>

                </div>
                <div className="flex w-full mt-auto bg-primary-200 text-white h-[75px] font-raleway rounded-b-lg">
                  <button 
                    className="ml-auto my-auto mx-[24px] font-medium"
                    onClick={()=> setIsDeleting(true)}
                  >
                    Delete
                  </button>
                </div>

                <DeleteConfirmationModal dataName="plan" isOpen={isDeleting} data={plan} handleDelete={handleDelete} closeDeleteModal={()=>setIsDeleting(false)} />
              </div>
            ))
          }
        </div>
      </>
    ) : (
      <div className="flex w-[423px] h-[350px] bg-white m-auto my-[48px] rounded-lg">
        <div className="flex flex-col m-auto w-9/12">
          <div className="flex mx-auto rounded-full h-[80px] w-[80px] bg-[#EFF8FA] mt-auto">
            <KitchenIcon className="m-auto text-primary-400 h-[40px] w-[40px]" />
          </div>
          <p className="font-semibold font-raleway text-[20px] text-primary-200 text-center mt-4">
            No Member Plan Yet
          </p>
          <p className="text-[#333333] font-open-sans text-[16px] text-center my-3">
            Set up a plan to manage access to your kitchen spaces
          </p>
          <button 
            className="bg-primary-200 text-white w-full p-2 rounded-md my-2"
            onClick={openAddModal}
          >
            <AddCircle className="my-auto mx-2 text-white" style={{height: 24, width: 25}} />
            Add Member Plan
          </button>
        </div>
      </div>
    )
  )
}




type CommunityRequirementsFormProps = {
  communityId: string
}

export const CommunityRequirementsForm: React.FC<CommunityRequirementsFormProps> = ({communityId}) => {

  const { requirements, isLoading, isError, isSuccess, addCommunityRequirements, addCustomRequirement, deleteResourceRequirement } = useLoadRequirements()
  const [selectedRequirements, setSelectedRequirements] = useState<RequirementSchema[]>(requirements)
  const [isAddingCustomRequirement, setIsAddingCustomRequirement] = useState<boolean>(false)
  const [isDeletingCustomRequirement, setIsDeletingCustomRequirement] = useState<boolean>(false)
  const [deleteReq, setDeleteReq] = useState<RequirementSchema>()

  // console.log("requirements", requirements)
  // console.log("selectedRequirements", selectedRequirements)

  // Check if item already exists in the array & add item if it doesn't exist
  const addSelection = (addedRequirement: RequirementsLookupSchema | RequirementSchema) => {
    const { requirementName, requirementCode } = addedRequirement as RequirementsLookupSchema
    // const { name, code } = addedRequirement as RequirementSchema
    const exists = selectedRequirements.find((item: RequirementSchema) => item.name === requirementName);
    if (!exists) {
      setSelectedRequirements(
        [
          ...selectedRequirements, 
          {
            name: requirementName, 
            code: requirementCode,
            community_id: communityId,
            // resource_id: resourceId ? resourceId : ""
          }
        ]
      )
    }
  };

  // Remove item by filtering out the item with the given name
  const removeSelection = (requirementName: string) => {
    setSelectedRequirements(selectedRequirements.filter((item: RequirementSchema) => item.name !== requirementName))
  };

  // const { addCommunityRequirements, isLoading, isError, isSuccess } = useResourceHook({});

  const handleAddRequirements = () => {
    if(selectedRequirements.length > 0){
      addCommunityRequirements(selectedRequirements)
    }
  }

  const handleAddCustomRequirement = (req: RequirementSchema) => {
    if(req?.name){
      addCustomRequirement(req)
    }
  }

  const handleDeleteCustomRequirement = async (reqId: string) => {
    if (reqId){
      await deleteResourceRequirement(reqId)
    }
    setDeleteReq(undefined)
    setIsDeletingCustomRequirement(false)
  }

  useEffect(() => {
    document.title = "HomeCook | Community Requirements";
    setSelectedRequirements(requirements)
  }, [requirements]);

  if (isLoading){
    return (
      <LoadingScreen bgColor="white" height="h-auto" />
    )
  } else {
    return (
      <>
        <div className="flex flex-col w-11/12 bg-white mx-auto my-6 rounded-md h-[777px]">
    
          <div className="flex flex-row w-auto mx-[24px] my-4">
            <p className="text-[#4D4D4D] text-[16px] font-open-sans w-[313px]">
              Choose requirements from the list below, or add your own.
            </p>
            {        
              (
                (!areArraysEqualByProps(requirements, selectedRequirements, ['name']))
              ) ? (
                <button 
                  className="bg-primary-200 text-white ml-auto w-[210px] rounded-md text-[16px] font-raleway"
                  onClick={handleAddRequirements}
                >
                  <CheckCircle className="my-auto mx-2 text-white" style={{height: 17, width: 17}} />
                  Save Changes
                </button>
              ) : (<></>)
            }
          </div>
          {
            (isSuccess && !isError && !isLoading) && (
              <div className="flex flex-row w-auto font-raleway text-gray-600 text-center mx-[24px] my-4 h-[50px] bg-green-200 rounded-md">
                <p className="m-auto">Update Successful</p>
              </div>
            )
          }
          {
            (isError) && (
              <div className="flex flex-row w-auto font-raleway text-gray-700 text-center mx-[24px] my-4 h-[50px] bg-red-200 rounded-md">
                <p className="m-auto">Update Failed</p>
              </div>
            )
          }
          <div className="flex flex-col w-auto mx-[24px] my-4 h-[777px] overflow-y-scroll">
            {
              (requirementsLookUp && requirementsLookUp.length > 0) ? (
                requirementsLookUp.map((req, i: number)=> (
    
                  (selectedRequirements && selectedRequirements.length > 0 && selectedRequirements.some((requirement: RequirementSchema) => requirement.code === req.requirementCode)) ? (
                    <div 
                      key={i} 
                      className={`flex flex-row bg-primary-200 text-white text-[16px] font-open-sans rounded-md my-1 p-2 h-[59px] cursor-pointer`}
                      onClick={() => removeSelection(req.requirementName)}
                    >
                        <CheckBox className="mx-2 my-auto" style={{height: 20, width: 20}} />
                        <p className="my-auto">{req.requirementName}</p>
                    </div>
                  ) : (
                      <div 
                        key={i} 
                        className={`flex flex-row border-[1px] hover:bg-[#E9E9E9] border-[#E9E9E9] text-[16px] font-open-sans rounded-md my-1 p-2 h-[59px] cursor-pointer`}
                        onClick={() => addSelection(req)}
                      >
                        <CheckBoxOutlineBlank 
                          className="mx-2 text-[#CFCFCF] my-auto" 
                          style={{height: 20, width: 20}} 
                        />
                      <p className="my-auto">{req.requirementName}</p>
                    </div>
                  )
                ))
              ) : (<></>)
            }
            {
              requirements.filter((reqmt: RequirementSchema) => !requirementsLookUp.some(selected => selected.requirementCode === reqmt.code)
              ).map((req: RequirementSchema, i: number) => (
              <div 
                key={i} 
                className={`flex flex-row bg-primary-200 text-white text-[16px] font-open-sans rounded-md my-1 p-2 h-[59px] cursor-pointer`}
              >
                  <CheckBox className="mx-2 my-auto" style={{height: 20, width: 20}} />
                  <p className="my-auto">{req.name}</p>
                  <div className="ml-auto cursor-pointer hover:text-slate-300">
                    <Delete onClick={()=> {
                      setDeleteReq(req)
                      setIsDeletingCustomRequirement(true)
                    }} style={{height: 20, width: 20}} />
                  </div>
              </div>
              ))
            }
          </div>
          <div onClick={()=>setIsAddingCustomRequirement(true)} className={`flex flex-row bg-[#F8F8F8] text-[16px] font-open-sans rounded-lg mx-2 p-2 h-[59px] cursor-pointer`}>
                <AddCircle className="my-auto mx-2" style={{height: 17, width: 17}} />
                <p className="my-auto text-[16px] font-open-sans text-black">Add Custom Requirement</p>
              </div>
        </div>
        <AddRequirementModal 
          isOpen={isAddingCustomRequirement} 
          closeAppModal={()=>setIsAddingCustomRequirement(false)} 
          handleAddCustomRequirement={handleAddCustomRequirement}
        />

        <DeleteConfirmationModal dataName="requirement" isOpen={isDeletingCustomRequirement} data={deleteReq} handleDelete={handleDeleteCustomRequirement} closeDeleteModal={()=>setIsDeletingCustomRequirement(false)} />
      </>
    )
  }
}



type LogoAndImagesProps = {
  community: DSCommunitySchema
  resource?: ResourceSchema
  currentCommunity: DSCommunitySchema
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void
}
const LogoAndImages: React.FC<LogoAndImagesProps> = ({community, currentCommunity, handleChange}) => {

  const communityId = community?.id ? community.id : ""
  var { updateCommunityDetails, isUpdateSuccess, isUpdateError, isUpdatePending } = useLoadCommunity(communityId)
  const [isConfirmBoxOpen, toggleConfirmBox] = useState<boolean>(!isUpdatePending && (isUpdateSuccess || isUpdateError))

  const [selectedLogo, setSelectedLogo] = useState(currentCommunity.logo);
  const [selectedBgPhoto, setSelectedBgPhoto] = useState(currentCommunity.bgPhoto);

  const handleSaveChanges = () => {
    community.logo = selectedLogo
    community.bgPhoto = selectedBgPhoto

    var changes = getChangedAttributes<DSCommunitySchema>(currentCommunity as DSCommunitySchema, community as DSCommunitySchema);
    // console.log("changes", changes)
    if (Object.keys(changes).length > 0){
      updateCommunityDetails(communityId, changes)
    }
  }

  useEffect(() => {
    document.title = "HomeCook | Logo & Images";
  }, []);

  return (isUpdatePending) ? (
    <LoadingScreen />
  ) : (
    <>
      <div className="flex flex-row bg-white h-[75px] w-full border-t-[#E6E6E6] border-t-[1px]">
        <p className="mx-[24px] my-auto font-open-sans text-[16px] text-[#4D4D4D]/70">
            View and edit your community logo and images.
        </p>
        {
          (
            ((currentCommunity?.bgPhoto === undefined && selectedBgPhoto !== undefined) || (selectedBgPhoto !== currentCommunity?.bgPhoto)) ||
            ((currentCommunity?.logo === undefined  && selectedLogo !== undefined) || (selectedLogo !== currentCommunity?.logo))
          ) ? (
            <div className="flex ml-auto mx-[24px] h-auto">
              <button
                className="text-primary-200 h-[43px] px-2 border-[1px] border-[#E9E9E9] ml-auto rounded-lg my-auto"
                onClick={handleSaveChanges}
              >
                Save Changes
              </button>
            </div>
          ) : (
            <></>
          )
        }
        
      </div> 
      <div className="flex flex-col w-11/12 mx-auto font-open-sans h-auto">
        <div className="flex flex-col bg-white my-3 h-[324px] w-full rounded-lg">
          <div className="flex flex-col m-[24px]">
            <p className="text-[16px] font-raleway font-semibold text-black">Upload Community Logo</p>
            <p className="text-[16px] font-open-sans font-light text-[#4D4D4D]">Upload a logo to represent your community.</p>
            <>
            <div className="flex flex-row text-2xl text-[#000] h-[206px] border-[1px] rounded-lg border-[#E9E9E9] my-4">
              <CustomAddImageBox name="logo" height="158px" width="200px" selectedImage={selectedLogo} setSelectedImage={setSelectedLogo} handleChange={handleChange} />
              <div className="flex flex-col font-open-sans text-[16px] text-[#4D4D4D] my-auto mx-2">
                <span><CheckCircle className="text-primary-400 mx-1" /> Accepted formats: JPEG (.jpg, .jpeg), PNG (.png), SVG (.svg)</span>
                <span><CheckCircle className="text-primary-400 mx-1" /> At least 500x500 pixels</span>
                <span><CheckCircle className="text-primary-400 mx-1" /> Maximum file size: 5MB</span>
                <span><CheckCircle className="text-primary-400 mx-1" /> Transparent background preferred for versatility</span>
              </div>
            </div>
            </>
          </div>
        </div>

        <div className="flex flex-col bg-white my-3 h-[324px] w-full rounded-lg">
          <div className="flex flex-col m-[24px]">
          <p className="text-[16px] font-raleway font-semibold text-black">Upload Community Images</p>
          <p className="text-[16px] font-open-sans font-light text-[#4D4D4D]">Upload clear images that showcase your community.</p>
          <div className="flex flex-row text-2xl text-[#000] w-full h-[206px] border-[1px] rounded-lg border-[#E9E9E9] my-4">
            <CustomAddImageBox name="bgPhoto" height="158px" width="340px" selectedImage={selectedBgPhoto} setSelectedImage={setSelectedBgPhoto} handleChange={handleChange} />
          </div>
          </div>
        </div>
      </div>

      <ConfirmationModal  
        isOpen={isConfirmBoxOpen} 
        message={isUpdateSuccess ? "All changes have been saved": "Update Failed"} 
        closeModal={()=>toggleConfirmBox(false)}
      />
    </>
  )
}



const Reviews = () => {

  useEffect(() => {
    document.title = "HomeCook | Reviews";
  }, []);

  return (
    <div className="flex flex-col w-11/12 mx-auto">

    <div className="flex flex-row bg-white my-6 h-[178px] w-full rounded-md">
    </div>

    <div className="flex flex-row bg-white my-6 h-[247px] w-full rounded-md">
    </div>

    <div className="flex flex-row bg-white my-6 h-[178px] w-full rounded-md">
    </div>

    <div className="flex flex-row bg-white my-6 h-[178px] w-full rounded-md">
    </div>

    <div className="flex flex-row bg-white my-6 h-[178px] w-full rounded-md">
    </div>

    <div className="flex flex-row bg-white my-6 h-[178px] w-full rounded-md">
    </div>

  </div>
  )
}


export default CommunityPage;
