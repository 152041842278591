import React, { useState } from "react"

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import MemberRecord from "../molecules/MemberRecord"
import EditMemberRecord from "../molecules/EditMemberRecord"

import { useUpdateMembership } from '../hooks/memberhooks'
import { MembershipSchema } from "../data/types";


type MembersListProps = {
  kitchen_capacity: number
  members: MembershipSchema[],
  isRequests?: boolean
}

const MembersList: React.FC<MembersListProps> = ({kitchen_capacity, members, isRequests}) => {
  const { updateMembership } = useUpdateMembership()

  const [allselected, updateAllSelected] = useState(false)
  const [editMember, setEditMember] = useState<string>("")

  const submitUpdate = async (membershipChanges: MembershipSchema) => {
    updateMembership(membershipChanges)
    setEditMember("")
  }

  const cancelEditMember = () => {
    setEditMember("")
  }

  return (
    <table className="table-auto my-3">
      <thead className="bg-[#F8F8F8] h-[60px] border-[1px] border-[#E9E9E9]  font-open-sans">
        <tr>
          <th className="flex my-auto p-1 border-r-0 border-l-0 text-[#4D4D4D] font-raleway text-[16px] font-normal"> 
            {allselected ? (
              <CheckBoxIcon
                onClick={() => updateAllSelected(false)}
              />
            ) : (
              <CheckBoxOutlineBlankIcon
                onClick={() => updateAllSelected(true)}
              />
            )}
            &nbsp; <KeyboardArrowDownIcon className="m-auto" style={{ cursor: "pointer", fontSize: 15 }} />
          </th>

          <th className="headItem border-0 text-[#4D4D4D] font-raleway text-[16px] font-normal">
            Username &nbsp; <KeyboardArrowDownIcon style={{ cursor: "pointer", fontSize: 15 }} />
          </th>

          <th className="headItem border-0 text-[#4D4D4D] font-raleway text-[16px] font-normal">Full Name</th>

          <th className="headItem border-0 text-[#4D4D4D] font-raleway text-[16px] font-normal">Team</th>

          <th className="headItem border-0 text-[#4D4D4D] font-raleway text-[16px] font-normal">Membership Status</th>

          <th className="headItem border-0 text-[#4D4D4D] font-raleway text-[16px] font-normal">Membership Plan</th>

          <th className="headItem border-0 text-[#4D4D4D] font-raleway text-[16px] font-normal">Member Balance</th>

          <th className="headItem border-0 text-[#4D4D4D] font-raleway text-[16px] font-normal">Joined</th>

          
          <th className="headItem border-0 text-[#4D4D4D] font-raleway text-[16px] font-normal"></th>
        </tr>
      </thead>

      <tbody className="bg-white h-[60px]">
        {
          
         (members) ? members.map((member: MembershipSchema, i: number) => (
              (editMember && editMember === member.username) ? (
                <EditMemberRecord
                  key={i}
                  member={member}
                  kitchen_capacity={kitchen_capacity}
                  allSelected={allselected}
                  isRequests={isRequests}
                  submitUpdate={submitUpdate}
                  cancelEditMember={cancelEditMember}
                />
              ) : (
                <MemberRecord
                  key={i}
                  member={member}
                  allSelected={allselected}
                  isRequests={isRequests}
                  setEditMember={setEditMember}
                />
              )
          )): "No Members"
        }
      </tbody>
    </table>
  );
};

export default MembersList;
