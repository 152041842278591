import React from "react";
// import { useNavigate } from "react-router-dom";
// import moment from 'moment'
import moment from 'moment-timezone';
// import { ReactComponent as ProfileIcon } from "../icons/ProfileIcon.svg";

// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import InfoIcon from "@mui/icons-material/Info";
// import VisibilityIcon from '@mui/icons-material/Visibility';

// import CheckIcon from '@mui/icons-material/Check';
// import EditIcon from '@mui/icons-material/Edit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { ReactComponent as ActionIcon } from '../icons/ActionIcon.svg'


// import { DeleteConfirmationModal } from "../components/AppModal";
// import { useUpdateMembership } from '../hooks/memberhooks'
// import { statusColors } from "../utils/enums";
import { getBookingStatusColor } from "../utils/helpers";
import { ResourceBookingSchema } from "../data/types";


// const colors: {[key: string]: string} = statusColors();

type ResourceRequestRecordProps = {
  request: ResourceBookingSchema
  isRequests?: boolean
}

const ResourceRequestRecord: React.FC<ResourceRequestRecordProps> = (props) => {

  var { request } = props

  var dateFieldValue = ""
  switch(request.resource_type){
    case 'training':
      dateFieldValue =  moment(request.start_time).tz('America/New_York').format('ddd MMM DD, YYYY @ hh:mm a')
      break
    case 'expert':
      dateFieldValue =  moment(request.start_time).tz('America/New_York').format('ddd MMM DD, YYYY @ hh:mm a')
      break
  }

  // const circle = (status: string) => {
  //   return (
  //     <div
  //       className={`z-0 w-2 h-2 animate-pulse ${colors[status]} rounded-full`}
  //     ></div>
  //   );
  // };
  

  // const declineRequest = () => {
  //   if (updateMembership !== undefined) {
  //     updateMembership({username: request.username, status: request.status === 'declined' ? 'removed' : 'declined'})
  //  } 
  // }



  return request ? (
    <tr className="font-open-sans border-x-2 border-s-2 border border-[#E9E9E9]">



      <td className="recordItem w-3/12 border-r-0">
        {/* <ProfileIcon className='text-[#E6E6E6] h-8 ' /> */}
        {
          request.username
        }
      </td>

      <td className="recordItem w-3/12 border-r-0"> {request?.resource_name ? request?.resource_name : "-"} </td>

      {
        (dateFieldValue) && (
          <td className="recordItem w-3/12 border-r-0">{dateFieldValue}</td>
        )
      }

      <td className="recordItem w-2/12 border-r-0">
        {moment.utc(request?.created).fromNow()}
      </td>

      <td className="recordItem w-2/12 border-r-0">
        {
          <div className="flex justify-center items-center">
            {/* {circle((request.status) ? request.status : '')} */}
            <span className="ml-2 p-1 w-full" style={{color: getBookingStatusColor((request.status) ? request.status : '').primaryTextColor}}>
              <FiberManualRecordIcon style={{fontSize: 15}} />
              &nbsp; {(request.status) ? request.status.replace(/\b\w/g, (status: string) => status.toUpperCase()) : 'Pending'}
              </span>
          </div>
        }
      </td>

      <td className="recordItem w-1/12 border-r-0">

        <div className="flex flex-row">
          <ActionIcon className="text-[#4D4D4D] hover:text-primary-300 mx-auto cursor-pointer"/>
        </div>
      </td>

    </tr>
  ) : (<></>);
};

export default ResourceRequestRecord;
