import { useEffect, useState } from "react";
import { ResourceOptionType, ResourceType, SearchFilterOptionType} from "../../data/types";
// import KitchensPage from "../shared/KitchensPage";
import ResourceList from '../../molecules/ResourceList'
import FilterBox from '../../components/FilterBox'
import { capitalizeString } from "../../utils/helpers";
import { useNavigate, useParams } from "react-router-dom";
import { AllResourceRequests } from "./RequestsPage";
import { useLoadResourceBookings } from "../../hooks/memberhooks";
// import { AddResourceModal } from "../../molecules/AppModal";



type RouteParams = {
  resourceType: ResourceType
};


type ResourcesPageProps = {
  searchFilterOptions: SearchFilterOptionType
}

const ResourcesPage: React.FC<ResourcesPageProps> = ({searchFilterOptions}) => {

  const navigate = useNavigate();
  var { resourceType } = useParams<RouteParams>();
  resourceType = resourceType ? resourceType : "kitchen"

  const resourceOptions: ResourceOptionType[] = [
    {
      name: "Local Kitchen Spaces",
      type: "kitchen"
    },
    {
      name: "Local Fresh Food",
      type: "farm"
    },
    {
      name: "Required Documentation",
      type: "documentation"
    },
    {
      name: "Culinary Training",
      type: "training"
    },
    {
      name: "Funding Opportunities",
      type: "funding"
    },
    {
      name: "Industry Experts",
      type: "expert"
    }
  ]


  var initialOption =  resourceOptions.find(opt => opt.type === resourceType)
  if (initialOption === undefined){
    initialOption = resourceOptions[0]
  }

  const [currentOption, setCurrentOption] = useState<ResourceOptionType>(initialOption);


  useEffect(() => {
    document.title = "HomeCook | Resources";
    // eslint-disable-next-line
  }, []);

  return  (
    <div className="w-full bg-[#F5F5F5] font-open-sans mt-[72px] h-auto">
      <div className="flex flex-row bg-white h-[75px] w-full">
      {
          resourceOptions.map((option, i) => {
            return (
              <div 
                key={i} 
                onClick={()=>{
                  setCurrentOption(option)
                  navigate(`/resources/${option.type}`)
                  
                }} 
                className={`${(currentOption.name === option.name) ? 'text-white bg-primary-200' : 'text-[#4D4D4D] '} ml-6 my-auto text-[16px] font-raleway border-[1px] border-[#E9E9E9] py-2 px-4 rounded-md cursor-pointer`}
              >
                  {option.name}
              </div>
            )
          })
        }
      </div>
      <ResourceTypeView resourceType={currentOption.type} searchFilterOptions={searchFilterOptions} />
    </div>
  );
};


type ResourceTypeViewProps = {
  resourceType: ResourceType
  searchFilterOptions: SearchFilterOptionType
}

export const ResourceTypeView: React.FC<ResourceTypeViewProps> = ({resourceType, searchFilterOptions}) => {

  const { resourceBookings } = useLoadResourceBookings(resourceType);

  type menuOptionTypes = "overview" | "bookings"
  const menuOptions: {id: menuOptionTypes, name: string}[] = [
    {id: "overview", name: "Overview"}, 
    {id: "bookings", name: `${resourceType} Requests (${resourceBookings && resourceBookings.length > 0 ? resourceBookings.length : 0})`} 
  ]
  const [currentOption, setCurrentOption] = useState<menuOptionTypes>(menuOptions[0].id)
  const [isAddingResource, setIsAddingResource] = useState<boolean>(false)



  return (
    <>
      <div className="flex flex-row bg-white h-auto w-full my-1">
        {
          menuOptions.map((option, i)=> (
          <div 
            key={i} 
            className={`${(currentOption === option.id) ? 'text-primary-200 font-bold' : 'text-[#4D4D4D]'}  ml-6 mt-auto my-auto text-[16px] font-raleway py-2 px-4 rounded-md cursor-pointer`}
            onClick={()=> setCurrentOption(option.id)}
          >
            {capitalizeString(option.name)}
          </div>
          ))
        }
      </div>

      {
        (currentOption === 'bookings') ? (
          <>
            <AllResourceRequests resourceType={resourceType} resourceRequests={resourceBookings} />
          </>
        ) : (
          <>
            <FilterBox searchType={resourceType} searchFilterOptions={searchFilterOptions} setIsAddingResource={setIsAddingResource} />
            <div className="flex flex-col w-11/12 mx-auto my-4">
              <ResourceList resourceType={resourceType} isAddingResource={isAddingResource} searchFilterOptions={searchFilterOptions} setIsAddingResource={setIsAddingResource} />
            </div>
          </>
        )
      }
    </>
  )
}

export default ResourcesPage;
