import {ReactNode, useContext, createContext, useState} from 'react'

import { AppContextType } from "../data/types";

const AppContext = createContext<AppContextType>({} as AppContextType);

type PropsChildren = {children?: ReactNode}
const AppProvider = (props: PropsChildren) => {

    const [isCollapsed, setIsCollapsed] = useState<boolean>(false)

    const toggleIsCollapsed = () => {
        setIsCollapsed(prev=>!prev)
    }

    let data = {
        isCollapsed,
        setIsCollapsed,
        toggleIsCollapsed
    }
    return <AppContext.Provider value={data} {...props} />
}

const useAppState = () => {
    const context = useContext(AppContext)
    if (context === undefined ){
        throw new Error(`useApp must be used within AppProvider`)
    }
    return context
}

export {AppProvider, useAppState }
