import { useQuery, useMutation} from '@tanstack/react-query'
import { 
    getKitchenID, addNewResource, deleteCommunityResource,
    getAllResources, getResourceByID, updateResourceById, deleteMembershipPlan,
    addKitchen, updateKitchen, updateMembershipPlans, getMembershipPlan, 
    updateKitchenOptions, getCommunityAllInventory, addCommunityInventory,
    deleteMembershipRequirement, addMembershipRequirement, addRequirements,
    addCommunityPlans, updatePlansForResource, getMembershipPlans, getCommunityRequirements,
    addResourceInventory,getAllResourceInventory, updateRequirementsForResource,
    deleteInventoryItemById, updateResourceOptions} from '../api/crud'
import { InventorySchema, KitchenOptionsType, KitchenSpaceSchema, PlanSchema, RequirementSchema, FilterOptionSchema, ResourceSchema, ResourceType, SettingsOptionsType, ResourceOptionType, TrainingInventorySchema } from "../data/types";
import { useResourceState } from '../contexts/kitchencontext'
import { filterItemsByCategoryAndName } from '../utils/helpers';

import { ReactComponent as DocumentationIcon } from '../icons/documentationIcon.svg'
// import { ReactComponent as PreferenceIcon } from '../icons/PreferenceIcon.svg'
import { ReactComponent as FundingIcon } from '../icons/fundingIcon.svg'
import { ReactComponent as KitchenIcon } from '../icons/KitchenIcon.svg'
// import { ReactComponent as NotificationIcon } from '../icons/NotificationIcon.svg'
// import { ReactComponent as AccountIcon } from '../icons/Account.svg'
import { ReactComponent as ExpertIcon } from '../icons/expertIcon.svg'
import { ReactComponent as TrainingIcon } from '../icons/TrainingIcon.svg'
import { ReactComponent as OrderIcon } from '../icons/OrderIcon.svg'

import { ReactComponent as CommunityIcon } from '../icons/Community.svg'

import inventoryCatalog from '../data/inventoryCatalog.json';
import { useAuthState } from '../contexts/authcontext';

const staleTime = 100000
const isEnabled = true


export const useLoadCommunityResources = () => {
    var { communityId, } = useResourceState()
    communityId = communityId ? communityId : "resource_pantry"
    const {data: allResources, refetch: reloadResources,  isLoading: isLoadingResources} = useQuery({ 
        queryKey: ['resources', communityId], 
        queryFn: async () => {
            if (communityId){
                console.log("fetching resources")
                var res = await getAllResources(communityId)
                return res
            }
        },
        staleTime,
        enabled: isEnabled
    })

    useLoadPlans()
    useLoadRequirements()
    if (allResources){
        return {resources: allResources, reloadResources}
    }
    return { isLoadingResources, resources: [], reloadResources}
}



export const useLoadCurrentKitchen = (kitchenId: string) => {
    let { communityId, setKitchen } = useResourceState()
    const {data: currentKitchen, refetch: reloadCurrentKitchen, isLoading: isLoadingKitchen} = useQuery({ 
        queryKey: ['kitchen', communityId, kitchenId], 
        queryFn: async () => {
            console.log("fetching kitchen by id")
            var res = await getKitchenID({communityId: communityId ? communityId : "", id: kitchenId})
            if (res && setKitchen!== undefined){
                setKitchen(res)
            }
            return res
        },
        staleTime,
        enabled: isEnabled
    })
    var { isLoadingPlans } = useLoadPlans()
    var { isLoadingRequirements } = useLoadRequirements()
    var isLoadingCurrentKitchen = isLoadingKitchen || isLoadingPlans || isLoadingRequirements
    return { isLoadingCurrentKitchen, currentKitchen, reloadCurrentKitchen}
}


export const useLoadCurrentResource = (resourceId: string) => {
    let { communityId, setCurrentResource } = useResourceState()
    const {data: currentResource, refetch: reloadCurrentResource, isLoading: isLoadingResource} = useQuery({ 
        queryKey: ['resource', resourceId], 
        queryFn: async () => {
            console.log("fetching resource by id")
            communityId = communityId ? communityId : ""
            var res = await getResourceByID(communityId, resourceId)
            if (res && setCurrentResource!== undefined){
                setCurrentResource(res)
                
            }
            return res
        },
        staleTime,
        enabled: isEnabled
    })
    return { isLoadingResource, currentResource, reloadCurrentResource}
}


// export const useLoadResources = () => {
//     let { communityId, setResources } = useResourceState()
//     const {data: resources, refetch: reloadResources, isLoading: isLoadingResources} = useQuery({ 
//         queryKey: ["resources", communityId], 
//         queryFn: async () => {
//             if (communityId){
//                 console.log("fetching community resources")
//                 var res = await getAllResources(communityId)
//                 if (res && setResources!== undefined){
//                     setResources(res)
//                 }
//                 return res
//             }
//             return []
//         },
//         staleTime,
//         enabled: isEnabled
//     })
//     return { isLoadingResources, resources, reloadResources}
// }

export const resourcesPerPage: number = 8

export const useLoadResourceOptions = () => {
    const {data: allOptions, refetch: reloadOptions,  isLoading: isLoadingOptions} = useQuery({ 
        queryKey: ['options'], 
        queryFn: async () => {
            console.log("fetching resource options")
            const resourcesOptions: SettingsOptionsType[] = [
                {name: "Local Kitchen Spaces", description: "Discover kitchen communities and food incubators in your area.", icon: KitchenIcon, path: "kitchen", tags: []},
                {name: "Required Documentation & Compliance ", description: "Access essential legal documents and compliance guides", icon: DocumentationIcon, path: "documentation", tags: ["servsafe"]},
                {name: "Culinary Training", description: "Enhance your skills with top culinary training programs.", icon: TrainingIcon, path: "training", tags: ["servsafe"]},
                {name: "Funding & Financial Resources", description: "Discover financial options and resources for your business.", icon: FundingIcon, path: "funding", tags: []},
                {name: "Industry Mentors & Experts", description: "Discover organizations that offer mentorship and industry insights.", icon: ExpertIcon, path: "expert", tags: []},
                {name: "Local Fresh Food", description: "Source fresh, local ingredients for your culinary creations.", icon: OrderIcon, path: "farm", tags: []}
              ]
            return resourcesOptions 
        },
        staleTime,
        enabled: isEnabled
    })

    const searchOptions = (searchTerm: string) => {
        const searchedOptions =  allOptions ? allOptions.filter((option: SettingsOptionsType) => (option.name?.toLowerCase().includes(searchTerm.toLowerCase()) || option.tags?.join(" ")?.toLowerCase()?.includes(searchTerm.toLowerCase()))) : []
        // const searchedOptions = new Set(searchedResources.map((resource: ResourceSchema) => resource.resource_type));
        return {searchedOptions, isLoadingOptions, reloadOptions}
    }

    const getResourceIconData = (resourceType: ResourceType | undefined) => {
        switch(resourceType){
            case 'kitchen':
                return {name: "Local Kitchen Space", description: "Set up a kitchen resource to allow members to book and use the space.", Icon: KitchenIcon, path: "kitchen", tags: []}
            case 'documentation':
                return {name: "Local Documentations", description: "Share guides or documents to help food entrepreneurs start and grow their businesses.", Icon: DocumentationIcon, path: "documentation", tags: []}
            case 'training':
                return {name: "Culinary Training", description: "Set up a training to help others kickstart their food business journey.", Icon: TrainingIcon, path: "training", tags: []}
            case 'funding':
                return {name: "Funding Opportunities", description: "Provide details on grants, loans, and other funding options available to help food entrepreneurs grow their businesses.", Icon: FundingIcon, path: "funding", tags: []}
            case 'expert':
                return {name: "Industry Experts", description: "List specialized experts to support food entrepreneurs. Provide key details so users can easily find and request the right guidance.", Icon: ExpertIcon, path: "expert", tags: []}
            case 'farm':
                return {name: "Local Fresh Food", description: "Add and organize your fresh produce to make it available for customers to browse and buy.", Icon: OrderIcon, path: "farm", tags: []}
            default:
                return {name: "Culinary Resource", description: "Discover kitchen communities and food incubators in your area.", Icon: CommunityIcon, path: "kitchen", tags: []}
        }
    }
    return {allOptions: allOptions ? allOptions : [], isLoadingOptions, reloadOptions, searchOptions, getResourceIconData}
}

export const useLoadResourcesFilter =  (resourceType: ResourceType, filterState: string, filterActive: boolean | undefined) => {
    // const { updateResources } = useResource()
    var { communityId } = useResourceState()
    const {data: allResources, refetch: reloadResources,  isLoading: isLoadingResources} = useQuery({ 
        queryKey: ['resources'], 
        queryFn: async () => {
            console.log("fetching filtered resources")
            const res = await getAllResources(communityId ? communityId : "", resourceType)
            // console.log("res", res)
            return res ? res : []
        },
        staleTime,
        enabled: isEnabled
    })
    const filteredResources = allResources ?  allResources.filter((resource: ResourceSchema) => (resource?.state?.toLowerCase() === filterState.toLowerCase() || resource?.state === "" || filterState === "") && (filterActive === undefined || (resource?.active === filterActive))) : []


    const  filterResourcesBySearchPhrase = (
        items: ResourceSchema[],
        searchPhrase: string,
        attributes: (keyof ResourceSchema)[]
    ) => {

        const lowerSearchPhrase = searchPhrase.toLowerCase();
        return items.filter(item =>
          attributes.some(attr => {
            const value = item[attr];
            return (value && typeof value === 'string') && 
                   value.toLowerCase().includes(lowerSearchPhrase);
                //    new RegExp(lowerSearchPhrase.toLowerCase()).test(value.toLowerCase());
                //    value.indexOf(lowerSearchPhrase) !== -1;
          })
        );
    }

    const searchResources = (searchTerm: string) => {
        const searchAttributes: (keyof ResourceSchema)[] = ["name", "description", "location", "zipcode"]
        const searchedResources = filterResourcesBySearchPhrase(filteredResources, searchTerm, searchAttributes)
        return {searchedResources, isLoadingResources, reloadResources}
    }

    const getPage = (resourcesList: ResourceSchema[], pageNumber:  number) => {
        var numAllResources = resourcesList?.length ? resourcesList.length : 0
        var startIndex = 0
        var endIndex = numAllResources <= resourcesPerPage ? numAllResources : resourcesPerPage
        var numResources = numAllResources
        var numPages = Math.ceil(numAllResources / resourcesPerPage)
        // var paginatedResources = filteredResources.slice(startIndex, endIndex)

        if (resourcesList && pageNumber && pageNumber > 0 && pageNumber <= numPages){
            startIndex = (pageNumber-1) * resourcesPerPage
            endIndex = (numPages === pageNumber) ? numAllResources : (pageNumber * resourcesPerPage)
        }
        var paginatedResources = resourcesList.slice(startIndex, endIndex)
        numResources = paginatedResources?.length ? paginatedResources.length : 0
        return {paginatedResources, numAllResources, numResources, startIndex, endIndex}
    }
    return {filteredResources, isLoadingResources, getPage, reloadResources, searchResources}
}



export const useLoadPlans = () => {
    let { communityId, setPlans } = useResourceState()
    const {data: plans, refetch: reloadPlans, isLoading: isLoadingPlans} = useQuery({ 
        queryKey: ["plans", communityId], 
        queryFn: async () => {
            if (communityId){
                console.log("fetching plans")
                var res = await getMembershipPlans(communityId)
                if (res && setPlans!== undefined){
                    setPlans(res)
                }
                return res
            }
            return []
        },
        staleTime,
        enabled: isEnabled
    })
    return { isLoadingPlans, plans, reloadPlans}
}


export const useAddInventory = () => {
    var { communityId } = useResourceState()
    const reloadInventoryList  = useLoadInventoryList().reloadInventoryList
    const mutation = useMutation({ 
        mutationFn: async (inventory: InventorySchema) => {
            console.log("add community inventory")
            if (communityId){
                const res = await addCommunityInventory(communityId, inventory)
                reloadInventoryList()
                return res
            }
            return null
    }})

    const addInventoryItem = (inventory: InventorySchema) => {
        mutation.mutate(inventory)
        return mutation?.data ? true : false
    }

    const searchInventoryCatalog = (category: string, query: string): string[] => {
        const inventoryItems: FilterOptionSchema[] = inventoryCatalog as FilterOptionSchema[];
        return filterItemsByCategoryAndName(inventoryItems, category, query)
    }

    const isLoadingInventory = mutation.isPending
    return { addInventoryItem, searchInventoryCatalog, isLoadingInventory }
}


export const useLoadInventoryList = () => {
    let { communityId, setInventoryList } = useResourceState()
    const {data: inventoryList, refetch: reloadInventoryList, isLoading: isLoadingInventoryList} = useQuery({ 
        queryKey: ["inventory", communityId], 
        queryFn: async () => {
            if (communityId){
                console.log("fetching community inventory")
                var res = await getCommunityAllInventory(communityId)
                if (res && setInventoryList!== undefined){
                    setInventoryList(res)
                }
                return res
            }
            return []
        },
        staleTime,
        enabled: isEnabled
    })
    return { isLoadingInventoryList, inventoryList, reloadInventoryList}
}


export const useLoadRequirements = () => {
    const { setRequirements } = useResourceState()
    const { communityId } = useAuthState()
    console.log("communityId", communityId)
    const {data: requirements, refetch: reloadRequirements, isLoading: isLoadingRequirements} = useQuery({ 
        queryKey: ['requirements', communityId], 
        queryFn: async () => {
            if (communityId){
                console.log("fetching requirements")
                var res = await getCommunityRequirements(communityId)
                if (res && setRequirements!== undefined){
                    setRequirements(res)
                }
                console.log("requirements res", res)
                return res                
            }
            return []
        },
        staleTime,
        enabled: isEnabled
    })

    // add requirement
    const addRequirementsMutation = useMutation({ 
        mutationFn: async (requirements: RequirementSchema[]) => {
            console.log("add requirement")
            const res = await addRequirements(communityId ? communityId : "", requirements)
            if (res){
                reloadRequirements()
            }
            return res
    }})
    
    const addCommunityRequirements = (requirements: RequirementSchema[]) => {
        addRequirementsMutation.mutate(requirements)
        return addRequirementsMutation?.data ? true : false
    }


    // add custom requirement
    const addCustomRequirementMutation = useMutation({ 
        mutationFn: async (requirement: RequirementSchema) => {
            console.log("add requirement")
            const res = await addMembershipRequirement(communityId ? communityId : "", requirement)
            if (res){
                reloadRequirements()
            }
            return res
    }})
    

    const addCustomRequirement = (requirement: RequirementSchema) => {
        addCustomRequirementMutation.mutate(requirement)
        return addCustomRequirementMutation?.data ? true : false
    }


        // delete requirement
        const deleteRequirementMutation = useMutation({ 
            mutationFn: async (reqId: string) => {
                console.log("delete requirement")
                const res = await deleteMembershipRequirement(communityId ? communityId : "", reqId)
                reloadRequirements()
                return res
        }})
        const deleteResourceRequirement = (reqId: string) => {
            deleteRequirementMutation.mutate(reqId)
            return deleteRequirementMutation?.data ? true : false
        }


    const isLoading = (
        addRequirementsMutation.isPending ||
        addCustomRequirementMutation.isPending ||
        deleteRequirementMutation.isPending
    );

    const isSuccess = (
        addRequirementsMutation.isSuccess ||
        addCustomRequirementMutation.isSuccess ||
        deleteRequirementMutation.isSuccess
    );

    const isError = (
        addRequirementsMutation.isError ||
        addCustomRequirementMutation.isError ||
        deleteRequirementMutation.isError
    );

        
    return { isLoadingRequirements, requirements, isLoading,isSuccess, isError, addCommunityRequirements, addCustomRequirement, deleteResourceRequirement, reloadRequirements}
}


export const useKitchenHook = () => {
    var { communityId, plans, setKitchen, setPlans } = useResourceState()
    const { reloadPlans } = useLoadPlans()
    const { reloadRequirements } = useLoadRequirements()
    const { reloadResources } = useLoadCommunityResources()
    
    // add kitchen space
    const addKitchenMutation = useMutation({ 
        mutationFn: (kitchen: KitchenSpaceSchema | {}) => {
            console.log("add kitchen")
            const res = addKitchen(communityId ? communityId : "", kitchen)
            reloadResources()
            return res
    }})
    
    const addKitchenSpace = (kitchen: KitchenSpaceSchema | {}) => {
        addKitchenMutation.mutate(kitchen)
        return addKitchenMutation?.data ? true : false
    }

    // update kitchen space
    const updateKitchenMutation = useMutation({ 
        mutationFn: async ({spaceId, kitchenUpdates}: {spaceId: string, kitchenUpdates: KitchenSpaceSchema | ResourceSchema}) => {
            console.log("update kitchen space")
            const res = await updateKitchen({communityId: communityId ? communityId : "", spaceId, kitchenUpdates})
            if (res && setKitchen !== undefined){
                setKitchen(res)
            }
            return res
    }})
    const updateKitchenSpace = (spaceId: string, kitchenUpdates: KitchenSpaceSchema | ResourceSchema) => {
        updateKitchenMutation.mutate({spaceId, kitchenUpdates})
        reloadResources()
        return updateKitchenMutation?.data ? true : false
    }

    // add kitchen plan
    const addPlanMutation = useMutation({ 
        mutationFn: async (plan: PlanSchema | {}) => {
            console.log("add plan")
            const res = await addCommunityPlans(communityId ? communityId : "", plan)
            if (res && setPlans !== undefined){
                let updated_plans: any = (plan && plans) ? [...plans] : []
                updated_plans.push(res)
                setPlans(updated_plans)
            }
            return res
    }})
    const addKitchenPlan = (plan: PlanSchema | {}) => {
        addPlanMutation.mutate(plan)
        return addPlanMutation?.data ? true : false
    }

    // update kitchen plan
    const updatePlanMutation = useMutation({ 
        mutationFn: async ({planId, planUpdate}: {planId: string, planUpdate: PlanSchema}) => {
            console.log("update plan")
            const res = await updateMembershipPlans(communityId ? communityId : "", planUpdate, planId)
            reloadPlans()
            return res
    }})

    const updateKitchenPlan = (planId: string, planUpdate: PlanSchema | {}) => {
        updatePlanMutation.mutate({planId, planUpdate})
        return updatePlanMutation?.data ? true : false
    }

    // add requirement
    const addRequirementMutation = useMutation({ 
        mutationFn: async (requirement: RequirementSchema | {}) => {
            console.log("add requirement")
            const res = await addMembershipRequirement(communityId ? communityId : "", requirement)
            if (res){
                // let updated_reqs: RequirementSchema[] = (requirement && requirements) ? [...requirements] : []
                // updated_reqs.push(res)
                // setRequirements(updated_reqs)
                reloadRequirements()
            }
            return res
    }})
    const addKitchenRequirement = (requirement: RequirementSchema | {}) => {
        addRequirementMutation.mutate(requirement)
        return addRequirementMutation?.data ? true : false
    }

    // delete requirement
    const deleteRequirementMutation = useMutation({ 
        mutationFn: async (req_id: string) => {
            console.log("delete requirement")
            const res = await deleteMembershipRequirement(communityId ? communityId : "", req_id)
            reloadRequirements()
            return res
    }})
    const deleteKitchenRequirement = (req_id: string) => {
        deleteRequirementMutation.mutate(req_id)
        return deleteRequirementMutation?.data ? true : false
    }

    // update kitchen options
    const updateKitchenOptionsMutation = useMutation({ 
        mutationFn: async ({spaceId, optionsUpdates}: {spaceId: string, optionsUpdates: KitchenOptionsType | ResourceOptionType}) => {
            console.log("update kitchen space")
            const res = await updateKitchenOptions(communityId ? communityId : "", spaceId, optionsUpdates)
            if (res && setKitchen !== undefined){
                setKitchen(res)
            }
            return res
    }})

    const updateOptions = (spaceId: string, optionsUpdates: KitchenOptionsType | ResourceOptionType) => {
        updateKitchenOptionsMutation.mutate({spaceId, optionsUpdates})
        return updateKitchenMutation?.data ? true : false
    }

    const isLoading = (
        addKitchenMutation.isPending ||
        updateKitchenMutation.isPending ||
        addPlanMutation.isPending || 
        updatePlanMutation.isPending || 
        addRequirementMutation.isPending || 
        deleteRequirementMutation.isPending ||
        updateKitchenOptionsMutation.isPending
        // || isLoadingCommunity
    );

    return { isLoading, addKitchenSpace, addKitchenPlan, addKitchenRequirement, updateKitchenSpace, updateOptions, updateKitchenPlan, deleteKitchenRequirement }
}



export const useLoadResourceInventoryItems = (resourceId: string) => {
    let { communityId, setInventoryList } = useResourceState()
    const {data: inventoryList, refetch: reloadInventoryList, isLoading: isLoadingInventoryList} = useQuery({ 
        queryKey: ["inventory", communityId, resourceId],
        queryFn: async () => {
            if (communityId){
                console.log("fetching resource inventory items")
                var res = await getAllResourceInventory(communityId, resourceId)
                if (res && setInventoryList!== undefined){
                    setInventoryList(res)
                }
                return res
            }
            return []
        },
        staleTime,
        enabled: isEnabled
    })
    return { isLoadingInventoryList, inventoryList, reloadInventoryList}
}

type hookPropsType = {
    resourceType?: ResourceType, 
    filterState?: string, 
    filterActive?: boolean | undefined, 
    resourceId?: string
}

export const useResourceHook = (hookProps: hookPropsType) => {
    const {resourceType, filterState, filterActive, resourceId} = hookProps
    var { communityId, setCurrentResource } = useResourceState()
    const { reloadCurrentResource } = useLoadCurrentResource(resourceId ? resourceId : '')
    const { reloadPlans } = useLoadPlans()

    const { reloadResources } = useLoadResourcesFilter(
                                    resourceType ? resourceType : "" as ResourceType, 
                                    filterState ? filterState : "", 
                                    filterActive ? filterActive : undefined
                                )
    
    // add resource
    const addResourceMutation = useMutation({ 
        mutationFn: async (resource: ResourceSchema | {}) => {
            console.log("add resource")
            const res = await addNewResource(communityId ? communityId : "", resource)
            await reloadResources()
            return res
    }})
    
    const addResource = (resource: ResourceSchema | {}) => {
        addResourceMutation.mutate(resource)
        return addResourceMutation?.data ? true : false
    }

    // update resource
    const updateResourceMutation = useMutation({ 
        mutationFn: async ({resourceId, resourceUpdates}: {resourceId: string, resourceUpdates: ResourceSchema | KitchenSpaceSchema}) => {
            console.log("update resource")
            const res = await updateResourceById({communityId: communityId ? communityId : "", resourceId, resourceUpdates})
            if (res && setCurrentResource !== undefined){
                await reloadCurrentResource()
            }
            return res
    }})
    const updateResource = async (resourceId: string, resourceUpdates: ResourceSchema | KitchenSpaceSchema) => {
        updateResourceMutation.mutate({resourceId, resourceUpdates})
        return updateResourceMutation?.data ? true : false
    }

    // delete resource
    const deleteResourceMutation = useMutation({ 
        mutationFn: async () => {
            console.log("delete resource")
            const res = await deleteCommunityResource(communityId ? communityId : "", resourceId ? resourceId : "")
            await reloadResources()
            return res
    }})
    
    const deleteResource = () => {
        deleteResourceMutation.mutate()
        return deleteResourceMutation?.data ? true : false
    }

    // add community plan
    const addPlanMutation = useMutation({ 
        mutationFn: async (plan: PlanSchema | {}) => {
            console.log("add plan")
            const res = await addCommunityPlans(communityId ? communityId : "", plan)
            await reloadPlans()
            // if (res && setPlans !== undefined){
            //     let updated_plans: any = (plan && plans) ? [...plans] : []
            //     updated_plans.push(res)
            //     setPlans(updated_plans)
            // }
            return res
    }})
    const addCommunityPlan = (plan: PlanSchema | {}) => {
        addPlanMutation.mutate(plan)
        return addPlanMutation?.data ? true : false
    }

    // update resource plans
    const updateResourcePlansMutation = useMutation({ 
        mutationFn: async (plans: string[]) => {
            communityId = communityId ? communityId : ""
            const res = await updatePlansForResource(communityId, resourceId ? resourceId : "", plans)
            // await reloadPlans()
            return res
    }})

    const updateResourcePlans = (plans: string[]) => {
        updateResourcePlansMutation.mutate(plans)
        return updateResourcePlansMutation?.data ? true : false
    }

    // update resource plan
    const updatePlanMutation = useMutation({ 
        mutationFn: async ({planId, planUpdate}: {planId: string, planUpdate: PlanSchema}) => {
            console.log("update plan")
            const res = await updateMembershipPlans(communityId ? communityId : "", planUpdate, planId)
            reloadPlans()
            return res
    }})


    const updateResourcePlan = (planId: string, planUpdate: PlanSchema | {}) => {
        updatePlanMutation.mutate({planId, planUpdate})
        return updatePlanMutation?.data ? true : false
    }

    // delete plan
    const deletePlanMutation = useMutation({ 
        mutationFn: async (planId: string) => {
            console.log("delete requirement")
            const res = await deleteMembershipPlan(communityId ? communityId : "", planId)
            reloadPlans()
            return res
    }})

    const deletePlan = (planId: string) => {
        deletePlanMutation.mutate(planId)
        return deletePlanMutation?.data ? true : false
    }
    
    // update resource requirements
    const updateResourceRequirementsMutation = useMutation({ 
        mutationFn: async (requirements: string[]) => {
            communityId = communityId ? communityId : ""
            const res = await updateRequirementsForResource(communityId, resourceId ? resourceId : "", requirements)
            return res
    }})
    const updateResourceRequirements = (requirements: string[]) => {
        updateResourceRequirementsMutation.mutate(requirements)
        return updateResourceRequirementsMutation?.data ? true : false
    }


    // update resource options
    const updateResourceOptionsMutation = useMutation({ 
        mutationFn: async ({resourceId, optionsUpdates}: {resourceId: string, optionsUpdates: ResourceOptionType}) => {
            const res = await updateResourceOptions(communityId ? communityId : "", resourceId, optionsUpdates)
            await reloadCurrentResource()
            return res
    }})

    const updateOptions = (resourceId: string, optionsUpdates: ResourceOptionType) => {
        updateResourceOptionsMutation.mutate({resourceId, optionsUpdates})
        return updateResourceMutation?.data ? true : false
    }

    
    const addInventoryItemMutation = useMutation({ 
        mutationFn: async ({resourceId, inventoryItem}: {resourceId: string, inventoryItem: InventorySchema | TrainingInventorySchema}) => {
            console.log("add resource inventory item")
            // if (communityId){
            communityId = communityId ? communityId : ""
            const res = await addResourceInventory(communityId, resourceId, inventoryItem)
            reloadCurrentResource()
            return res
            // }
    }})

    const addInventoryItem = (resourceId: string, inventoryItem: InventorySchema | TrainingInventorySchema) => {
        addInventoryItemMutation.mutate({resourceId, inventoryItem})
        return addInventoryItemMutation?.data ? true : false
    }


    // delete inventory item
    const deleteInventoryItemMutation = useMutation({ 
        mutationFn: async ({resourceId, itemId}: {resourceId: string, itemId: string}) => {
            console.log("delete inventory item")
            const res = await deleteInventoryItemById(communityId ? communityId : "", resourceId, itemId)
            reloadResources()
            return res
    }})

    const deleteInventoryItem = (resourceId: string, itemId: string) => {
        deleteInventoryItemMutation.mutate({resourceId, itemId})
        reloadCurrentResource()
        return deleteInventoryItemMutation?.data ? true : false
    }

    const isLoading = (
        addResourceMutation.isPending ||
        updateResourceMutation.isPending ||
        addPlanMutation.isPending || 
        updateResourcePlansMutation.isPending ||
        updatePlanMutation.isPending || 
        updateResourceOptionsMutation.isPending ||
        deleteResourceMutation.isPending
        // || isLoadingCommunity
    );

    const isSuccess = (
        addResourceMutation.isSuccess ||
        updateResourceMutation.isSuccess ||
        addPlanMutation.isSuccess || 
        updateResourcePlansMutation.isSuccess ||
        updatePlanMutation.isSuccess || 
        // deleteRequirementMutation.isSuccess ||
        updateResourceOptionsMutation.isSuccess ||
        deleteResourceMutation.isSuccess
    )

    const isError = (
        addResourceMutation.isError ||
        updateResourceMutation.isError ||
        addPlanMutation.isError || 
        updatePlanMutation.isError || 
        // deleteRequirementMutation.isError ||
        updateResourceOptionsMutation.isError ||
        deleteResourceMutation.isError
    )

    return { 
        isLoading, isError, isSuccess, 
        addResource, addCommunityPlan, updateResourcePlans, deletePlan, 
        addInventoryItem, updateResourceRequirements,
        updateResource, updateOptions, updateResourcePlan, 
        deleteResource, deleteInventoryItem 
    }
}

export const useGetKitchenPlan = (planId: string) => {
    let { communityId } = useResourceState()
    const {data: plans, refetch: reloadPlans, isLoading: isLoadingPlan} = useQuery({ 
        queryKey: ["plan", communityId, planId], 
        queryFn: async () => {
            if (communityId){
                console.log("fetching plan by id")
                var res = await getMembershipPlan(communityId, planId)
                return res
            }
            return []
        },
        staleTime,
        enabled: isEnabled
    })
    return {isLoadingPlan, plans, reloadPlans}
}