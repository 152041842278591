import React, { useEffect, useState } from "react";
import moment from 'moment'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LoadingScreen from "../pages/shared/LoadingScreen"
import { useKitchenHook } from "../hooks/kitchenhooks";
import { ResourceOptionType, selectedDaysType, WeekdayOptionFields } from "../data/types";
import {  capitalizeString, isSameOrLess, roundToNearest30Minutes } from "../utils/helpers";




type OperatingHoursListProps = {
resourceOptions: ResourceOptionType;
setResourceOptions: React.Dispatch<React.SetStateAction<ResourceOptionType>>
selectedDays: selectedDaysType
setSelectedDays: React.Dispatch<React.SetStateAction<selectedDaysType>>
};


const OperatingHoursList: React.FC<OperatingHoursListProps> = ({ resourceOptions: currentResourceOptions, setResourceOptions: setCurrentResourceOptions, selectedDays, setSelectedDays }) => {
  const daysOfWeek: Set<WeekdayOptionFields> = new Set([ "sun", "mon","tue",  "wed","thu",  "fri", "sat"] as WeekdayOptionFields[])
  // const [currentResourceOptions, setCurrentResourceOptions] = useState<ResourceOptionType>({} as ResourceOptionType);
  // const setCurrentResourceOptions = (options: ResourceOptionType) => {
  //   setResourceOptions(options)
  // }
  // console.log("selectedDays", selectedDays)

  const handleSelectDay = (dayName: WeekdayOptionFields, value: boolean) => {
    setSelectedDays(prevDays => {
        return {
            ...prevDays,
            [dayName]: value
        }
    })
  }

  
  const isDaySelected = (day: WeekdayOptionFields) => {
    return (selectedDays && selectedDays[day]) ? true : false
  }


  const getFieldTime = (name: 'open_time' | 'close_time', day: WeekdayOptionFields) => {
    if (currentResourceOptions){
      let dayTimes = currentResourceOptions[day]
      if (dayTimes){
        let dayTimesName = dayTimes[name]
        let newTime = moment(dayTimesName, 'HH:mm A').format('HH:mm');
        // console.log("getFieldTime newTime", newTime)
        return newTime
      }
    }
    return ""
  }



  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>, day: WeekdayOptionFields) => {
      event.preventDefault()
      const timeFieldName = event.target.name;
      const inputTime = event.target.value;
      if (timeFieldName && inputTime){
        var formattedTime = moment(roundToNearest30Minutes(inputTime), 'HH:mm').format('hh:mmA');
    
        setCurrentResourceOptions(prev => {
          let prevHours = prev[day]
          formattedTime = timeFieldName === "open_time" ? formattedTime : (prevHours?.open_time && formattedTime && isSameOrLess(prevHours?.open_time, formattedTime) ? formattedTime : prevHours?.open_time) as string
          return {
            ...prev,
            [day]: {
              ...prevHours,
              [timeFieldName]: formattedTime,
            }
          }
        })
      }
  };


  // Memoized onChange handler function
  // const handleChange = useMemo(() => {
  //   return (event: React.ChangeEvent<HTMLInputElement>) => {
  //       const dayName = event.target.value as WeekdayOptionFields
  //       isDaySelected(dayName);
  //   };
  // }, []); // No dependencies, so the function is memoized only once


  useEffect(()=> {
    // setCurrentResourceOptions(currentResourceOptions as ResourceOptionType)
  }, [currentResourceOptions])

  const { isLoading } = useKitchenHook()
  if (isLoading){
    return (
      <LoadingScreen />
    )
  }


  return (
 
    <div className="flex flex-col">
        <hr className='h-[1px] text-[#E9E9E9] mt-4'/>
        <div className="flex flex-col text-[#555] font-open-sans rounded-md p-2 w-full h-max">
            {
                Array.from(daysOfWeek).map((day: WeekdayOptionFields, i: number) => {
                    return (
                    <OperatingDayHours 
                        key={i} 
                        day={day} 
                        daysOfWeek={daysOfWeek}
                        handleSelectDay={handleSelectDay} 
                        isDaySelected={isDaySelected}
                        // setSelectedDays={setSelectedDays}
                        getFieldTime={getFieldTime}
                        handleTimeChange={handleTimeChange}
                        setCurrentResourceOptions={setCurrentResourceOptions}
                    />
                    )
                })
            }
        </div>
    </div>
    
  );
};


type OperatingDayHoursProps = {
  day: WeekdayOptionFields
  daysOfWeek: Set<WeekdayOptionFields>
  handleSelectDay: (day: WeekdayOptionFields, value: boolean) => void
  isDaySelected: (day: WeekdayOptionFields) => boolean, 
  // setSelectedDays: React.Dispatch<React.SetStateAction<selectedDaysType>>
  getFieldTime: (name: 'open_time' | 'close_time', day: WeekdayOptionFields) => string, 
  handleTimeChange: (event: React.ChangeEvent<HTMLInputElement>, day: WeekdayOptionFields) => void
  setCurrentResourceOptions: React.Dispatch<React.SetStateAction<ResourceOptionType>>
}


  // Options for the dropdown
  const options: DropdownOption[] = [
    {
      label: "Opened",
      value: true,
      textColor: "text-[#0F973D]",
      bgColor: "bg-[#E7F5EC]",
    },
    {
      label: "Closed",
      value: false,
      textColor: "text-[#D42620]",
      bgColor: "bg-[#FBE9E9]",
    }
  ];


const OperatingDayHours: React.FC<OperatingDayHoursProps> = ({day, handleSelectDay, isDaySelected, getFieldTime, handleTimeChange, setCurrentResourceOptions}) => {

 const openTime = getFieldTime('open_time', day)
 const closeTime = getFieldTime('close_time', day)

 const [currentSelectedOption, setCurrentSelectedOption] = useState<DropdownOption>(options[0]);


 const handleSelectOption = (option: DropdownOption) => {
    handleSelectDay(day, !isDaySelected(day))
    setCurrentSelectedOption(option)
    if (option.label === "Closed"){
      setCurrentResourceOptions(prev => {
        delete prev[day]
        return {...prev}
      })
    }
  }

  useEffect(()=> {
    // const getSelectedOption = (d: any) => ((openTime && closeTime) || isDaySelected(d)) ? options[0] :  options[1]
    const getSelectedOption = (d: any) => (isDaySelected(d)) ? options[0] :  options[1]
    let selectedOption = getSelectedOption(day)
    setCurrentSelectedOption(selectedOption)
    }, [day, openTime, closeTime, isDaySelected]
  )
  
  return (
    <div className="flex flex-row text-md font-bold my-2">
            <span className="flex flex-row text-[16px] text-black font-raleway font-medium m-auto w-1/12">
              {capitalizeString(day)}
            </span>

            <span className="flex flex-row text-left font-normal mx-auto w-8/12">
              <input 
                onChange={e => handleTimeChange(e, day)} 
                className="formInput h-[51px] mx-2 my-auto bg-inherit w-2/5 border-[1px] border-[#E9E9E9] text-[16px] font-open-sans" 
                type="time" 
                name="open_time"
                value={openTime ? openTime : ''}
                step="1800"
              />
              <span className="my-auto">&nbsp; - &nbsp; </span>
              <input 
                onChange={e => handleTimeChange(e, day)} 
                className="formInput h-[51px] mx-2 my-auto bg-inherit w-2/5 border-[1px] border-[#E9E9E9] text-[16px] font-open-sans" 
                type="time"
                name="close_time"
                value={closeTime ? closeTime : ''}
                min={openTime}
                step="1800"
              />
            </span>
        
            <span className="flex flex-row text-left font-normal m-auto w-3/12">
              <Dropdown selectedOption={currentSelectedOption} handleSelectOption={handleSelectOption} />
            </span>

            
          </div>
  )
}


interface DropdownOption {
  label: 'Closed' | 'Opened';
  value: boolean;
  textColor: string;
  bgColor: string;
}

type DropdownProps = {
  selectedOption: DropdownOption
  handleSelectOption: (option: DropdownOption) => void
}

const Dropdown: React.FC<DropdownProps> = ({selectedOption, handleSelectOption}) => {
  const [showDropdown, toggleShowDropdown] = useState<boolean>(false)
  
  // Handle selection
  const handleSelectDropdown = (option: DropdownOption) => {
    if (option !== selectedOption){
      handleSelectOption(option);
      toggleShowDropdown(false)
    }
  };

  return (
    <div className="relative">
      {/* Dropdown Button */}
      <button
        onClick={()=>toggleShowDropdown(prev=>!prev)}
        className={`flex font-open-sans w-[110px] h-[51px] font-medium text-[16px] py-2 px-4 rounded ${
          selectedOption
            ? `${selectedOption.textColor} ${selectedOption.bgColor}`
            : "text-gray-700 bg-gray-200"
        }`}
      >
        <span className="text-left my-auto cursor-pointer">{selectedOption ? selectedOption.label : "Closed"} </span>
        <KeyboardArrowDownIcon className="ml-auto my-auto cursor-pointer" style={{ fontSize: 15 }} />
      </button>

      {/* Dropdown Menu */}
      {(showDropdown) && (
      <ul 
        className="absolute w-[110px] h-[51px] left-0 right-0 border rounded bg-white shadow z-50"
        onMouseLeave={() => toggleShowDropdown(false)}
      >
        {options.map((option, i) => (
          <li
            key={i}
            className={`font-open-sans text-left text-[16px] font-medium cursor-pointer px-4 py-2 hover:bg-gray-100 ${option.textColor} ${option.bgColor} `}
            onClick={() => handleSelectDropdown(option)}
          >
            {option.label}
          </li>
        ))}
      </ul>)}
    </div>
  );
};



export default OperatingHoursList;
