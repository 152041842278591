import React, { useEffect, useState } from 'react'
// import { capitalizeString } from '../utils/helpers'
import { AiOutlineSearch } from 'react-icons/ai'
// import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';


type NewSearchProps = {
  searchType: string
  searchTerm: string
  // {search:string, filter: string}
  filterSetting?: boolean
  toggleFilterSetting?: () => void
  changeSearchTerm: React.Dispatch<React.SetStateAction<string>>
  // React.Dispatch<React.SetStateAction<{search: string, filter: string}>>
}

const NewSearch: React.FC<NewSearchProps> = ({searchType, searchTerm, changeSearchTerm}) => {

  // var { allCommunities, isLoadingCommunities } = useSearchCommunities(searchTerm)
  // console.log("allCommunities", allCommunities)
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width < 1024;
  

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    var { name, value } = e.target
    if ((value === "" || value) && name === searchType){
      changeSearchTerm(value)
    }
  }

  let searchFor = searchType
  if (searchType === "kitchens"){
    searchFor = "kitchens"
  } else if (searchType === "reservations"){
    searchFor = "reservations"
  } else if (searchType === "resources"){
    searchFor = "culinary resources"
  }else if (searchType === "messages"){
    searchFor = "messages"
  }



  return (
    <div className={`flex flex-row py-4 pl-4 items-center    ${isMobile? "w-full":"w-[782px]"} `}>
      <div className='flex flex-row w-full flex-start h-auto md:max-h-10'>
        <div className='bg-primaryLight-1000 rounded-xl flex items-center px-2 w-full ml-auto shadow-none  md:shadow-sm border-[1px] border-[#E9E9E9]'>
            <AiOutlineSearch size={isMobile? 30: 20} className='text-[#808080]'/>
            <input className='bg-transparent text-[14px] font-open-sans text-[#808080] outline-grayDark-100 text-text-300 focus:outline-none w-full mx-auto border-0' type='text' name={searchType} placeholder={`Search ${searchFor}`} value={searchTerm} onChange={e => handleOnChange(e)} />
            {/* {isLoadingCommunities ? "...searching" : ""} */}

            {/* <FilterAltOutlinedIcon className='text-white/70 rounded-full bg-gray-400 m-auto' /> */}
        </div>
        {/* <div 
          className={`${searchType === "resources" ? 'invisible': ''} ${(filterSetting) ? 'hover:bg-white/70 bg-primaryDark-400 text-primaryDark-200 hover:text-gray-500/70 border-primaryDark-200/70' : 'bg-white hover:bg-primaryDark-400 hover:text-primaryDark-200 text-gray-500/70 border-gray-400/70'} cursor-pointer rounded-full  m-3 p-1 mr-auto  border-0 md:border-[1px]`}
        >
          <FilterAltOutlinedIcon className='m-auto' onClick={toggleFilterSetting} />
        </div> */}
      </div>
    </div>
  )
}

export default NewSearch