import React from 'react'
import { ReactComponent as AddImageIcon } from '../icons/AddImageIcon.svg'
import { capitalizeString } from '../utils/helpers'


type AddImageBoxProps = {
    name: string
    title: string
    description: string
    selectedImage: string // fix with real schema
    setSelectedImage: React.Dispatch<React.SetStateAction<string>>
    handleChange: (event: React.ChangeEvent<HTMLInputElement>)=> void
}

export const AddImageBox: React.FC<AddImageBoxProps> = ({name, title, description, selectedImage, setSelectedImage, handleChange}) =>  {

  const handleImageChange = ( event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target
    const file = files?.[0];
    if (file) {
      const reader = new FileReader(); // Read the selected image file as a data URL
      reader.onload = (e) => {
        setSelectedImage(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    } else {
        setSelectedImage("");
    }
    
    handleChange(event);
  }

  return (
  <>
    {(title) && (<p className='font-raleway text-black text-[16px] font-medium'>{capitalizeString(title)}</p>)}
    {
      (description) && (
      <div 
        className='text-[#4D4D4D] text-[16px] w-full rounded-md my-3'
      >
        {description}
      </div>
      )
    }
    <label
      htmlFor={`image-input-${name}`}
      className="my-auto"
    >
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        className="hidden"
        id={`image-input-${name}`}
        name={name}
      />
      
        {
          (selectedImage) ? (
            <img
              id={`image-input-${name}`}
              src={selectedImage}
              alt="Custom"
              className="object-cover mb-1 rounded-md w-[177px] h-[158px]"
            />
          ) : (
            <div className={`flex flex-col w-[177px] h-[158px] rounded-md bg-[#FAFAFA] border-[1px] border-dashed border-[#CFCFCF]`}>
              <p className="text-[16px] text-[#1A1A1A] font-raleway m-auto">
                <AddImageIcon className="text-primary-400 m-auto" />
                Tap to Browse
              </p>
            </div>
          )
        }
    </label>
  </>
  )
}


type CustomAddImageBoxProps = {
  name: string
  height: string
  width: string
  selectedImage: string | undefined
  setSelectedImage: React.Dispatch<React.SetStateAction<string | undefined>>
  handleChange: (event: React.ChangeEvent<HTMLInputElement>)=> void
}

export const CustomAddImageBox: React.FC<CustomAddImageBoxProps> = ({name, height, width, selectedImage, setSelectedImage, handleChange}) =>  {

  const handleImageChange = ( event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target
    const file = files?.[0];
    // console.log("AddImageBox name, value, file", name, value, file)
    if (file) {
      // Read the selected image file as a data URL
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    } else {
        setSelectedImage("");
    }
    handleChange(event);
  };

  return (name === "logo") ? (
    <label
      htmlFor={`image-input-${name}`}
      className="my-auto"
    >
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        className="hidden"
        id={`image-input-${name}`}
        name={name}
      />
      <div className="flex my-auto mx-4 w-[200px] h-[158px] bg-[#FAFAFA] border-[1px] border-[#CFCFCF] rounded-lg">
        {
          (selectedImage) ? (
            <img
              id={`image-input-${name}`}
              src={selectedImage}
              alt="Logo"
              className="relative m-auto h-[72px]"
            />
          ) : (
            <div className="flex flex-col rounded-md m-auto ">
              <p className="text-[16px] text-[#1A1A1A] font-raleway m-auto">
                <AddImageIcon className="text-primary-400 m-auto" />
                Tap to Browse
              </p>
          </div>
          )
        }
      </div>
    </label>
  ) : (
    <label
      htmlFor={`image-input-${name}`}
      className="my-auto mx-2"
    >
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        className="hidden"
        id={`image-input-${name}`}
        name={name}
      />
      {
        (selectedImage) ? (
          <img
            id={`image-input-${name}`}
            className={`object-cover w-[${width}] h-[${height}] mb-1 rounded-md mx-2`}
            alt="addphoto"
            src={selectedImage}
          />
        ) : (
          <div className={`flex flex-col w-[${width}] h-[${height}] rounded-md my-auto mx-2 bg-[#FAFAFA] border-[1px] border-dashed border-[#CFCFCF]`}>
            <p className="text-[16px] text-[#1A1A1A] font-raleway m-auto">
              <AddImageIcon className="text-primary-400 m-auto" />
              Tap to Browse
            </p>
          </div>
        )
      }
    </label>
  )
}