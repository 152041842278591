import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from 'moment'

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CheckBoxIcon from "@mui/icons-material/CheckBox";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
// import VisibilityIcon from '@mui/icons-material/Visibility';

// import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

// import { DeleteConfirmationModal } from "../components/AppModal";
import { useUpdateMembership } from '../hooks/memberhooks'
import { statusColors } from "../utils/enums";
import { styleThousandCommas, getMemberStatusColor } from "../utils/helpers";
import { MemberDetailsModal } from "./AppModal";
import { MembershipSchema } from "../data/types";
// import { MembershipSchema } from "../data/types";


const colors: {[key: string]: string} = statusColors();
// const account_avatar = `https://upload.wikimedia.org/wikipedia/commons/1/1e/Default-avatar.jpg`

const circle = (status: string) => {
  return (
    <div
      className={`z-0 w-2 h-2 animate-pulse ${colors[status]} rounded-full`}
    ></div>
  );
};

type MemberRecordProps = {
  member: any
  allSelected: boolean
  isRequests?: boolean
  setEditMember: React.Dispatch<React.SetStateAction<string>>
}

const MemberRecord: React.FC<MemberRecordProps> = (props) => {
  var navigate = useNavigate();

  // const [is_open, openCheck] = useState(false);
  // const [isClicked, setClick] = useState(false);
  const [viewMemberDetails, toggleViewMemberDetails] = useState(false);
  const [selected, updateSelect] = useState(false);

  var { member, isRequests, setEditMember } = props
  const [dropdownIsOpen, toggleDropdownIsOpen] = useState(false);
  // const [newMember, setMember] = useState<DSCommunitySchema>(member);
  const { updateMembership } = useUpdateMembership()

  const updateOpenView = () => {
    navigate(`/kitchens/members/${member.id}`) 
    // openCheck(true);
  }

  // const updateCloseView = () => {
  //   openCheck(false);
  // };

  // const approveRequest = () => {
  //  if (updateMembership !== undefined) {
  //     updateMembership({username: member.username, status: 'approved'})
  //  } 
  // }

    // const submitUpdate = (membershipChanges: MembershipSchema) => {
    //   updateMembership(membershipChanges)
    // }

    const declineRequest = () => {
      if (updateMembership !== undefined) {
        updateMembership({username: member.username, status: member.status === 'declined' ? 'removed' : 'declined'})
     } 
    }

    const handleUpdateMember = (member: MembershipSchema) => {

    }



  return member ? (
    <>
      <tr className="font-open-sans border-x-2 border-s-2 border border-[#E9E9E9]">
        <td className="pl-5s w-auto p-1">
          {/* Uses the allselected prop from the kitchen list to set the check box*/}
          {props.allSelected ? (
            <CheckBoxIcon
              style={{ cursor: "pointer" }}
              onClick={() => updateSelect(false)}
            />
          ) : selected ? (
            <CheckBoxIcon
              style={{ cursor: "pointer" }}
              onClick={() => updateSelect(false)}
            />
          ) : (
            <CheckBoxOutlineBlankIcon
              style={{ cursor: "pointer" }}
              onClick={() => updateSelect(true)}
            />
          )}
        </td>

        {/* <td className="w-48 pr-5 text-center border-r-0 border-stone-500"> 
          {member.id}
        </td> */}

        <td className="recordItem w-auto border-r-0">
          {/* This provides a test if the edit button has been checked and if so to open a text field to change the value. */}
          {
            member.username
          }
        </td>

        <td className="recordItem w-auto border-r-0">
          {/* This provides a test if the edit button has been checked and if so to open a text field to change the value. */}
          {member?.first_name ? member.first_name : ''} &nbsp;
          {member?.last_name ? member.last_name : ''}
        </td>

        <td className="recordItem w-auto border-r-0"> {member?.team_name ? member?.team_name : "-"} </td>

        <td className="recordItem w-auto border-r-0">
          {
            <div className="flex justify-center items-center">
              {circle(member.status)}
              <span className="ml-2 p-1 w-full" style={{color: getMemberStatusColor((member.status) ? member.status : '').primaryTextColor}}>
                <FiberManualRecordIcon style={{fontSize: 15}} />
                &nbsp; {(member.status) ? member.status.replace(/\b\w/g, (status: string) => status.toUpperCase()) : 'Pending'}
              </span>
            </div> 
          }
        </td>

        <td className="recordItem w-auto border-r-0">
          {
            (member && member?.plan_rate && member?.plan_rate.toString().includes(".") )? (
              `$${styleThousandCommas(member.plan_rate.toString())} (${member.plan_recurrence})`
            ) : (
              `$${styleThousandCommas(member?.plan_rate.toString())}.00 (${member.plan_recurrence})`
            )
          }
        </td>

        <td className="recordItem w-auto border-r-0">
          {
            (member && (member?.member_balance || member?.member_balance === 0))? (
              `${styleThousandCommas(member.member_balance.toString())} hours`
            ) : (
              ` - `
            )
          }
        </td>

        <td className="recordItem w-auto border-r-0">
          {moment.utc(member?.joined).fromNow()}
        </td>

        <td className={`grid ${isRequests ? 'grid-cols-3' : 'grid-cols-1'}  text-slate-400`}>
          {
            (isRequests) ? (
              <>
                <EditIcon
                  className="cursor-pointer text-[#013847]"
                  onClick={() => setEditMember(member.username)}
                />

                
                {
                  (member?.status === 'declined') ? (
                    <DoNotDisturbIcon 
                      className="cursor-pointer text-[#EB001B]"
                      onClick={() => declineRequest()}
                    />
                  ) : (
                    <DeleteIcon
                      className="cursor-pointer text-[#EB001B]"
                      onClick={() => declineRequest()}
                    />
                  )
                }
                
                <InfoIcon
                  style={{ cursor: "pointer", color: "#5DB6CE"}}
                  onClick={() => updateOpenView()}
                />
          
              </>
            ) : (
              <div className="flex flex-row justify-evenly my-4">
                {/* <EditIcon
                  className="cursor-pointer text-[#013847]"
                  onClick={() => setEditMember(member.username)}
                /> */}
                <div 
                  className="flex h-[20px] w-[20px] rounded-full border-[2px] border-black cursor-pointer"
                  onClick={()=>toggleDropdownIsOpen(prev=>!prev)}
                >
                  <MoreHorizIcon style={{fontSize: 16}} className="m-auto text-black" />
                </div>
                <Dropdown showDropdown={dropdownIsOpen} toggleShowDropdown={toggleDropdownIsOpen} toggleViewMemberDetails={toggleViewMemberDetails} />

              </div>
            )
          }
          
        </td>
      </tr>

      <MemberDetailsModal isOpen={viewMemberDetails} closeAppModal={()=>toggleViewMemberDetails(false)} member={member} handleUpdateMember={handleUpdateMember} />
    </>
  ) : null;
};

export default MemberRecord;


type dropdownOptionsType = {
  label: "Approve" | "Decline" | "View Details"
  value: 'approve' | "decline" | "details"
  textColor: string
  bgColor: string
}

  // Options for the dropdown
  const options: dropdownOptionsType[] = [
    {
      label: "Approve",
      value: 'approve',
      textColor: "text-[#0F973D]",
      bgColor: "bg-white",
    },
    {
      label: "Decline",
      value: 'decline',
      textColor: "text-[#D42620]",
      bgColor: "bg-white",
    },
    {
      label: "View Details",
      value: 'details',
      textColor: "text-[#aaa]",
      bgColor: "bg-white",
    }
  ];


type DropdownProps = {
  // selectedOption: any
  // handleSelectOption: (option: any) => void
  showDropdown: boolean
  toggleShowDropdown: React.Dispatch<React.SetStateAction<boolean>>
  toggleViewMemberDetails: React.Dispatch<React.SetStateAction<boolean>>
}

const Dropdown: React.FC<DropdownProps> = ({showDropdown, toggleShowDropdown, toggleViewMemberDetails}) => {
  // Handle selection
  const handleSelectDropdown = () => {
      toggleShowDropdown(false)
  };

  return (
    <div className="relative">
      {/* Dropdown Menu */}
      {(showDropdown) && (
      <ul 
        className="absolute w-[90px] h-[51px] left-0 right-0 border-[1px] rounded bg-white shadow z-50"
        onMouseLeave={() => toggleShowDropdown(false)}
      >
        {options.map((option, i) => (
          <li
            key={i}
            className={`font-open-sans text-left text-[14px] font-medium cursor-pointer p-2 hover:bg-gray-100 ${option.textColor} ${option.bgColor} `}
            onClick={() => (option.value === 'details') ? toggleViewMemberDetails(true) : handleSelectDropdown()}
          >
            {option.label}
            
          </li>
        ))}
      </ul>)}
    </div>
  );
};
