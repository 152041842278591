import React from "react"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ResourceRequestRecord from "../molecules/ResourceRequestRecord"
import { ResourceBookingSchema } from "../data/types";
import { capitalizeString } from "../utils/helpers";


type ResourceRequestListProps = {
  resourceType: string
  requests: ResourceBookingSchema[],
  isRequests?: boolean
}

const ResourceRequestList: React.FC<ResourceRequestListProps> = ({resourceType, requests, isRequests}) => {

  var dateFieldName = ""
  switch(resourceType){
    case 'training':
      dateFieldName = "Date"
      break
    case 'expert':
      dateFieldName = "Session Date"
      break
  }


  return (
    <table className="table-auto my-3">
      <thead className="bg-[#F8F8F8] h-[60px] border-[1px] border-[#E9E9E9]  font-open-sans">
        <tr>


          <th className="headItem border-0 text-[#4D4D4D] font-raleway text-[16px] font-normal w-3/12">
            Username &nbsp; <KeyboardArrowDownIcon style={{ cursor: "pointer", fontSize: 15 }} />
          </th>

          <th className="headItem border-0 text-[#4D4D4D] font-raleway text-[16px] font-normal w-3/12">{capitalizeString(resourceType)}</th>
          {
            (dateFieldName) && (
              <th className="headItem border-0 text-[#4D4D4D] font-raleway text-[16px] font-normal w-3/12">{dateFieldName}</th>
            )
          }

          <th className="headItem border-0 text-[#4D4D4D] font-raleway text-[16px] font-normal w-2/12">Submitted</th>
          
          <th className="headItem border-0 text-[#4D4D4D] font-raleway text-[16px] font-normal w-2/12">Status</th>
          <th className="headItem border-0 text-[#4D4D4D] font-raleway text-[16px] font-normal w-1/12">Actions</th>
        </tr>
      </thead>

      <tbody className="bg-white h-[60px]">
        {
          
         (requests) ? requests.map((request: ResourceBookingSchema, i: number) => (
            <ResourceRequestRecord
              key={i}
              request={request}
              isRequests={isRequests}
            />
          )): "No Requests"
        }
      </tbody>
    </table>
  );
};

export default ResourceRequestList;
