import { useQuery, useMutation} from '@tanstack/react-query'
import { startNewAuth, verifyUserOTP, getAuthUser, createNewCommunity, fetchCommunity, updateCommunity, signOut} from '../api/crud'
import { CommunitySchema, AccountSchema, AuthSchema } from "../data/types";
import { useAuthState } from '../contexts/authcontext'


const staleTime = 1000
const isEnabled = true


const getUserFromToken = (token: string) => {
    if (token){
        var user = JSON.parse(atob(token.split('.')[1]))
        return user.hasOwnProperty('username') && user.hasOwnProperty('exp') ? user.username : ""
    }
    return ""
}


// get user details from access token detail
export const useLoadAuthUser = () => {
    const { authUser, updateAuthUser, updateLogin, updateVerified } = useAuthState()

    const loadAuthMutation = useMutation({ 
        mutationFn: async (token_username: string) => {
            console.log("get auth token user")
            const res = await getAuthUser({token_username})
            if (res && Object.keys(res).length !== 0 && updateAuthUser && updateLogin && updateVerified){
                updateAuthUser(res)
                updateLogin(true)
                if (res.auth_status){
                    // + if auth_user has verified attribute
                    updateVerified(res.auth_status) 
                }
            }
            return res
    }})


    const registerUserMutation = useMutation({ 
        mutationFn: async (user: AccountSchema) => {
            console.log("register user")
            const res = await startNewAuth(user)
            if (res && Object.keys(res).length !== 0 && updateAuthUser && updateLogin && updateVerified){
                if (res?.login_token && res?.refresh_token){
                    localStorage.setItem('access_token', res.login_token)
                    localStorage.setItem('refresh_token', res.refresh_token)
                }
                updateAuthUser(res)
                updateLogin(true)
                if (res.auth_status){
                    updateVerified(res.auth_status) 
                }
            }
            return res
    }})


    const registerBusinessMutation = useMutation({ 
        mutationFn: async ({community, account}: {community: CommunitySchema, account: AccountSchema}) => {
            console.log("register business")
            const res = await createNewCommunity(community, account)
            if (res && Object.keys(res).length !== 0 && updateAuthUser && updateLogin && updateVerified){
                if (res?.login_token && res?.refresh_token){
                    localStorage.setItem('access_token', res.login_token)
                    localStorage.setItem('refresh_token', res.refresh_token)
                }
                updateAuthUser(res)
                updateLogin(true)
                if (res.auth_status){
                    updateVerified(res.auth_status) 
                }
            }
            return res
    }})

    const verifyUserMutation = useMutation({ 
        mutationFn: async (otp: string) => {
            console.log("verify user")
            if (authUser?.username){const res = await verifyUserOTP(otp,authUser.username)
            if (res && Object.keys(res).length !== 0 && updateAuthUser && updateLogin && updateVerified){
                if (res?.login_token && res?.refresh_token){
                    localStorage.setItem('access_token', res.login_token)
                    localStorage.setItem('refresh_token', res.refresh_token)
                }
                updateAuthUser(res)
                updateLogin(true)
                if (res.auth_status){
                    updateVerified(res.auth_status) 
                    return true
                }
            }}
            return false
    }})

    const loadAuthUser = () => {
        var access_token: any = localStorage.getItem('access_token')
        var token_username: string = getUserFromToken(access_token)
        loadAuthMutation.mutate(token_username)
        return loadAuthMutation?.data ? true : false
    }

    const registerUser = (user: AccountSchema) => {
        registerUserMutation.mutate(user)
        return registerUserMutation?.data ? true : false
    }

    const registerBusiness = (community: CommunitySchema, account: AccountSchema) => {
        registerBusinessMutation.mutate({community, account})
        return registerBusinessMutation?.data ? true : false
    }

    const verifyUser = (otp: string) => {
        verifyUserMutation.mutate(otp)
        return verifyUserMutation?.data ? true : false
    } 

    const signOutUser = () => {
        console.log("signout")
        signOut()
        if (updateAuthUser && updateLogin && updateVerified){
            updateAuthUser({} as AuthSchema)
            updateLogin(false)
            updateVerified(false)
        }
    }

    var communityId = (authUser && authUser.primary_community) ? authUser.primary_community : ""
    var { isLoadingCommunity } = useLoadCommunity(communityId)

    const isLoading = (
        loadAuthMutation.isPending || 
        registerUserMutation.isPending || 
        registerBusinessMutation.isPending || 
        verifyUserMutation.isPending || 
        isLoadingCommunity
    );

    return { loadAuthUser, registerUser, registerBusiness, verifyUser, signOutUser, isLoading }
}


export const useLoadCommunity = (communityId: string) => {
    let { updatePrimaryCommunity } = useAuthState()
    const {data: primaryCommunity, refetch: reloadCommunity, isLoading: isLoadingCommunity} = useQuery({ 
        queryKey: ['community', communityId], 
        queryFn: async () => {
            if (communityId){
                console.log("load community")
                const res = await fetchCommunity(communityId)
                if (res && updatePrimaryCommunity !== undefined){
                    updatePrimaryCommunity(res)
                }
                return res
            }
            return {}
        },
        staleTime,
        enabled: isEnabled
    })


    const updateCommunityMutation = useMutation({ 
        mutationFn: async ({communityId, communityUpdates}: {communityId: string, communityUpdates: CommunitySchema}) => {
            console.log("update community")
            const res = await updateCommunity(communityId, communityUpdates)
            reloadCommunity()
            return res
    }})

    const updateCommunityDetails = (communityId: string, communityUpdates: CommunitySchema) => {
        updateCommunityMutation.mutate({communityId, communityUpdates})
        return updateCommunityMutation?.data ? true : false
    } 

    const isUpdateSuccess = updateCommunityMutation.isSuccess
    const isUpdateError = updateCommunityMutation.isError
    const isUpdatePending = updateCommunityMutation.isPending

    return {primaryCommunity, isLoadingCommunity, isUpdatePending, isUpdateSuccess, isUpdateError, updateCommunityDetails, reloadCommunity}
}



    // const updateProfile = async (user_data: AccountSchema) => {
    //     if (authUser && authUser.hasOwnProperty('phone')){
    //         let user
    //         if (user_data && authUser){
    //             user = await updateUserData(authUser, user_data)
    //         }
    //         if (user && Object.keys(user).length !== 0){
    //             updateAuthUser(user)
    //             if (user.auth_status){
    //                 // + if auth_user has verified attribute
    //                 updateVerified(user.auth_status) 
    //             }
    //             return true
    //         }
    //     }
    //     return false
    // }



    // // initiate user auth
    // const registerUser = async (user: AccountSchema) => {
    //     let loginType = user.phone ? 'phone' : 'email'
    //     localStorage.setItem('loginType', loginType)
    //     updateLoginType(loginType)

    //     var auth_user = await startNewAuth(user)
    //     // console.log("registerUser auth_user",auth_user)
    //     if (auth_user && auth_user.login_token && auth_user.refresh_token){
    //         updateAuthUser(auth_user)
    //         updateLogin(true)
    //         if (auth_user.auth_status){
    //             // + if auth_user has verified attribute
    //             updateVerified(auth_user.auth_status) 
    //         }
    //         localStorage.setItem('access_token', auth_user.login_token)
    //         localStorage.setItem('refresh_token', auth_user.refresh_token)
    //         return true
    //     }
    //     return false
    // }


    // const registerBusiness = async (community: CommunitySchema, account: AccountSchema) => {
    //     var auth_user = await createNewCommunity(community, account)
    //     if (auth_user && auth_user.login_token && auth_user.refresh_token){
    //         updateAuthUser(auth_user)
    //         updateLogin(true)
    //         if (auth_user.auth_status){
    //             updateVerified(auth_user.auth_status) 
    //         }
    //         localStorage.setItem('access_token', auth_user.login_token)
    //         localStorage.setItem('refresh_token', auth_user.refresh_token)
    //         var communityId = (auth_user && auth_user.primary_community) ? auth_user.primary_community : ""
    //         if (communityId){
    //             loadPrimaryCommunity(communityId)
    //         }
    //         return true
    //     }
    //     return false
    // }


    // const verifyUser = async (otp: string) => {
    //     let username = ""
    //     if (authUser && authUser?.username){
    //         username = authUser.username
    //     }
    //     let auth_user = await verifyUserOTP(otp, username)
    //     if (auth_user && auth_user?.auth_status && auth_user?.login_token){
    //         updateAuthUser(auth_user)
    //         updateLogin(true)
    //         if (auth_user?.auth_status){
    //             updateVerified(auth_user.auth_status)
    //             return true
    //         }
    //     }
    //     return false
    // }


    // const loadPrimaryCommunity = async (communityId: string) => {
    //     var communityDetails = await fetchCommunity(communityId)
    //     if (communityDetails){
    //         updatePrimaryCommunity(communityDetails)
    //     }
    // }


    // const handleUpdateLoginType = (loginType: string) => {
    //     updateLoginType(loginType ? loginType : 'phone')
    //     localStorage.setItem('loginType', loginType)
    // }



