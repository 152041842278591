import React, { useState } from 'react'

import { AddCircle, Cancel, CheckBox, CheckBoxOutlineBlank, } from "@mui/icons-material";

import { useLoadResourceOptions } from '../../hooks/kitchenhooks';
import { FoodBusinessExpertCategories, FoodBusinessFundingCategories, usStates, USStatesEnum } from "../../utils/enums"
import { InventorySchema, ResourceSchema, ResourceType } from '../../data/types';
import { AddImageBox } from '../../components/AddImageBox'

import { ReactComponent as RightArrow } from "../../icons/ScrollRightArrow.svg";
import { ReactComponent as LeftArrow } from "../../icons/ScrollLeftArrow.svg";
// import { ReactComponent as AddImageIcon } from '../../icons/AddImageIcon.svg'
// import { ReactComponent as RightArrow } from "../../icons/ScrollRightArrow.svg";
// import { ReactComponent as LeftArrow } from "../../icons/ScrollLeftArrow.svg";
import { AddProductForm } from './AddProductForm';
import { capitalizeString } from '../../utils/helpers';



type AddResourceFormProps = {
  resourceType: ResourceType
  handleClose: () => void;
  handleAddResource: (resource: ResourceSchema) => void;
}

const AddResourceForm: React.FC<AddResourceFormProps> = ({resourceType, handleAddResource, handleClose}) => {
  const { getResourceIconData } = useLoadResourceOptions()
  const resourceIconData = getResourceIconData(resourceType)

  return (
    <div className='bg-white flex flex-col justify-start w-11/12 mx-auto h-full'>
      <span onClick={handleClose} className="ml-auto w-full flex cursor-pointer">
          <Cancel className="text-[#A1A1A1] hover:text-[#777] h-[18px] w-[18px] ml-auto" />
      </span>
      {
        (resourceType === "farm") ? (
          <AddFarmForm resourceType={resourceType} resourceIconData={resourceIconData} handleAddResource={handleAddResource} />
        ) : (
          (resourceType === "training") ? (
            <AddTrainingForm resourceType={resourceType} resourceIconData={resourceIconData} handleAddResource={handleAddResource} />
          ) : (
            (resourceType === "funding") ? (
              <AddFundingForm resourceType={resourceType} resourceIconData={resourceIconData} handleAddResource={handleAddResource} />
            ) : (
              (resourceType === "expert") ? (
                <AddExpertForm resourceType={resourceType} resourceIconData={resourceIconData} handleAddResource={handleAddResource} />
              ) : (
                (resourceType === "documentation") ? (
                  <AddDocumentationForm resourceType={resourceType} resourceIconData={resourceIconData} handleAddResource={handleAddResource} />
                ) : (
                  (resourceType === "kitchen") ? (
                    <AddKitchenForm resourceType={resourceType} resourceIconData={resourceIconData} handleAddResource={handleAddResource} />
                  ) : (
                    <></>
                  )
                )
              )
            )
          )
        )
      }
      
    </div>
  )
}


type AddFarmFormProps = {
  resourceType: ResourceType
  resourceIconData: any
  handleAddResource: (resource: ResourceSchema) => void;
}
const AddFarmForm: React.FC<AddFarmFormProps> = ({resourceType, resourceIconData, handleAddResource}) => {
  const [step, setStep] = useState<1 | 2>(1)
  const [newResource, setNewResource] = useState<ResourceSchema>({} as ResourceSchema)
  const resourceCategory = resourceIconData.name

  const [isAddingProduct, setIsAddingProduct] = useState<boolean>(false)
  const [currentInventoryList, setCurrentInventoryList] = useState<InventorySchema[]>([])
  const [selectedResourceImage, setSelectedResourceImage] = useState<string>(newResource?.bgPhoto ? newResource?.bgPhoto : "");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    e.preventDefault()
    const {name, value, files}: any = e.target
    if (name && files && files.length > 0) {
      let file_data = files[0];
      setNewResource(prev => ({
        ...prev,
        [name]: file_data,
      }));
    } else if (name && (value || value === "")){
      setNewResource(prev=>(
        {
          ...prev,
          [name]: value
        }
      ))
    }
  }

  return (step === 1) ? (
    <>
      <p className='text-black text-[18px] font-bold font-raleway my-1'>Add {resourceCategory} Resource</p>
      <p className='text-[#4D4D4D] text-[16px] font-light font-open-sans my-1'>Fill out the details below to set up your fresh food products.</p>
      <div className='my-4'>
        <p className='font-raleway text-black text-[16px] font-medium'>Name</p>
        <input 
          type='text' 
          placeholder={`Enter your ${resourceType} name`}
          className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
          name="name"
          value={newResource?.name}
          onChange={handleInputChange}
        />
      </div>
      <div className='my-4'>
        <p className='font-raleway text-black text-[16px] font-medium'>Description</p>
        <textarea 
          placeholder='Provide a brief description of this resource and what it offers.' 
          className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[120px] text-[16px]'
          name="description"
          value={newResource?.description}
          onChange={handleInputChange}
        />
      </div>

      <div className='flex flex-row'>
        <div className='my-4 mr-1'>
          <p className='font-raleway text-black text-[16px] font-medium'>City</p>
          <input 
            type='text' 
            placeholder='Enter City' 
            className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
            name="city"
            value={newResource?.city}
            onChange={handleInputChange}
          />
        </div>
        <div className='my-4 mr-1'>
          <p className='font-raleway text-black text-[16px] font-medium'>State</p>
          <select 
            name="state" 
            value={newResource?.state} 
            className="border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]"
            onChange={handleInputChange}
          >
            <option value="">-- Select State --</option>
            <option value="nationwide">All</option>
            {
                (usStates && usStates.length > 0) && usStates.map((state, i) => (
                    <option value={state} key={i}>{USStatesEnum[state]?.fullName}</option>
                ))
            }
          </select>
        </div>
        <div className='my-4'>
          <p className='font-raleway text-black text-[16px] font-medium'>Zipcode</p>
          <input 
            type='text' 
            placeholder='Enter Zipcode' 
            className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
            name="zipcode"
            value={newResource?.zipcode}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className='my-4'>
        <AddImageBox 
          name="bgPhoto"
          title="Upload Images" 
          description="Upload clear images that showcase your resource. Ensure each image is well-lit, high-quality, and at least 800x600 pixels (JPEG or PNG format)." 
          selectedImage={selectedResourceImage}
          setSelectedImage={setSelectedResourceImage}
          handleChange={handleInputChange}
        />
      </div>

      <div className='flex flex-row justify-between w-11/12 mx-auto mb-4 border-t-[1px] border-[#E9E9E9] py-2'>
        <button className='border-0 font-raleway text-[#4D4D4D]'>Save to Drafts</button>
        <button 
          // className='border-0 font-raleway text-white bg-[#E6E6E6] p-2 rounded-md w-[90px]'
          className='border-0 font-raleway text-white bg-primary-200 p-2 rounded-md w-[90px]'
          onClick={()=>setStep(2)}
        >
            Next
        </button>
      </div>
    </>
  ) : (
    <>
      {
        (isAddingProduct) ? (
          <AddProductForm 
            resourceType={resourceType} 
            resourceCategory={resourceCategory} 
            setIsAddingProduct={setIsAddingProduct} 
            setCurrentInventoryList={setCurrentInventoryList} 
          />
        ) : (
          <>
            <p className='text-black text-[18px] font-bold font-raleway my-1'>Add {resourceCategory} Resource</p>
            <p className='text-[#4D4D4D] text-[16px] font-light font-open-sans my-1'>Fill out the details below to set up your fresh food products.</p>
            <div className='my-4'>
              <p className='font-raleway text-black text-[16px] font-medium'>Add Products</p>
              <div className='text-[#4D4D4D] text-[16px] font-open-sans font-thin'>
                Add your individual products here, including names, prices, and images.
              </div>
            </div>
            <div className='flex my-4'>
              
              <button className='ml-auto bg-black text-white p-2 rounded-md' onClick={()=> setIsAddingProduct(true)}>
                <AddCircle className='my-auto mr-2' />
                Add Product
              </button>
            </div>

            {
              (currentInventoryList.length > 0) && currentInventoryList.map((inventory, i) => (
                <div key={i} className='flex flex-row my-4 border-[#E9E9E9] border-[1px] h-[266px] rounded-lg'>
                  <div className='w-1/3 bg-slate-300'>
                  </div>
                  <div className='flex flex-col w-2/3'>
                    <p className='mx-4 mt-4 font-raleway text-[18px] text-black font-semibold'>{inventory.item_name}</p>
                    <p className='font-open-sans text-[16px] text-[#4D4D4D] mx-4'>{inventory.item_description}</p>
                    <div className='flex flex-row justify-between border-t-[1px] border-b-[1px] border-[#E9E9E9] h-[51px] my-4 w-11/12 mx-auto'>
                      <p className='my-auto'>{inventory.total_quantity} units</p>
                      <p className='my-auto'>${inventory.unit_price ? inventory.unit_price : 0} per {inventory.measure_unit}</p>
                    </div>
                  </div>
                </div>
              ))
            }

            <div className='flex flex-row text-[16px] justify-between w-11/12 mx-auto mb-4 border-t-[1px] border-[#E9E9E9] py-2'>
              <button className='border-0 font-raleway text-[#4D4D4D]'>Save to Drafts</button>
              <button className='border-0 font-raleway text-primary-200 font-medium' onClick={()=>setStep(1)}>Back</button>
              <button 
                className='border-0 font-raleway w-[160px] text-white bg-primary-200 p-2 rounded-md'
                onClick={()=>handleAddResource(newResource)}
                disabled={!newResource.name}
              >
                  Publish Resource
              </button>
            </div>
          </>
        )
      }
    </>
  )
}






type AddDocumentationFormProps = {
  resourceType: ResourceType
  resourceIconData: any
  handleAddResource: (resource: ResourceSchema) => void;
}

const AddDocumentationForm: React.FC<AddDocumentationFormProps> = ({resourceType, resourceIconData, handleAddResource}) => {
  // const [step, setStep] = useState<1 | 2>(1)
  const [newResource, setNewResource] = useState<ResourceSchema>({} as ResourceSchema)
  const resourceCategory = resourceIconData.name

  // const [isAddingProduct, setIsAddingProduct] = useState<boolean>(false)
  // const [currentInventoryList, setCurrentInventoryList] = useState<InventorySchema[]>([])
  // const [selectedResourceImage, setSelectedResourceImage] = useState<string>(newResource?.bgPhoto ? newResource?.bgPhoto : "");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    e.preventDefault()
    const {name, value, files}: any = e.target
    if (name && files && files.length > 0) {
      let file_data = files[0];
      setNewResource(prev => ({
        ...prev,
        [name]: file_data,
      }));
    } else if (name && (value || value === "")){
      setNewResource(prev=>(
        {
          ...prev,
          [name]: value
        }
      ))
    }
  }

  return (
    <>
      <p className='text-black text-[18px] font-bold font-raleway my-1'>Add {resourceCategory} Resource</p>
      <p className='text-[#4D4D4D] text-[16px] font-light font-open-sans my-1'>Fill out the details below to add your documentation resource.</p>
      <div className='my-4'>
        <p className='font-raleway text-black text-[16px] font-medium'>Name</p>
        <input 
          type='text' 
          placeholder={`Enter your ${resourceType} name`}
          className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
          name="name"
          value={newResource?.name}
          onChange={handleInputChange}
        />
      </div>
      <div className='my-4'>
        <p className='font-raleway text-black text-[16px] font-medium'>Description</p>
        <textarea 
          placeholder='Provide a brief description of this resource and what it offers.' 
          className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[120px] text-[16px]'
          name="description"
          value={newResource?.description}
          onChange={handleInputChange}
        />
      </div>


      <div className='flex flex-row text-[16px] justify-between w-11/12 mx-auto mb-4 border-t-[1px] border-[#E9E9E9] py-2'>
        <button className='border-0 font-raleway text-[#4D4D4D]'>Save to Drafts</button>
        <button 
          className='border-0 font-raleway w-[160px] text-white bg-primary-200 p-2 rounded-md'
          onClick={()=>handleAddResource(newResource)}
          disabled={!newResource.name}
        >
            Publish Resource
        </button>
      </div>
    </>
  )
}





type AddTrainingFormProps = {
  resourceType: ResourceType
  resourceIconData: any
  handleAddResource: (resource: ResourceSchema) => void;
}
const AddTrainingForm: React.FC<AddTrainingFormProps> = ({resourceType, resourceIconData, handleAddResource}) => {
  const [step, setStep] = useState<1 | 2 | 3>(1)
  const [newResource, setNewResource] = useState<ResourceSchema>({} as ResourceSchema)
  const resourceCategory = resourceIconData.name

  const [isAddingDates, setIsAddingDates] = useState<boolean>(false)
  const [currentInventoryList, setCurrentInventoryList] = useState<InventorySchema[]>([])
  const [isFree, setIsFree] = useState<boolean>(false)
  const [selectedResourceImage, setSelectedResourceImage] = useState<string>(newResource?.bgPhoto ? newResource?.bgPhoto : "");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    e.preventDefault()
    const {name, value, files}: any = e.target
    if (name && files && files.length > 0) {
      let file_data = files[0];
      setNewResource(prev => ({
        ...prev,
        [name]: file_data,
      }));
    } else if (name && (value || value === "")){
      setNewResource(prev=>(
        {
          ...prev,
          [name]: value
        }
      ))
    }
  }

  return (step === 1) ? (
    <>
      <p className='text-black text-[18px] font-bold font-raleway my-1'>Add {resourceCategory} Resource</p>
      <p className='text-[#4D4D4D] text-[16px] font-light font-open-sans my-1'>Fill out the details below to add your culinary training resource.</p>
      <div className='my-4'>
        <p className='font-raleway text-black text-[16px] font-medium'>Name</p>
        <input 
          type='text' 
          placeholder={`Enter your ${resourceType} name`}
          className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
          name="name"
          value={newResource?.name}
          onChange={handleInputChange}
        />
      </div>
      <div className='my-4'>
        <p className='font-raleway text-black text-[16px] font-medium'>Description</p>
        <textarea 
          placeholder='Provide a brief description of this resource and what it offers.' 
          className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[120px] text-[16px]'
          name="description"
          value={newResource?.description}
          onChange={handleInputChange}
        />
      </div>

      <div className='flex flex-row'>
        <div className='my-4 mr-1'>
          <p className='font-raleway text-black text-[16px] font-medium'>City</p>
          <input 
            type='text' 
            placeholder='Enter City' 
            className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
            name="city"
            value={newResource?.city}
            onChange={handleInputChange}
          />
        </div>
        <div className='my-4 mr-1'>
          <p className='font-raleway text-black text-[16px] font-medium'>State</p>
          <select 
            name="state" 
            value={newResource?.state} 
            className="border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]"
            onChange={handleInputChange}
          >
            <option value="">-- Select State --</option>
            <option value="nationwide">All</option>
            {
                (usStates && usStates.length > 0) && usStates.map((state, i) => (
                    <option value={state} key={i}>{USStatesEnum[state]?.fullName}</option>
                ))
            }
          </select>
        </div>
        <div className='my-4'>
          <p className='font-raleway text-black text-[16px] font-medium'>Zipcode</p>
          <input 
            type='text' 
            placeholder='Enter Zipcode' 
            className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
            name="zipcode"
            value={newResource?.zipcode}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className='flex flex-col w-full flex-start mt-4'>
        {
          (isFree) ? (
            <div 
              className={`flex flex-row  hover:bg-[#E9E9E9] text-[16px] font-open-sans rounded-md h-[59px] cursor-pointer`}
              onClick={() => setIsFree(prev=> !prev)}
            >
                <CheckBox 
                  className="mx-2 my-auto text-primary-400" 
                  style={{height: 20, width: 20}} 
                />
                <p className="my-auto">This training is free</p>
            </div>
          ) : (
              <>
                <div className="flex flex-col justify-between">
                  <span className="font-raleway text-black text-[16px] font-medium">
                    Training Cost ($)
                  </span>
                  <label className="flex flex-row text-primary-200 border-[1px] border-[#E9E9E9] h-[50px] w-full rounded-lg">
                    <input
                      placeholder="0.00"
                      className="border-0 bg-inherit w-full my-1 "
                      name="total_amount"
                      type="number"
                      // value={0}
                      step={0.10}
                      min={0}
                      onChange={handleInputChange}
                    />

                    <div
                      className='rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer my-auto m-1'
                    >
                      <LeftArrow className=" text-black rounded-full  m-auto" />
                    </div>
                    <div
                      className='rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer my-auto m-1'
                    >
                        <RightArrow className="text-black rounded-full  my-auto" />
                    </div>
                  </label>
                </div>
                <div 
                  className={`flex flex-row  text-[16px] font-open-sans rounded-md my-1 h-[59px] cursor-pointer`}
                  onClick={() => setIsFree(prev=> !prev)}
                >
                  <CheckBoxOutlineBlank 
                    className="mx-2 text-[#CFCFCF] my-auto" 
                    style={{height: 20, width: 20}} 
                  />
                  <p className="my-auto">This training is free</p>
                </div>
              </>
          )
        }
      </div>

      <div className='flex flex-row justify-between w-11/12 mx-auto mb-4 border-t-[1px] border-[#E9E9E9] py-2'>
        <button className='border-0 font-raleway text-[#4D4D4D]'>Save to Drafts</button>
        <button 
          // className='border-0 font-raleway text-white bg-[#E6E6E6] p-2 rounded-md w-[90px]'
          className='border-0 font-raleway text-white bg-primary-200 p-2 rounded-md w-[90px]'
          onClick={()=>setStep(2)}
        >
            Next
        </button>
      </div>
    </>
  ) : (
    (step === 2) ? (
      <>
        <p className='text-black text-[18px] font-bold font-raleway my-1'>Add {resourceCategory} Resource</p>
        <p className='text-[#4D4D4D] text-[16px] font-light font-open-sans my-1'>Fill out the details below to add your culinary training resource.</p>
      
        <div className='my-4'>
          <AddImageBox 
            name="bgPhoto"
            title="Upload Images" 
            description="Upload clear images that showcase your resource. Ensure each image is well-lit, high-quality, and at least 800x600 pixels (JPEG or PNG format)." 
            selectedImage={selectedResourceImage}
            setSelectedImage={setSelectedResourceImage}
            handleChange={handleInputChange}
          />
        </div>

        <div className='flex flex-row justify-between w-11/12 mx-auto my-4 border-t-[1px] border-[#E9E9E9] py-2'>
          <button className='border-0 font-raleway text-[#4D4D4D]'>Save to Drafts</button>
          <button className='border-0 font-raleway text-primary-200 font-medium' onClick={()=>setStep(1)}>Back</button>
          <button 
            // className='border-0 font-raleway text-white bg-[#E6E6E6] p-2 rounded-md w-[90px]'
            className='border-0 font-raleway text-white bg-primary-200 p-2 rounded-md w-[90px]'
            onClick={()=>setStep(3)}
          >
              Next
          </button>
        </div>
      </>
    ) : (
      <>
        {
          (isAddingDates) ? (
            <AddProductForm 
              resourceType={resourceType} 
              resourceCategory={resourceCategory} 
              setIsAddingProduct={setIsAddingDates} 
              setCurrentInventoryList={setCurrentInventoryList} 
            />
          ) : (
            <>
              <p className='text-black text-[18px] font-bold font-raleway my-1'>Add {resourceCategory} Resource</p>
              <p className='text-[#4D4D4D] text-[16px] font-light font-open-sans my-1'>Fill out the details below to add your culinary training resource.</p>
              
              <div className='my-4'>
                <p className='font-raleway text-black text-[16px] font-medium'>Add Training Schedule</p>
                <div className='text-[#4D4D4D] text-[16px] font-open-sans font-thin'>
                  Add your individual training schedule here
                </div>
              </div>

              <div className='flex my-4'>
                
                <button className='ml-auto bg-black text-white p-2 rounded-md' onClick={()=> setIsAddingDates(true)}>
                  <AddCircle className='my-auto mr-2' />
                  Add Schedule
                </button>
              </div>
              {
                (currentInventoryList.length > 0) && currentInventoryList.map((inventory, i) => (
                  <div key={i} className='flex flex-row my-4 border-[#E9E9E9] border-[1px] h-[266px] rounded-lg'>
                    <div className='w-1/3 bg-slate-300'>
                    </div>
                    <div className='flex flex-col w-2/3'>
                      <p className='mx-4 mt-4 font-raleway text-[18px] text-black font-semibold'>{inventory.item_name}</p>
                      <p className='font-open-sans text-[16px] text-[#4D4D4D] mx-4'>{inventory.item_description}</p>
                      <div className='flex flex-row justify-between border-t-[1px] border-b-[1px] border-[#E9E9E9] h-[51px] my-4 w-11/12 mx-auto'>
                        <p className='my-auto'>{inventory.total_quantity} units</p>
                        <p className='my-auto'>${inventory.unit_price ? inventory.unit_price : 0} per {inventory.measure_unit}</p>
                      </div>
                    </div>
                  </div>
                ))
              }
              <div className='flex flex-row text-[16px] justify-between w-11/12 mx-auto mb-4 border-t-[1px] border-[#E9E9E9] py-2'>
                <button className='border-0 font-raleway text-[#4D4D4D]'>Save to Drafts</button>
                <button className='border-0 font-raleway text-primary-200 font-medium' onClick={()=>setStep(2)}>Back</button>
                <button 
                  className='border-0 font-raleway w-[160px] text-white bg-primary-200 p-2 rounded-md'
                  onClick={()=>handleAddResource(newResource)}
                  disabled={!newResource.name}
                >
                    Publish Resource
                </button>
              </div>
            </>
          )
        }
      </>
  ))
}



type AddKitchenFormProps = {
  resourceType: ResourceType
  resourceIconData: any
  handleAddResource: (resource: ResourceSchema) => void;
}

const AddKitchenForm: React.FC<AddKitchenFormProps> = ({resourceType, resourceIconData, handleAddResource}) => {
  // const [step, setStep] = useState<1 | 2>(1)
  const [newResource, setNewResource] = useState<ResourceSchema>({} as ResourceSchema)
  const resourceCategory = resourceIconData.name

  // const [isAddingProduct, setIsAddingProduct] = useState<boolean>(false)
  // const [currentInventoryList, setCurrentInventoryList] = useState<InventorySchema[]>([])
  const [selectedResourceImage, setSelectedResourceImage] = useState<string>(newResource?.bgPhoto ? newResource?.bgPhoto : "");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    e.preventDefault()
    const {name, value, files}: any = e.target
    if (name && files && files.length > 0) {
      let file_data = files[0];
      setNewResource(prev => ({
        ...prev,
        [name]: file_data,
      }));
    } else if (name && (value || value === "")){
      setNewResource(prev=>(
        {
          ...prev,
          [name]: value
        }
      ))
    }
  }

  return (
    <>
      <p className='text-black text-[18px] font-bold font-raleway my-1'>Add {resourceCategory} Resource</p>
      <p className='text-[#4D4D4D] text-[16px] font-light font-open-sans my-1'>Fill in the details below to add your Kitchen Space.</p>
      <div className='my-4'>
        <p className='font-raleway text-black text-[16px] font-medium'>Name</p>
        <input 
          type='text' 
          placeholder={`Enter your ${resourceType} name`}
          className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
          name="name"
          value={newResource?.name}
          onChange={handleInputChange}
        />
      </div>
      <div className='my-4'>
        <p className='font-raleway text-black text-[16px] font-medium'>Description</p>
        <textarea 
          placeholder='Provide a brief description of this resource and what it offers.' 
          className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[120px] text-[16px]'
          name="description"
          value={newResource?.description}
          onChange={handleInputChange}
        />
      </div>

      <div className='flex flex-row'>
        <div className='my-4 mr-1'>
          <p className='font-raleway text-black text-[16px] font-medium'>City</p>
          <input 
            type='text' 
            placeholder='Enter City' 
            className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
            name="city"
            value={newResource?.city}
            onChange={handleInputChange}
          />
        </div>
        <div className='my-4 mr-1'>
          <p className='font-raleway text-black text-[16px] font-medium'>State</p>
          <select 
            name="state" 
            value={newResource?.state} 
            className="border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]"
            onChange={handleInputChange}
          >
            <option value="">-- Select State --</option>
            <option value="nationwide">All</option>
            {
                (usStates && usStates.length > 0) && usStates.map((state, i) => (
                    <option value={state} key={i}>{USStatesEnum[state]?.fullName}</option>
                ))
            }
          </select>
        </div>
        <div className='my-4'>
          <p className='font-raleway text-black text-[16px] font-medium'>Zipcode</p>
          <input 
            type='text' 
            placeholder='Enter Zipcode' 
            className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
            name="zipcode"
            value={newResource?.zipcode}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className='my-4'>
        <AddImageBox 
          name="bgPhoto"
          title="Upload Images" 
          description="Upload clear images that showcase your resource. Ensure each image is well-lit, high-quality, and at least 800x600 pixels (JPEG or PNG format)." 
          selectedImage={selectedResourceImage}
          setSelectedImage={setSelectedResourceImage}
          handleChange={handleInputChange}
        />
      </div>

      <div className='flex flex-row justify-between w-11/12 mx-auto mb-4 border-t-[1px] border-[#E9E9E9] py-2'>
        <button className='border-0 font-raleway text-[#4D4D4D]'>Save to Drafts</button>
        <button 
          className='border-0 font-raleway w-[160px] text-white bg-primary-200 p-2 rounded-md'
          onClick={()=>handleAddResource(newResource)}
          disabled={!newResource.name}
        >
            Publish Resource
        </button>
      </div>
    </>
  )
}





type AddFundingFormProps = {
  resourceType: ResourceType
  resourceIconData: any
  handleAddResource: (resource: ResourceSchema) => void;
}
const AddFundingForm: React.FC<AddFundingFormProps> = ({resourceType, resourceIconData, handleAddResource}) => {
  const [step, setStep] = useState<1 | 2>(1)
  const [newResource, setNewResource] = useState<ResourceSchema>({} as ResourceSchema)
  const resourceCategory = resourceIconData.name
  const [selectedResourceImage, setSelectedResourceImage] = useState<string>(newResource?.bgPhoto ? newResource?.bgPhoto : "");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    e.preventDefault()
    const {name, value, files}: any = e.target
    if (name && files && files.length > 0) {
      let file_data = files[0];
      setNewResource(prev => ({
        ...prev,
        [name]: file_data,
      }));
    } else if (name && (value || value === "")){
      setNewResource(prev=>(
        {
          ...prev,
          [name]: value
        }
      ))
    }
  }

  return (step === 1) ? (
    <>
      <p className='text-black text-[18px] font-bold font-raleway my-1'>Add {resourceCategory} Resource</p>
      <p className='text-[#4D4D4D] text-[16px] font-light font-open-sans my-1'>Fill out the details below to add your funding resource.</p>

      <div className="flex flex-col text-primary-200 my-4">
        <span className="font-raleway text-black text-[16px] font-medium">
          Funding Type
          {/* <span className='text-red-500 text-sm mx-2'>*</span> */}
        </span>
        <select
          name="resource_subtype"
          value={newResource.resource_subtype}
          onChange={handleInputChange}
          className="formInput border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]"
        >
          <option value="" disabled>Select funding type</option>
          {Object.values(FoodBusinessFundingCategories).map((resource_subtype, i) => (
            <option key={i} value={resource_subtype}>
              {capitalizeString(resource_subtype)}
            </option>
          ))}
        </select>
      </div>
      <div className='my-4'>
        <p className='font-raleway text-black text-[16px] font-medium'>Name</p>
        <input 
          type='text' 
          placeholder={`Enter your ${resourceType} name`}
          className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
          name="name"
          value={newResource?.name}
          onChange={handleInputChange}
        />
      </div>
      <div className='my-4'>
        <p className='font-raleway text-black text-[16px] font-medium'>Description</p>
        <textarea 
          placeholder='Provide a brief description of this resource and what it offers.' 
          className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[120px] text-[16px]'
          name="description"
          value={newResource?.description}
          onChange={handleInputChange}
        />
      </div>

      <div className='flex flex-row'>
        <div className='my-4 mr-1'>
          <p className='font-raleway text-black text-[16px] font-medium'>City</p>
          <input 
            type='text' 
            placeholder='Enter City' 
            className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
            name="city"
            value={newResource?.city}
            onChange={handleInputChange}
          />
        </div>
        <div className='my-4 mr-1'>
          <p className='font-raleway text-black text-[16px] font-medium'>State</p>
          <select 
            name="state" 
            value={newResource?.state} 
            className="border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]"
            onChange={handleInputChange}
          >
            <option value="">-- Select State --</option>
            <option value="nationwide">All</option>
            {
                (usStates && usStates.length > 0) && usStates.map((state, i) => (
                    <option value={state} key={i}>{USStatesEnum[state]?.fullName}</option>
                ))
            }
          </select>
        </div>
        <div className='my-4'>
          <p className='font-raleway text-black text-[16px] font-medium'>Zipcode</p>
          <input 
            type='text' 
            placeholder='Enter Zipcode' 
            className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
            name="zipcode"
            value={newResource?.zipcode}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className='my-4'>
        <AddImageBox 
          name="bgPhoto"
          title="Upload Images" 
          description="Upload clear images that showcase your resource. Ensure each image is well-lit, high-quality, and at least 800x600 pixels (JPEG or PNG format)." 
          selectedImage={selectedResourceImage}
          setSelectedImage={setSelectedResourceImage}
          handleChange={handleInputChange}
        />
      </div>

      <div className='flex flex-row justify-between w-11/12 mx-auto mb-4 border-t-[1px] border-[#E9E9E9] py-2'>
        <button className='border-0 font-raleway text-[#4D4D4D]'>Save to Drafts</button>
        <button 
          // className='border-0 font-raleway text-white bg-[#E6E6E6] p-2 rounded-md w-[90px]'
          className='border-0 font-raleway text-white bg-primary-200 p-2 rounded-md w-[90px]'
          onClick={()=>setStep(2)}
        >
            Next
        </button>
      </div>
    </>
  ) : (
    <>
      <p className='text-black text-[18px] font-bold font-raleway my-1'>Add {resourceCategory} Resource</p>
      <p className='text-[#4D4D4D] text-[16px] font-light font-open-sans my-1'>Fill out the details below to add your funding resource.</p>

      <div className="flex flex-col my-4 w-full">
        <p className='font-raleway text-black text-[16px] font-medium'>Application Deadline</p>
        <input 
          type='date' 
          className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
          name="end_time"
          value=""
          onChange={handleInputChange}
        />
      </div>

      <div className="flex flex-col justify-between">
        <span className="font-raleway text-black text-[16px] font-medium">
        Funding amount
        </span>
        <label className="flex flex-row text-primary-200 border-[1px] border-[#E9E9E9] h-[50px] w-full rounded-lg">
          <input
            placeholder="0.00"
            className="border-0 bg-inherit w-full my-1 "
            name="total_amount"
            type="number"
            // value={0}
            step={0.10}
            min={0}
            onChange={handleInputChange}
          />

          <div
            className='rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer my-auto m-1'
          >
            <LeftArrow className=" text-black rounded-full  m-auto" />
          </div>
          <div
            className='rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer my-auto m-1'
          >
              <RightArrow className="text-black rounded-full  my-auto" />
          </div>
        </label>
      </div>

      <div className='flex flex-row text-[16px] justify-between w-11/12 mx-auto mb-4 border-t-[1px] border-[#E9E9E9] py-2'>
        <button className='border-0 font-raleway text-[#4D4D4D]'>Save to Drafts</button>
        <button className='border-0 font-raleway text-primary-200 font-medium' onClick={()=>setStep(1)}>Back</button>
        <button 
          className='border-0 font-raleway w-[160px] text-white bg-primary-200 p-2 rounded-md'
          onClick={()=>handleAddResource(newResource)}
          disabled={!newResource.name}
        >
            Publish Resource
        </button>
      </div>  
    </>
  )
}




type AddExpertFormProps = {
  resourceType: ResourceType
  resourceIconData: any
  handleAddResource: (resource: ResourceSchema) => void;
}
const AddExpertForm: React.FC<AddExpertFormProps> = ({resourceType, resourceIconData, handleAddResource}) => {
  // const [step, setStep] = useState<1 | 2>(1)
  const [newResource, setNewResource] = useState<ResourceSchema>({} as ResourceSchema)
  const resourceCategory = resourceIconData.name

  // const [isAddingProduct, setIsAddingProduct] = useState<boolean>(false)
  // const [currentInventoryList, setCurrentInventoryList] = useState<InventorySchema[]>([])
  const [selectedResourceImage, setSelectedResourceImage] = useState<string>(newResource?.bgPhoto ? newResource?.bgPhoto : "");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    e.preventDefault()
    const {name, value, files}: any = e.target
    if (name && files && files.length > 0) {
      let file_data = files[0];
      setNewResource(prev => ({
        ...prev,
        [name]: file_data,
      }));
    } else if (name && (value || value === "")){
      setNewResource(prev=>(
        {
          ...prev,
          [name]: value
        }
      ))
    }
  }

  return (
    <>
      <p className='text-black text-[18px] font-bold font-raleway my-1'>Add {resourceCategory} Resource</p>
      <p className='text-[#4D4D4D] text-[16px] font-light font-open-sans my-1'>Fill out the details below to add industry expertise.</p>
      <div className='my-4'>
        <p className='font-raleway text-black text-[16px] font-medium'>Name</p>
        <input 
          type='text' 
          placeholder='Enter name' 
          className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
          name="name"
          value={newResource?.name}
          onChange={handleInputChange}
        />
      </div>
      <div className="flex flex-col text-primary-200 my-4">
        <span className="font-raleway text-black text-[16px] font-medium">
          Expertise
        </span>
        <select
          name="resource_subtype"
          value={newResource.resource_subtype}
          onChange={handleInputChange}
          className="formInput border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]"
        >
          <option value="" disabled>Select business specializations</option>
          {Object.values(FoodBusinessExpertCategories).map((resource_subtype, i) => (
            <option key={i} value={resource_subtype}>
              {capitalizeString(resource_subtype)}
            </option>
          ))}
          <option value="other">Other</option>
        </select>
      </div>
      <div className='my-4'>
        <p className='font-raleway text-black text-[16px] font-medium'>Description</p>
        <textarea 
          placeholder='Provide a brief description of what you offer.' 
          className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[120px] text-[16px]'
          name="description"
          value={newResource?.description}
          onChange={handleInputChange}
        />
      </div>

      <div className='flex flex-row'>
        <div className='my-4 mr-1'>
          <p className='font-raleway text-black text-[16px] font-medium'>City</p>
          <input 
            type='text' 
            placeholder='Enter City' 
            className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
            name="city"
            value={newResource?.city}
            onChange={handleInputChange}
          />
        </div>
        <div className='my-4 mr-1'>
          <p className='font-raleway text-black text-[16px] font-medium'>State</p>
          <select 
            name="state" 
            value={newResource?.state} 
            className="border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]"
            onChange={handleInputChange}
          >
            <option value="">-- Select State --</option>
            <option value="nationwide">All</option>
            {
                (usStates && usStates.length > 0) && usStates.map((state, i) => (
                    <option value={state} key={i}>{USStatesEnum[state]?.fullName}</option>
                ))
            }
          </select>
        </div>
        <div className='my-4'>
          <p className='font-raleway text-black text-[16px] font-medium'>Zipcode</p>
          <input 
            type='text' 
            placeholder='Enter Zipcode' 
            className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
            name="zipcode"
            value={newResource?.zipcode}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className='my-4'>
        <AddImageBox 
          name="bgPhoto"
          title="Upload Cover Photo" 
          description="Upload clear images that showcase your expertise. Ensure each image is well-lit, high-quality, and at least 800x600 pixels (JPEG or PNG format)." 
          selectedImage={selectedResourceImage}
          setSelectedImage={setSelectedResourceImage}
          handleChange={handleInputChange}
        />
      </div>

      <div className='flex flex-row justify-between w-11/12 mx-auto mb-4 border-t-[1px] border-[#E9E9E9] py-2'>
        <button className='border-0 font-raleway text-[#4D4D4D]'>Save to Drafts</button>
        <button 
          className='border-0 font-raleway w-[160px] text-white bg-primary-200 p-2 rounded-md'
          onClick={()=>handleAddResource(newResource)}
          disabled={!newResource.name}
        >
            Publish Resource
        </button>
      </div>
    </>
  ) 
}

export default AddResourceForm