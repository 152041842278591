import { useEffect, useState } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ResourceRecord from "../components/ResourceRecord";
// import { useResourceState } from "../contexts/kitchencontext";
import { useLoadResourcesFilter, resourcesPerPage, useLoadResourceOptions, useResourceHook } from '../hooks/kitchenhooks';
import { ResourceSchema, ResourceType, SearchFilterOptionType } from "../data/types";
import { capitalizeString } from "../utils/helpers";
import { AddCircle } from '@mui/icons-material';
// import { useResourceState } from '../contexts/kitchencontext';
import { AddResourceModal } from "./AppModal";
import LoadingScreen from '../pages/shared/LoadingScreen';


type ResourceListProps = {
  resourceType: ResourceType
  isAddingResource: boolean
  searchFilterOptions: SearchFilterOptionType
  setIsAddingResource: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResourceList: React.FC<ResourceListProps> = ({isAddingResource, resourceType, searchFilterOptions, setIsAddingResource}) => {

  const { currentFilter } = searchFilterOptions
  var { filteredResources, getPage, reloadResources, isLoadingResources } = useLoadResourcesFilter(resourceType, currentFilter.state, currentFilter?.active)
  const [currentResourcePage, setCurrentResourcePage] = useState(1)

  const { addResource } = useResourceHook({resourceType, filterState: currentFilter.state, filterActive: currentFilter?.active})
  const handleAddResource = (resource: ResourceSchema) => {
    if(resource && resource.name){
      addResource({
        ...resource,
        resource_type: resourceType
      })
      setIsAddingResource(false);
    }
  }

  var { paginatedResources, numResources, numAllResources, startIndex, endIndex} = getPage(filteredResources, currentResourcePage)

  const { getResourceIconData } = useLoadResourceOptions()
  const resourceIconData = getResourceIconData(resourceType)
  const resourceCategory = resourceIconData.name
  const resourceCategoryDesc = resourceIconData.description
  const ResourceIcon = resourceIconData.Icon

  useEffect(()=>{
    reloadResources()
    // eslint-disable-next-line 
  }, [resourceType])

  return (isLoadingResources) ? (
    <LoadingScreen />
  ) : (
    <>
      <div className="w-full my-2 h-auto ">
        {
          (numResources && numAllResources) ? (
              <div className='flex flex-row w-full text-[#013847] font-raleway text-[14px] font-bold my-auto'>
                <span className='flex flex-row justify-start text-[#8A8A8A] text-[14px] font-light text-center my-auto w-1/2 mx-auto'>
                  {`Showing ${startIndex+1} - ${endIndex} of ${numAllResources}`}
                </span>

                <div className='flex flex-row justify-end my-auto w-1/4' onClick={() => setCurrentResourcePage(prev => (prev > 1) ? prev - 1 : prev)}>
                    <span className={`text-[14px] my-auto ${(currentResourcePage > 1) ? 'visible cursor-pointer':'invisible'}`}><ArrowBackIosIcon style={{fontSize: 10}}  className='my-auto' />&nbsp;Previous</span>
                </div>

                <div className='my-auto w-1/4 flex flex-row justify-end' onClick={() => setCurrentResourcePage(prev => (prev+1 <= Math.ceil(numAllResources/resourcesPerPage) ) ? prev+1 : prev)}>
                    <span className={`text-[14px] my-auto ${(currentResourcePage+1 <= Math.ceil(numAllResources/resourcesPerPage)) ? 'visible cursor-pointer' : 'invisible'}`}>Next&nbsp;<ArrowForwardIosIcon style={{fontSize: 10}} className='my-auto' /></span>
                </div>

              </div>
          
          ) : (<></>)
        }
        <div className="grid grid-cols-2 grid-flow-row flex-wrap gap-10 my-2">
          {paginatedResources && paginatedResources.length !== 0 ? (
            paginatedResources.map((resource: any, i: number) => (
              <ResourceRecord
                key={i}
                resource={resource}
                resourceType={resourceType}
              />
            ))
          ) : (
            
            <div className="flex w-full">
              <div className="flex flex-col mx-auto w-[423px] h-auto bg-white rounded-xl py-4">
                <div className='m-auto w-10/12'>
                  <div className='flex h-[80px] w-[80px] rounded-full bg-[#EFF8FA] mx-auto mb-4'>
                    <ResourceIcon className='m-auto h-[40px] w-[40px] text-primary-400' />
                  </div>
                  <p className='text-[20px] text-primary-200 font-raleway font-bold text-center my-2'>No {resourceCategory}</p>
                  <p className='text-[16px] text-center font-open-sans text-[#333333]'>
                    {resourceCategoryDesc}
                  </p>
                  <button 
                    onClick={()=> setIsAddingResource(true)}
                    className='flex flex-row w-full bg-primary-200 text-white border-0 p-[16px] rounded-lg mt-4 justify-center'
                  >
                    <AddCircle /> &nbsp;
                    Add {capitalizeString(resourceType)} Resources
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <AddResourceModal isOpen={isAddingResource} resourceType={resourceType} handleAddResource={handleAddResource} closeAppModal={()=>setIsAddingResource(false)}/>
    </>
  );
};

export default ResourceList;
