import React, { useState } from 'react'

// import { AddCircle, Cancel } from "@mui/icons-material";
// import { useLoadResourceOptions } from '../../hooks/kitchenhooks';
// import { usStates, USStatesEnum } from "../../utils/enums"
import { useAddInventory, useResourceHook} from "../../hooks/kitchenhooks";
import { InventorySchema, ResourceType, TrainingInventorySchema } from '../../data/types';
import {  MeasurementUnits, InventoryCategories } from "../../utils/enums";
import { AddImageBox } from '../../components/AddImageBox'
// import { ReactComponent as AddImageIcon } from '../../icons/AddImageIcon.svg'
import { ReactComponent as RightArrow } from "../../icons/ScrollRightArrow.svg";
import { ReactComponent as LeftArrow } from "../../icons/ScrollLeftArrow.svg";
import { capitalizeString } from '../../utils/helpers';
import moment from 'moment';


type AddProductFormProps = {
  isModal?: boolean
  resourceCategory: string
  resourceId?: string
  resourceType: ResourceType
  setCurrentInventoryList: React.Dispatch<React.SetStateAction<InventorySchema[]>> | React.Dispatch<React.SetStateAction<TrainingInventorySchema[]>>
  setIsAddingProduct: React.Dispatch<React.SetStateAction<boolean>>
  // handleReloadResource?: () => void
}
export const AddProductForm: React.FC<AddProductFormProps> = ({resourceType, resourceId, resourceCategory, isModal, setIsAddingProduct}) => {

  const emptyInventoryItem: InventorySchema = {
    item_name: "",
    item_description: "",
    total_quantity: 0,
    category: 'equipment',
    measure_unit: 'kg'
  }
  const [newProduct, setNewProduct] = useState<InventorySchema>(emptyInventoryItem)

  const emptyTrainingItem: TrainingInventorySchema = {
    item_name: "",
    item_description: "",
    category: 'schedule',
    unit_price: 0,
    total_quantity: 0, 
    measure_unit: "ea",
    date: "",
    times: [],
    duration: 0,
  }
  const [newTraining, setNewTraining] = useState<TrainingInventorySchema>(emptyTrainingItem)
  const [suggestions, setSuggestions] = useState<string[]>([])
  const { searchInventoryCatalog } = useAddInventory()

  const [selectedProductImage, setSelectedProductImage] = useState<string>(newProduct?.image_url ? newProduct?.image_url : ""); // fix with real schema
  const [currentTime, setCurrentTime] = useState<string>("")

  const { addInventoryItem } = useResourceHook({resourceId})

  const getEnumValue = (key: string): string =>  {
    return MeasurementUnits[key as keyof typeof MeasurementUnits].toLowerCase() as string
  }

  const handleAddTime = async () => {
    if (currentTime && !newTraining.times.includes(currentTime)){
      setNewTraining(prev=> { 
        return (
          {
            ...prev, 
            times: [...prev.times, currentTime]
          }
        )
      })
      setCurrentTime("")
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    e.preventDefault()
    const {name, value, files}: any = e.target
    if (["training", "expert"].includes(resourceType)){
      if (name === "times" && value){
        // Check if the minute is either 00 or 30 / Round to nearest valid time in 30-minute increments
        const selectedTime = moment(value, 'HH:mm');
        const minute = selectedTime.minute();
        if (minute % 30 === 0) {
          setCurrentTime(selectedTime.format('HH:mm'));
        } else {
          const roundedTime = selectedTime.minute(minute < 30 ? 0 : 30).format('HH:mm');
          setCurrentTime(roundedTime);
        }
      } else if (name && (value || value === "")){
        setNewTraining((prev: any)=>(
          {
            ...prev,
            [name]: value
          }
        ))
      }
    } else {
      if (name && files && files.length > 0) {
        let file_data = files[0];
        setNewProduct(prev => ({
          ...prev,
          [name]: file_data,
        }));
      } else if ((name === "unit_price" || name === "total_quantity") && value) {
        setNewProduct((prevInv) => ({
          ...prevInv,
          [name]: Number(value),
        }));
      } else if (name && (value || value === "")){
        if (name === "item_name"){
          handleSuggestion(value)
        }
        setNewProduct((prev: any)=>(
          {
            ...prev,
            [name]: value
          }
        ))
      }
    }
  }

  const handleSuggestion = (query: string) => {
    if (newProduct && query && newProduct?.category){
      let suggestionList = searchInventoryCatalog(newProduct.category, query)
      setSuggestions(suggestionList)
    } else {
      setSuggestions([])
    } 
  }

  const handleSubmit = () => {
    if (["training", "expert"].includes(resourceType)){
      if (newTraining !== undefined && resourceId){
        addInventoryItem(resourceId, newTraining)
        // handleReloadResource()
      } 
      // else {
      //   setCurrentInventoryList((prev: any) => (
      //     [
      //       newTraining,
      //       ...prev
      //     ]
      //   ))
      // }
      setIsAddingProduct(false)

    } else if (newProduct && newProduct.item_name){
      if(newProduct !== undefined && resourceId){
        addInventoryItem(resourceId, newProduct)
        // handleReloadResource()
      } 
      // else {
      //   setCurrentInventoryList((prev: any) => (
      //     [
      //       newProduct,
      //       ...prev
      //     ]
      //   ))
      // }
      setIsAddingProduct(false)
    }
  }

  return (["training", "expert"].includes(resourceType)) ? (
    <>
      {        
        (!isModal) ? (
          <button className='border-0 font-raleway hover:text-primary-200 text-primary-300 mr-auto' onClick={()=> setIsAddingProduct(false)}>Back</button>
        ) : (
          <></>
        )
      }
      <p className='text-black text-[18px] font-bold font-raleway my-1'>Add {resourceCategory} Schedule</p>
      <p className='text-[#4D4D4D] text-[16px] font-light font-open-sans my-1'>Fill out the details below to add your culinary training resource.</p>
    
      <div className="flex flex-col my-4 w-full">
        <p className='font-raleway text-black text-[16px] font-medium'>Add Date</p>
        <input 
          type='date' 
          className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px] font-raleway text-[#808080]'
          name="date"
          value={newTraining.date}
          onChange={handleInputChange}
        />
      </div>

      <div className="flex flex-col my-4 w-full">
        <p className='font-raleway text-black text-[16px] font-medium'>Add Time</p>
        <div className='flex flex-row justify-between'>
          <input 
            type='time' 
            className='border-[#E9E9E9] border-[1px] w-10/12 rounded-md h-[50px] text-[16px] font-raleway text-[#808080]'
            name="times"
            step="1800" 
            min="00:00" 
            max="23:30"
            value={currentTime}
            onChange={handleInputChange}
          />

          <button onClick={handleAddTime} className='rounded-full p-2 bg-primary-200 text-white h-10 w-10 m-1 font-bold'>+</button>
        </div>
        {
          (newTraining?.times && newTraining?.times?.length > 0) && (
            <div className='flex flex-row font-open-sans border-t-[1px] border-b-[1px] border-[#E9E9E9] h-[70px] my-4 w-11/12 '>
              {
                newTraining.times?.map((time, i)=> (
                    <p key={i} className='flex flex-col text-center my-auto mr-6'>
                        <b>Option {i+1}</b>
                        <span className="p-1 bg-primary-300 rounded-md text-white my-1">{moment(time, 'HH:mm').format('hh:mm a')}</span>
                    </p>
                ))
              }
            </div>
          )
        }
      </div>

      <div className='flex flex-col my-4 w-full'>
        <span className="font-raleway text-black text-[16px] font-medium">
          Duration (hours)
        </span>
        <label className="flex flex-row text-primary-200 border-[1px] border-[#E9E9E9] h-[50px] w-full rounded-lg">
          <input
            placeholder="0"
            className="border-0 bg-inherit w-full my-1 font-raleway text-[#808080]"
            name="duration"
            type="number"
            value={newTraining.duration}
            step={1}
            min={0}
            onChange={handleInputChange}
          />

          <div
            className='rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer my-auto m-1'
          >
            <LeftArrow className=" text-black rounded-full  m-auto" />
          </div>
          <div
            className='rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer my-auto m-1'
          >
              <RightArrow className="text-black rounded-full  my-auto" />
          </div>
        </label>
      </div>

      <div className='flex flex-col my-4 w-full'>
        <span className="font-raleway text-black text-[16px] font-medium">
          Capacity
        </span>
        <label className="flex flex-row text-primary-200 border-[1px] border-[#E9E9E9] h-[50px] w-full rounded-lg">
          <input
            placeholder="Enter the maximum number of participants"
            className="border-0 bg-inherit w-full my-1 font-raleway text-[#808080]"
            name="total_quantity"
            type="number"
            value={newTraining.total_quantity}
            step={1}
            min={0}
            onChange={handleInputChange}
          />

          <div
            className='rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer my-auto m-1'
          >
            <LeftArrow className=" text-black rounded-full  m-auto" />
          </div>
          <div
            className='rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer my-auto m-1'
          >
              <RightArrow className="text-black rounded-full  my-auto" />
          </div>
        </label>
      </div>

      <div className='flex flex-row text-[16px] justify-between w-11/12 mx-auto mb-4 border-t-[1px] border-[#E9E9E9] py-2'>
        <button 
          className='border-0 font-raleway w-[160px] text-white bg-black p-2 rounded-md ml-auto'
          onClick={handleSubmit}
        >
            Add Schedule
        </button>
      </div>
    </>
  ) : (
      <>
        {        
          (!isModal) ? (
            <button className='border-0 font-raleway hover:text-primary-200 text-primary-300 mr-auto' onClick={()=> setIsAddingProduct(false)}>Back</button>
          ) : (
            <></>
          )
        }
        <p className='text-black text-[18px] font-bold font-raleway my-1'>Add {resourceCategory} Product</p>
        <p className='text-[#4D4D4D] text-[16px] font-light font-open-sans my-1'>Fill in the details below to add your product</p>

    
        <div className="flex flex-col text-primary-200 my-2">
          <span className="font-raleway text-black text-[16px] font-medium">
            Product Category 
            {/* <span className='text-red-500 text-sm mx-2'>*</span> */}
          </span>
          <select
            name="category"
            value={newProduct.category}
            onChange={handleInputChange}
            className="font-open-sans text-[#808080] border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]"
          >
            <option value="" disabled>-- Category --</option>
            {Object.keys(InventoryCategories).map((category, i) => (
              <option key={i} value={category}>
                {capitalizeString(category)}
              </option>
            ))}
          </select>
        </div>
        <div className='my-2 mr-1'>
          <p className='font-raleway text-black text-[16px] font-medium'>Product Name</p>
          <input 
            type='text' 
            placeholder='Enter your product name' 
            className='font-open-sans text-[#808080] border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
            name="item_name"
            value={newProduct?.item_name}
            onChange={handleInputChange}
          />
          {
            (suggestions && suggestions.length > 0 && newProduct?.item_name) && 
            (
              <div className="bg-white h-auto w-[540px]">
              {
                suggestions.slice(0, 3).map((suggestion, i) => (
                    <p 
                      key={i} 
                      className="font-normal text-[16px] text-primary-200 cursor-pointer mb-1 p-1 bg-gray-100/80"
                      onClick={()=> {
                        setNewProduct(prev => ({...prev, item_name: suggestion}))
                        handleSuggestion("")
                      }}
                      >
                      {suggestion}
                    </p>
                  )
                )
              }
              </div>
            )
          }
        </div>

        <div className='my-2'>
          <p className='font-raleway text-black text-[16px] font-medium'>Description</p>
          <textarea 
            placeholder='Provide a brief description of this resource and what it offers.' 
            className='font-open-sans text-[#808080] border-[#E9E9E9] border-[1px] w-full rounded-md h-[120px] text-[16px]'
            name="item_description"
            value={newProduct?.item_description}
            onChange={handleInputChange}
          />
        </div>

        <div className="grid grid-flow-row grid-cols-3 space-x-2 my-2">
          <div className="flex flex-col">
            <span className="flex flex-row text-[#808080] text-[16px] font-raleway my-1">
              Unit Price ($) 
            </span>
            <div className="flex flex-row justify-between">
              <label className="flex flex-row text-primary-200 border-[1px] border-[#E9E9E9] h-[50px] rounded-lg">
                <input
                  placeholder="0.00"
                  className="font-open-sans text-[#808080] border-0 bg-inherit w-full my-1 "
                  name="unit_price"
                  type="number"
                  value={newProduct?.unit_price}
                  step={0.01}
                  min={0}
                  onChange={handleInputChange}
                />

                <div
                  className='rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer my-auto m-1'
                >
                  <LeftArrow className=" text-black rounded-full  m-auto" />
                </div>
                <div
                  className='rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer my-auto m-1'
                >
                    <RightArrow className="text-black rounded-full  my-auto" />
                </div>
              </label>
            </div>
          </div>
          <div className="flex flex-col">
            <span className="flex flex-row text-[#808080] text-[16px] font-raleway my-1">
              Stock Quantity
            </span>
            <div className="flex flex-row justify-between">
              <label className="flex flex-row text-primary-200 border-[1px] border-[#E9E9E9] h-[50px] rounded-lg">
                <input
                  placeholder="0"
                  className="font-open-sans text-[#808080] border-0 bg-inherit w-full my-1 "
                  name="total_quantity"
                  value={newProduct?.total_quantity}
                  type="number"
                  step={1}
                  min={0}
                  onChange={handleInputChange}
                />

                <div
                  className='rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer my-auto m-1'
                >
                  <LeftArrow className=" text-black rounded-full  m-auto" />
                </div>
                <div
                  className='rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer my-auto m-1'
                >
                    <RightArrow className="text-black rounded-full  my-auto" />
                </div>
              </label>
            </div>
          </div>

          <div className="flex flex-col">
            <span className="flex flex-row text-[#808080] text-[16px] font-raleway my-1">
              Measurement Unit <span className='text-red-900 text-sm mx-2'>*</span>
            </span>
            <select
              name="measure_unit"
              value={newProduct.measure_unit}
              onChange={handleInputChange}
              className="font-open-sans text-[#808080] w-full border-[1px] border-[#E9E9E9] h-[50px]" 
            >
              <option value="" disabled>-- Unit --</option>
              {Object.keys(MeasurementUnits).map((measureUnit, i) => (
                <option key={i} value={measureUnit}>
                  {capitalizeString(getEnumValue(measureUnit))} ({measureUnit})
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className='my-4 font-open-sans text-[#808080]'>
          <AddImageBox 
            name="image_url"
            title="Product Images" 
            description="Upload clear images of your product" 
            selectedImage={selectedProductImage}
            setSelectedImage={setSelectedProductImage}
            handleChange={handleInputChange}
          />
        </div>
      
        <div className='flex flex-row text-[16px] justify-between w-11/12 mx-auto mb-4 border-t-[1px] border-[#E9E9E9] py-2'>
          <button 
            className='border-0 font-raleway w-[160px] text-white bg-black p-2 rounded-md ml-auto'
            onClick={handleSubmit}
          >
              Add Product
          </button>
        </div>
      </>
  ) 
}