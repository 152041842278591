import React, { useEffect, useState } from "react";
import moment from "moment";
import { states, MeasurementUnits, InventoryCategories } from "../utils/enums";
import { 
  DSCommunitySchema, CredentialSchema, KitchenSpaceSchema, 
  PlanSchema, InventorySchema, ResourceType, ResourceSchema, RequirementSchema, 
  ResourceOptionsPropsType, RecurrenceOptions, selectedDaysType, ResourceOptionType } from "../data/types";
import { useResourceState } from "../contexts/kitchencontext";

import { AddCircle, CheckBox, CheckBoxOutlineBlank, CheckCircle, Cancel, RadioButtonUnchecked, RadioButtonChecked } from "@mui/icons-material";

import StartBooking from "../components/StartBooking";
import CreateMembership from "../components/CreateMembership";
import AddMessageList from "../molecules/addMessageList";
import LoadingScreen from "../pages/shared/LoadingScreen"
import { ReactComponent as Avatar } from "../icons/ChatbotIcon.svg";
import { ReactComponent as InfoIcon } from '../icons/InfoIcon.svg'
import { ReactComponent as UploadIcon } from '../icons/UploadIcon.svg'
// import { ReactComponent as AddImageIcon } from '../icons/AddImageIcon.svg'
import { ReactComponent as RightArrow } from "../icons/ScrollRightArrow.svg";
import { ReactComponent as LeftArrow } from "../icons/ScrollLeftArrow.svg";

import { useMemberState } from "../contexts/membercontext";
import { useLoadMessages } from "../hooks/memberhooks";
import { useAddInventory, useLoadPlans, useResourceHook } from "../hooks/kitchenhooks";
import { maxHours, styleThousandCommas, capitalizeString, capitalizeWords, getChangedAttributes } from "../utils/helpers";
import { usStates, USStatesEnum } from "../utils/enums";
import { CustomAddImageBox } from "../components/AddImageBox";
import OperatingHoursList from "./OperatingHoursList";
// import { useLoadCommunity } from "../hooks/authhooks";


// TO BE LOADED FROM COMMUNITIES / REQUIRED DOCS
// const credentialCategories = ["ID", "license", "certificate", "insurance", "other"]


// const capitalizeString = (string: string) => string?.replace(/\w/, c => c?.toUpperCase())

type AddCredentialFormProps = {
  handleClose: () => void;
  handleSubmitCredential: (credential: CredentialSchema) => void;
};

const AddCredentialForm: React.FC<AddCredentialFormProps> = (props) => {
  var { handleSubmitCredential, handleClose } = props;
  var { requirements } = useResourceState();

  const [credential, setCredential] = useState<CredentialSchema>(
    {} as CredentialSchema
  );
  const [selectedCategory, setSelectedCategory] = useState<string>("");

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedCategory(event.target.value);
    setCredential((prevCred: CredentialSchema) => ({
      ...prevCred,
      category: event.target.value,
    }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;
    if (name && files && files.length > 0) {
      let file_data = files[0];
      setCredential((prevCredential: CredentialSchema) => ({
        ...prevCredential,
        [name]: file_data,
      }));
    }
  };

  const handleSubmit = () => {
    if (credential) {
      handleSubmitCredential(credential);
      handleClose();
    }
  };

  return (
    <div className="flex flex-col justify-evenly items-center my-10">
      <button
        className="absolute top-0 left-0 p-2 text-gray-600 hover:text-gray-800 "
        onClick={handleClose}
      />
      <div className="flex flex-col mx-auto">
        <img src="/logo192.png" alt="homecook-logo" className="w-[110px] h-[82px] self-center"/>
        <h1 className="text-2xl text-primary-200 text-center">Add Credentials</h1>
        <h1 className="text-base text-back-200 text-center mb-5">Choose Credential Requirement</h1>
      </div>
      <div className="w-full grid grid-flow-row gap-y-3">
        <select
          value={selectedCategory}
          onChange={handleCategoryChange}
          className="formInput text-slate-500 bg-inherit w-max mx-auto h-full"
        >
          <option value="" className="text-slate-400">
            -- Requirement --
          </option>
          {requirements &&
            requirements.map((c, i) => (
              <option key={i} value={c.name}>
                {c.name}
              </option>
            ))}
        </select>

        <div className="flex flex-col mx-auto">
          <label className="text-slate-500 w-max text-left">Upload File</label>
          <input
            type="file"
            placeholder="Upload File"
            className="formInput m-1 w-max mx-auto h-full bg-inherit mb-5"
            name="file"
            onChange={handleFileChange}
          />
        </div>
        <button
          className="buttonsPrimary h-10 w-1/3 mx-auto text-xl"
          onClick={handleSubmit}
          data-testid="submit-button"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

type AddKitchenFormProps = {
  handleClose: () => void;
  handleAddKitchen: (kitchen: KitchenSpaceSchema | {}) => void;
};

const AddKitchenForm: React.FC<AddKitchenFormProps> = (props) => {
  var { handleAddKitchen, handleClose } = props;
  const [selectedState, setSelectedState] = useState<string>("");
  const [kitchen, setKitchen] = useState<KitchenSpaceSchema | {}>({});
  const [width, setWidth] = useState<string>("");
  const [length, setLength] = useState<string>("");

  const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedState(event.target.value);
    setKitchen((prevKitchen) => ({
      ...prevKitchen,
      state: event.target.value,
    }));
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setKitchen((prevKitchen) => ({
      ...prevKitchen,
      [name]: value,
    }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;
    if (name && files && files.length > 0) {
      let file_data = files[0];
      setKitchen((prevKitchen) => ({
        ...prevKitchen,
        [name]: file_data,
      }));
    }
  };

  const handleLengthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setLength(value);
  };

  const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setWidth(value);
  };

  const handleSubmit = () => {
    var size = `0x0`;
    if (length && width && kitchen) {
      size = `${length}x${width}`;
      const kitchenObj = kitchen as DSCommunitySchema;
      kitchenObj.size = size;
      setKitchen(kitchenObj);
    }
    handleAddKitchen(kitchen);
  };

  return (
    <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 md:justify-center items-center md:my-20 md:gap-x-2 m-10 md:m-0">
      <button
        className="absolute top-0 left-0 p-2 text-gray-600 hover:text-gray-800 "
        onClick={handleClose}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div className="text-center md:w-1/3 pr-16 ml-24 md:ml-0">
        <h1 className="text-4xl text-primary-400">Add Kitchen</h1>
        <p className="text-xl text-black">
          Please fill in the following information
        </p>
      </div>
      <div className="w-full md:w-1/2  grid grid-flow-row gap-y-5">
        <input
          placeholder="Kitchen Name"
          name="name"
          className="formInput "
          onChange={handleInputChange}
        />
        <div className="grid grid-cols-4 gap-x-5">
          <input
            placeholder="Address"
            className=" col-span-2"
            name="address"
            onChange={handleInputChange}
          />
          <input
            placeholder="City"
            className="formInput"
            name="city"
            onChange={handleInputChange}
          />
          <input
            placeholder="County"
            className="formInput"
            name="county"
            onChange={handleInputChange}
          />
        </div>
        <div className="grid grid-cols-3 gap-x-5">
          {/* <input placeholder="Country" className="form-input"  name="country"
          onChange={handleInputChange} /> */}
          <select
            value={selectedState}
            onChange={handleStateChange}
            className="formInput text-slate-500"
          >
            <option value="">Select a state</option>
            {Object.values(states).map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
          <input
            placeholder="Zipcode"
            className="formInput"
            name="zipcode"
            onChange={handleInputChange}
          />
        </div>
        <div className="grid ">
          <label className="text-slate-500">Background Image</label>
          <input
            type="file"
            placeholder="Background Image URL"
            className="form-input"
            name="bgPhoto"
            onChange={handleFileChange}
          />
          <label className="text-slate-500">Logo</label>
          <input
            type="file"
            placeholder="Logo URL"
            className="form-input"
            name="logo"
            onChange={handleFileChange}
          />
        </div>
        <input
          placeholder="Website"
          className="formInput"
          name="website"
          onChange={handleInputChange}
        />
        <div className="grid grid-cols-2 gap-x-5">
          <div className="grid grid-cols-2 gap-x-5">
            <input
              placeholder="Kitchen Width"
              className="formInput"
              name="width"
              onChange={handleWidthChange}
            />
            <input
              placeholder="Kitchen Length"
              className="formInput"
              name="length"
              onChange={handleLengthChange}
            />
          </div>
          <input
            placeholder="Kitchen Capacity"
            className="formInput"
            name="capacity"
            onChange={handleInputChange}
          />
        </div>
        <textarea
          placeholder="Description"
          className="formInput"
          name="description"
          onChange={handleInputChange}
        />
        <button
          className="buttonsPrimary h-10 text-xl"
          onClick={handleSubmit}
          data-testid="submit-button"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

type AddPlanProps = {
  resource?: KitchenSpaceSchema | ResourceSchema
  handleClose: () => void;
  handleAddPlan: (plan: PlanSchema | {}) => void;
};

const AddPlanForm: React.FC<AddPlanProps> = (props) => {
  var { resource, handleClose, handleAddPlan } = props;
  const recurrences: RecurrenceOptions[] = ["hourly", "daily", "weekly", "monthly", "annual"];
  const [selectedRecurrence, setSelectedRecurrence] = useState<string | RecurrenceOptions>("");

  const emptyPlan: PlanSchema = {
    plan_description: "",
    plan_name: "",
    plan_rate: 0,
    plan_recurrence: "hourly",
    plan_max_hours: 0
  }

  const [plan, setPlan] = useState<PlanSchema>(emptyPlan);

  // const handleRecurrenceChange = async (
  //   event: React.ChangeEvent<HTMLSelectElement>
  // ) => {
  //   const {name, value} = event.target
  //   setSelectedRecurrence(value);
  //   setPlan((prevPlan) => ({
  //     ...prevPlan,
  //     [name]: value,
  //     plan_max_hours: maxHours((resource?.capacity) ? parseInt(resource.capacity) : 1, value)
  //   }));
  // };

  const handleRecurrenceChange = (recurrence: RecurrenceOptions) => {
    setSelectedRecurrence(recurrence);
    setPlan((prevPlan) => ({
      ...prevPlan,
      'plan_recurrence': recurrence,
      plan_max_hours: maxHours((resource?.capacity) ? parseInt(resource.capacity) : 1, recurrence)
    }));
  };


  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    if (name === "rate" || name === "plan_max_hours") {
      setPlan((prevPlan) => ({
        ...prevPlan,
        [name]: Number(value),
      }));
    } else {
      setPlan((prevPlan) => ({
        ...prevPlan,
        [name]: value,
      }));
    }
    // const { name, value } = event.target;
  };

  // const capitalizeString = (string: string) => string?.replace(/\w/, c => c?.toUpperCase())

  return (
    <div className="flex flex-col justify-evenly items-center h-full">
      <span onClick={handleClose} className="ml-auto w-full flex cursor-pointer">
          <Cancel className="text-[#A1A1A1] hover:text-[#777] h-[18px] w-[18px] ml-auto" />
      </span>
      <div className="flex flex-col w-11/12">
        <h1 className="text-[18px] text-black font-raleway font-bold text-left my-2">Add Custom Plan</h1>
        <h1 className="text-[16px] font-open-sans text-[#4D4D4D] text-left mb-2">Fill in the details below to add your community plan.</h1>
      </div>
      <div className="font-open-sans w-11/12  grid grid-flow-row gap-y-5">
        <label className="text-primary-200 text-xs">
          <span className="flex flex-row text-[#808080] text-[16px] font-raleway my-1">Plan Name 
            {/* <InfoIcon className="h-3 w-3 my-auto mx-1 text-primary-300" /> */}
          </span>
          <input
            id="plan_name"
            placeholder="Name"
            className="border-[1px] border-[#E9E9E9] bg-inherit w-full my-1 rounded-lg"
            name="plan_name"
            onChange={handleInputChange}
          />
        </label>

        <label className="text-primary-200  text-xs">
          <span className="flex flex-row text-[#808080] text-[16px] font-raleway my-1">
            Plan Description 
          </span>
          <input
            placeholder="Description"
            className="border-[1px] border-[#E9E9E9] bg-inherit w-full my-1 rounded-lg"
            name="plan_description"
            onChange={handleInputChange}
          />
        </label>

        <label className="flex flex-col text-primary-200 text-xs">
          <span className="flex flex-row text-[#808080] text-[16px] font-raleway my-1">
            Frequency
            {/* <InfoIcon className="h-3 w-3 my-auto mx-1 text-primary-300 cursor-pointer" /> */}
          </span>

          <span className="flex flex-row my-1 w-full">
            {
              Object.values(recurrences).map((recurrence) => (
                (selectedRecurrence===recurrence) ? (
                <span 
                  key={recurrence} 
                  className={`flex flex-row text-white font-open-sans text-[16px] mr-2 bg-primary-200 border-0 p-2 rounded-md w-auto cursor-pointer`}
                >
                  <RadioButtonChecked className="text-white my-auto" />
                  <p className="my-auto mx-1">{capitalizeString(recurrence)}</p>
                </span>
                ) : (
                  <span 
                    key={recurrence} 
                    className={`flex flex-row text-black font-open-sans text-[16px] mr-3 border-[1px] border-[#E9E9E9] p-2 rounded-md w-auto cursor-pointer`}
                    onClick={()=>handleRecurrenceChange(recurrence)}
                  >
                    <RadioButtonUnchecked className="text-[#CFCFCF] my-auto" />
                    <p className="my-auto mx-1">{capitalizeString(recurrence)}</p>
                  </span>
                )
              ))
            }
          </span>

          
          {/* <select
            name="plan_recurrence"
            value={selectedRecurrence}
            onChange={handleRecurrenceChange}
            className="border-[1px] border-[#E9E9E9] text-slate-500 bg-inherit w-full my-1 rounded-lg"
          >
            <option value="">-- Recurrence --</option>
            {Object.values(recurrences).map((recurrence) => (
              <option key={recurrence} value={recurrence}>
                {capitalizeString(recurrence)}
              </option>
            ))}
          </select> */}
        </label>



        <div className="grid grid-flow-row grid-cols-3 space-x-2">
          <div className="flex flex-col">
            <span className="flex flex-row text-[#808080] text-[16px] font-raleway my-1">
              Plan Rate ($) 
            </span>
            <div className="flex flex-row justify-between">
              <label className="flex flex-row text-primary-200 border-[1px] border-[#E9E9E9] h-[50px] rounded-lg">
                <input
                  placeholder="0.00"
                  className="border-0 bg-inherit w-full my-1 "
                  name="plan_rate"
                  type="number"
                  step={0.01}
                  min={0}
                  onChange={handleInputChange}
                />

                <div
                  className='rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer my-auto m-1'
                >
                  <LeftArrow className=" text-black rounded-full  m-auto" />
                </div>
                <div
                  className='rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer my-auto m-1'
                >
                    <RightArrow className="text-black rounded-full  my-auto" />
                </div>
              </label>
            </div>
          </div>

          {
            (selectedRecurrence && (!['hourly', 'daily'].includes(selectedRecurrence))) ? (
              <>
                <div className="flex flex-col">
                  <span className="flex flex-row text-[#808080] text-[16px] font-raleway my-1">
                    Maximum Hours 
                    {/* / Member (capacity: ~{resource?.capacity ? resource?.capacity : 0}) */}
                  </span>
                  <label className="flex flex-row text-primary-200 border-[1px] border-[#E9E9E9] h-[50px] rounded-lg">
                    <input
                      // placeholder={`${styleThousandCommas(maxHours(kitchen?.capacity ? parseInt(kitchen.capacity) : 1, selectedRecurrence))} hours`}
                      className="border-0 bg-inherit w-full my-1"
                      name="plan_max_hours"
                      type="number"
                      disabled
                      // value={maxHours(kitchen?.capacity ? parseInt(kitchen.capacity) : 1, selectedRecurrence)}
                      value={styleThousandCommas(maxHours(resource?.capacity ? parseInt(resource.capacity) : 1, selectedRecurrence))}
                      onChange={handleInputChange}
                    />

                    <div
                      className='rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer my-auto m-1'
                    >
                      <LeftArrow className=" text-black rounded-full  m-auto" />
                    </div>
                    <div
                      className='rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer my-auto m-1'
                    >
                        <RightArrow className="text-black rounded-full  my-auto" />
                    </div>
                  </label>
                </div>

                <div className="flex flex-col">
                  <span className="flex flex-row text-[#808080] text-[16px] font-raleway my-1">
                  Member Capacity
                  </span>
                  <label className="flex flex-row text-primary-200 border-[1px] border-[#E9E9E9] h-[50px] rounded-lg">
                    <input
                      className="border-0 bg-inherit w-full my-1"
                      name="plan_max_hours"
                      type="number"
                      disabled
                      value={resource?.capacity ? resource?.capacity : 0}
                      onChange={handleInputChange}
                    />

                    <div
                      className='rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer my-auto m-1'
                    >
                      <LeftArrow className=" text-black rounded-full  m-auto" />
                    </div>
                    <div
                      className='rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer my-auto m-1'
                    >
                        <RightArrow className="text-black rounded-full  my-auto" />
                    </div>
                  </label>
                </div>
              </>
            ) : (
              <>
              </>
            )
          }
        </div>

        <button
          className="border-0 bg-primary-200 text-white font-raleway rounded-md p-2 text-[18px] w-[125px] h-[54px] ml-auto"
          onClick={() => {
            handleAddPlan(plan)
            setPlan({})
          }}
          data-testid="submit-button"
        >
          Add Plan
        </button>
      </div>
    </div>
  );
};

type AddRequirementProps = {
  handleClose: () => void;
  handleAddRequirement: (kitchen: DSCommunitySchema | {}) => void;
};

const AddRequirementForm: React.FC<AddRequirementProps> = (props) => {
  var { handleAddRequirement, handleClose } = props;

  const [requirement, setRequirement] = useState({
    // description: "",
    name: "",
    validity: "",
  });

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    setRequirement((prevReq) => ({
      ...prevReq,
      [name]: value,
    }));
  };

  return (
    <div className="flex flex-col justify-evenly items-center h-full">
      <span onClick={handleClose} className="ml-auto w-full flex cursor-pointer">
          <Cancel className="text-[#A1A1A1] hover:text-[#777] h-[18px] w-[18px] ml-auto" />
      </span>

      <div className="font-open-sans w-11/12  grid grid-flow-row gap-y-5 mt-4">
        <label className="text-primary-200 text-xs">
          <span className="flex flex-row text-[#808080] text-[16px] font-raleway my-1">Requirement
          </span>
          <input
            id="name"
            placeholder="Enter your requirement"
            className="border-[1px] border-[#E9E9E9] bg-inherit w-full my-1 rounded-lg"
            name="name"
            onChange={handleInputChange}
          />
        </label>
        <button
          className="border-0 bg-primary-200 text-white font-raleway rounded-md p-2 text-[18px] w-1/3 h-[54px] ml-auto"
          onClick={() => handleAddRequirement(requirement)}
          data-testid="submit-button"
        >
          Add Requirement
        </button>
      </div>
    </div>
  );
};


type AddInventoryFormProps = {
  kitchen?: KitchenSpaceSchema
  handleClose: () => void;
  handleAddInventory: (inventory: InventorySchema) => void;
};

const AddInventoryForm: React.FC<AddInventoryFormProps> = (props) => {
  var { kitchen, handleAddInventory } = props;
  // const recurrences = ["hourly", "daily", "weekly", "monthly", "annual"];
  // const measureUnits = ["hourly", "daily", "weekly", "monthly", "annual"];
  // const [selectedRecurrence, setSelectedRecurrence] = useState<'hourly' | 'daily' | 'weekly' | 'monthly' | 'annual'>();

  const emptyInventory: InventorySchema = {
    item_name: "",
    total_quantity: 0,
    category: 'equipment',
    measure_unit: 'kg'
  }

  const [inventory, setInventory] = useState<InventorySchema>(emptyInventory);
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>(emptyInventory.category);
  const [selectedMeasureUnit, setSelectedMeasureUnit] = useState<string>(emptyInventory.measure_unit);
  const { searchInventoryCatalog } = useAddInventory()

  const [suggestions, setSuggestions] = useState<string[]>([])



  const handleImageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      // Read the selected image file as a data URL
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImage("");
    }
    handleFileChange(event);
  };


  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    if (name === "unit_price" || name === "total_quantity") {
      setInventory((prevInv) => ({
        ...prevInv,
        [name]: Number(value),
      }));
    } else {
      setInventory((prevInv) => ({
        ...prevInv,
        [name]: value,
      }));
    }
    if (name === "item_name" && value){
      handleSuggestion(value)
    }
  };

  const handleSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    // console.log("{ name, value }", { name, value })
    setInventory((prevInv) => ({
      ...prevInv,
      [name]: value,
    }));

    if(name === 'category' ){
      setSelectedCategory(value)
    } else if(name === 'measure_unit' ){
      setSelectedMeasureUnit(value)
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;
    if (name && files && files.length > 0) {
      let file_data = files[0];
      setInventory((prevInv) => ({
        ...prevInv,
        [name]: file_data,
      }));
    }
  };

  const getEnumValue = (key: string): string =>  {
    return MeasurementUnits[key as keyof typeof MeasurementUnits].toLowerCase() as string
  }



  const handleSuggestion = (query: string) => {
    if (inventory && query && inventory?.category){
      let suggestionList = searchInventoryCatalog(inventory.category, query)
      setSuggestions(suggestionList)
    } else {
      setSuggestions([])
    }
    
  }

  return (
    <div className="flex flex-col space-y-2 md:justify-center items-center md:gap-x-2 m-10 md:mx-0">
      <div className="flex flex-col">
        <img src="/logo192.png" alt="homecook-logo" className="w-[110px] h-[82px] self-center"/>
        <h1 className="text-2xl text-primary-200 text-center">Add Inventory Item</h1>
        <h1 className="text-base text-back-200 text-center">Enter information below</h1>

      </div>
      <div className="font-open-sans w-full px-5  grid grid-flow-row gap-y-5">
        <label className="text-primary-200 text-xs">
          <span className="flex flex-row font-bold">Item Name <span className='text-red-900 text-sm mx-2'>*</span>
            {/* <InfoIcon className="h-3 w-3 my-auto mx-1 text-primary-300" /> */}
          </span>
          <input
            id="item_name"
            placeholder="Name"
            className="formInput bg-inherit w-full my-1"
            name="item_name"
            value={inventory.item_name}
            onChange={handleInputChange}
          />
          {
            (suggestions && suggestions.length > 0 && inventory?.item_name) && 
            (
              <div className="bg-gray-200 h-auto w-auto fixed">
              {
                suggestions.slice(0, 3).map((suggestion, i) => (
                    <p 
                      key={i} 
                      className="font-normal text-[16px] text-primary-300 cursor-pointer mb-1 p-1 bg-gray-200"
                      onClick={()=> {
                        setInventory(prev => ({...prev, item_name: suggestion}))
                        handleSuggestion("")
                      }}
                      >
                      {suggestion}
                    </p>
                  )
                )
              }
              </div>
            )
          }
        </label>

        <label className="flex flex-col text-primary-200 text-xs">
          <span className="flex flex-row font-bold">
            Item Category <span className='text-red-900 text-sm mx-2'>*</span>
            <InfoIcon className="h-3 w-3 my-auto mx-1 text-primary-300 cursor-pointer" />
          </span>
          <select
            name="category"
            value={selectedCategory}
            onChange={handleSelectChange}
            className="formInput text-slate-500 bg-inherit w-full my-1"
          >
            <option value="" disabled>-- Category --</option>
            {Object.keys(InventoryCategories).map((category, i) => (
              <option key={i} value={category}>
                {capitalizeString(category)}
              </option>
            ))}
          </select>
        </label>

        <div className="flex flex-row justify-between">
          <label className="text-primary-200 text-xs w-3/5">
            <span className="flex flex-row font-bold">
              Item Quantity <span className='text-red-900 text-sm mx-2'>*</span>
              {/* <InfoIcon className="h-3 w-3 my-auto mx-1 text-primary-300" /> */}
            </span>
            <input
              // placeholder="Quantity"
              className="formInput bg-inherit w-full my-1"
              id="total_quantity"
              name="total_quantity"
              type="number"
              step={1}
              min={0}
              onChange={handleInputChange}
            />
          </label>

          <label className="flex flex-col text-primary-200 text-xs w-2/6 ">
            <span className="flex flex-row font-bold">
              Measurement Unit <span className='text-red-900 text-sm mx-2'>*</span>
              <InfoIcon className="h-3 w-3 my-auto mx-1 text-primary-300 cursor-pointer" />
            </span>
            <select
              name="measure_unit"
              value={selectedMeasureUnit}
              onChange={handleSelectChange}
              className="formInput text-slate-500 bg-inherit w-full my-1"
            >
              <option value="" disabled>-- Unit --</option>
              {Object.keys(MeasurementUnits).map((measureUnit, i) => (
                <option key={i} value={measureUnit}>
                  {capitalizeString(getEnumValue(measureUnit))} ({measureUnit})
                </option>
              ))}
            </select>
          </label>
        </div>

        <label className="text-primary-200 text-xs">
          <span className="flex flex-row font-bold">
            Unit Price (USD) <span className='text-red-900 text-sm mx-2'>*</span>
            <InfoIcon className="h-3 w-3 my-auto mx-1 text-primary-300 cursor-pointer" />
          </span>
          <input
            placeholder="Rate ($)"
            className="formInput bg-inherit w-full my-1"
            name="unit_price"
            type="number"
            step={0.01}
            min={0}
            onChange={handleInputChange}
          />
        </label>

        <label className="text-primary-200 text-xs">
          <span className="flex flex-row font-bold">
            Item Image 
            <InfoIcon className="h-3 w-3 my-auto mx-1 text-primary-300 cursor-pointer" />
          </span>
          <div className="flex flex-col border-primary-200 border-[1px] rounded-xl text-center my-1 p-1 cursor-pointer">
            {
                (selectedImage) ?  (
                    <img
                      id="image-input-logo"
                      src={selectedImage}
                      alt="Kitchen Logo"
                      className="relative m-auto max-w-sm max-h-32"
                  />) : (<>
                  <UploadIcon className="text-primary-200 hover:text-primary-300 m-auto" />
                  Choose File 
                  </>)
            }
            <input 
              id="image_url"
              name="image_url" 
              type="file" 
              className="invisible" 
              onChange={handleImageChange}
            />
          </div>
        </label>

        <button
          className="buttonsPrimary h-10 text-xl"
          onClick={() => {
            if (kitchen?.id){
              inventory.resource_id = kitchen.id
            }
            handleAddInventory(inventory)
            setInventory(emptyInventory)
          }}
          data-testid="submit-button"
          disabled={!inventory?.item_name || !inventory?.category || !inventory?.total_quantity|| !inventory?.measure_unit || !inventory?.unit_price}
        >
          Add
        </button>
      </div>
    </div>
  );
};

type AddMemberFormProps = {
  isOpen: boolean
  handleClose: () => void;
  handleAddMember: (kitchen: DSCommunitySchema | {}) => void;
  
};

const AddMemberForm: React.FC<AddMemberFormProps> = (props) => {
  return <CreateMembership isOpen={props.isOpen} handleClose={props.handleClose}/>;
};

type AddReservationFormProps = {
  handleClose: () => void;
  isOpen?: boolean
};

const AddReservationForm: React.FC<AddReservationFormProps> = (props) => {
  return <StartBooking isOpen={props.isOpen} handleClose={props.handleClose} />;
};

type DeleteConfirmationFormProps = {
  dataName: string
  data: any
  handleClose: () => void;
  handleDelete: (dataId: string) => void;
};

const DeleteConfirmationForm: React.FC<DeleteConfirmationFormProps> = (props) => {
  let { dataName, data, handleClose, handleDelete } = props;

  useEffect(() => {}, [dataName, data]);

  var deletionItemName = ''
  if (dataName === 'resource' || data?.name) {
    deletionItemName = data?.name ? `: ${data.name}` : ''
  } else if (data?.item_name || data?.date) {
    deletionItemName = data?.item_name ? `: ${data.item_name}` : ((data?.date) ? `: ${moment(data.date).format('ddd MMM DD, YYYY')}` : '')
  }

  return (
    <div className="flex flex-col container w-full">
      <h1 className="text-[24px] text-black font-bold font-raleway">
        Delete {dataName ? capitalizeWords(dataName) : 'Resource'}?
      </h1>
      <div className="text-[16px] w-full text-[#333333] my-2 font-open-sans">
        Are you sure you want to delete this {dataName ? dataName.toLowerCase(): 'resource'}<b>{deletionItemName}</b>? This action is permanent and cannot be undone.
      </div>
      <div className="flex flex-row justify-between w-full gap-x-1 my-2">
        <button
          className="w-1/2 font-raleway font-semibold text-center text-[#333333] bg-white border-[#E9E9E9] border-[1px] rounded "
          onClick={handleClose}
        >
          No, Cancel
        </button>
        <button
          className="w-1/2 h-10 text-center text-white bg-[#D42620] rounded-md font-raleway font-semibold"
          onClick={()=>handleDelete(data.id)}
          data-testid="delete-button"
        >
          Yes, Confirm
        </button>
      </div>
    </div>
  );
};

type NewMessageFormProps = {
  handleClose: () => void;
};

const NewMessageForm: React.FC<NewMessageFormProps> = (props) => {
  const { handleClose } = props
  const { newMessageMember } = useMemberState();
  const { switchMember } = useLoadMessages()
  

  // const capitalizeString = (string: string) =>
  //   string?.replace(/\w/, (c) => c?.toUpperCase());

  let get_primary_text = (member?: string) => {
    return (
      <p className="font-open-sans font-extrabold text-[14px] text-[#2D3748]">
        {member === "chatbot" ? capitalizeString(member) : member}
      </p>
    );
  };
  const handleNewMessage = () => {
    switchMember(newMessageMember);
    handleClose();
  }
  return (
    <div className="flex flex-row container space-x-2 space-y-2 justify-center align-center w-[500px] ">
      <div className="flex flex-col w-full">
      <div className="flex flex-col border-b-2 w-full text-center p-2">
        <img src="/logo192.png" alt="homecook-logo" className="w-[110px] h-[82px] self-center"/>
        <h1 className="text-2xl text-primary-200 text-center">Start New Message</h1>
        <h1 className="text-base text-back-200 text-center">Choose a user to message below</h1>

      </div>
        <div className="text-xl border-b-2  flex flex-row px-2 pt-2">
          <h1 className="mt-1">To: </h1>{" "}
          {newMessageMember ? (
            <div
              className={`flex justify-start m-1 px-5 cursor-pointer h-[40px] rounded `}
            >
              <Avatar
                className={`h-[30px] w-auto ${
                  newMessageMember === "chatbot"
                    ? "text-[#51a0b5]/90"
                    : "text-[#013847]/90"
                }`}
              />
              <div className="flex flex-col mx-4">
                <div>{get_primary_text(newMessageMember)}</div>
              </div>
            </div>
          ) : null}
        </div>
        <hr />
        <ul>
          <AddMessageList />
        </ul>
        <button className="buttonsPrimary text-black p-1 m-2" onClick={() => handleNewMessage()}>Start Chat</button>
      </div>
    </div>
  );
};





type ResourceInfoFormProps = {
  resource: ResourceSchema
  // currentResource: ResourceSchema
  resourceType: ResourceType
  updateResourceData?: (resource: ResourceSchema, resourceOptionTypes?: ResourceOptionsPropsType) => void
  // setResource: React.Dispatch<React.SetStateAction<ResourceSchema>>
  updateResourceOptions: (updateData: ResourceOptionsPropsType) => void
}

export const ResourceInfoForm: React.FC<ResourceInfoFormProps> = ({resourceType, resource, updateResourceData, updateResourceOptions}) => {
  const zipCodeRegex = /^\d{5}(-\d{4})?$/;

  const [currentResource, setCurrentResource] = useState<ResourceSchema>(resource)
  const [currentResourceOptions, setResourceOptions] = useState<ResourceOptionType>(currentResource?.resource_options as ResourceOptionType);
  const [selectedDays, setSelectedDays] = useState<selectedDaysType>({} as selectedDaysType)

  const currentResourceName = currentResource?.name ? currentResource?.name : ''


  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    e.preventDefault()
    const { name, value } = e.target
    // console.log("name, value ", name, value )
    if (name && (value || value === "")){
      setCurrentResource(prev => (
        {
          ...prev,
          [name]: value
        }
      ))
    }
  }

  const handleSave = () => {
    if ( 
      ((currentResource?.name !== undefined && resource?.name !== undefined) && (currentResource?.name !== resource?.name)) ||
      // (currentResource?.description && (currentResource?.description !== resource?.description)) ||
      (currentResource?.description && (currentResource?.description !== resource?.description)) ||
      (currentResource?.address && (currentResource?.address !== resource?.address)) ||
      (currentResource?.city && (currentResource?.city !== resource?.city)) ||
      (currentResource?.state && (currentResource?.state !== resource?.state)) ||
      ((currentResource?.zipcode && zipCodeRegex.test(currentResource.zipcode)) && (currentResource?.zipcode !== resource?.zipcode))){
      handleSaveChanges()
    }
    
    if (currentResourceOptions !== resource?.resource_options ){
      handleOptionsSaveChanges()
    }
  }

  const handleSaveChanges = () => {
    var changes = getChangedAttributes<ResourceSchema>(resource as ResourceSchema, currentResource as ResourceSchema);
    console.log("changes", changes)
    if (Object.keys(changes).length > 0 && updateResourceData !== undefined){
      updateResourceData(changes)
    }
  }

  const handleOptionsSaveChanges = () => {   
    var optionsChanges = currentResourceOptions
    if (optionsChanges && optionsChanges?.last_updated){
      delete optionsChanges['last_updated']
    }
    const updateOptions = {
      resourceOptions: optionsChanges,
      selectedDays: selectedDays
    }
    
    if (updateResourceOptions !== undefined){
      updateResourceOptions(updateOptions)
    }
  }


  // const resourceName = currentResource?.name ? currentResource?.name : ''
  // const resourceDescription = currentResource?.description ? currentResource?.description : ''


  useEffect(
    () => {
      document.title = `${resourceType ? capitalizeString(resourceType) : "Resource"} | Details`;
      setCurrentResource(resource)
      setResourceOptions(resource?.resource_options as ResourceOptionType)
      const defaultSelectedDays = {
        sun: (resource?.resource_options?.sun && resource?.resource_options.sun?.open_time && resource?.resource_options.sun?.close_time) ? true :  false,
        mon: (resource?.resource_options?.mon && resource?.resource_options.mon?.open_time && resource?.resource_options.mon?.close_time) ? true :  false, 
        tue: (resource?.resource_options?.tue && resource?.resource_options.tue?.open_time && resource?.resource_options.tue?.close_time) ? true :  false,
        wed: (resource?.resource_options?.wed && resource?.resource_options.wed?.open_time && resource?.resource_options.wed?.close_time) ? true :  false, 
        thu: (resource?.resource_options?.thu && resource?.resource_options.thu?.open_time && resource?.resource_options.thu?.close_time) ? true :  false, 
        fri: (resource?.resource_options?.fri && resource?.resource_options.fri?.open_time && resource?.resource_options.fri?.close_time) ? true :  false, 
        sat: (resource?.resource_options?.sat && resource?.resource_options.sat?.open_time && resource?.resource_options.sat?.close_time) ? true :  false
      }
      setSelectedDays(defaultSelectedDays)
    }, // eslint-disable-next-line
    [resource, resourceType]
  );

  // console.log("resource?.resource_options?.sun", typeof resource?.resource_options?.sun)
  // console.log("selectedDays", selectedDays)


  return (
  <>
    <div className="flex flex-row bg-white h-[75px] w-full border-t-[#E6E6E6] border-t-[1px] ">
      <p className="mx-[24px] my-auto font-open-sans text-[16px] text-[#4D4D4D]/70">
          View and edit your resource details.
      </p>
      {
        (currentResourceOptions !== resource?.resource_options ||
        ((currentResource?.name !== undefined && resource?.name !== undefined) && (currentResource?.name !== resource?.name)) ||
        (currentResource?.description && (currentResource?.description !== resource?.description)) ||
        ((currentResource?.address !== undefined && resource?.address !== undefined) && (currentResource?.address !== resource?.address)) ||
        (currentResource?.city && (currentResource?.city !== resource?.city)) ||
        ((currentResource?.state !== undefined && resource?.state !== undefined) && (currentResource?.state !== resource?.state)) ||
        (currentResource?.zipcode  && (currentResource?.zipcode !== resource?.zipcode))
        ) ? (
        <div className="flex ml-auto mx-[24px] h-auto font-open-sans">
          <button 
              className="text-primary-200 font-open-sans h-[43px] px-2 border-[1px] border-[#E9E9E9] ml-auto rounded-lg my-auto text-[16px]"
              onClick={handleSave}
            >
              Save Changes
          </button>
          {/* Add Cancel button and reset to api data */}
        </div>
        ) : (
          <></>
        )
      }
    </div> 
    <div className="flex flex-row">
      <div className={`flex flex-col ${(resourceType === "kitchen") ? "w-7/12" : "w-11/12"}  mx-auto font-open-sans h-auto`}>
        <div className="flex flex-col bg-white my-3 h-[178px] w-full rounded-lg">
          <div className="m-[24px] my-auto">
            <p className="text-[#808080] text-[16px] font-raleway my-2">{capitalizeString(resourceType)} Name</p>
            <input name="name" value={currentResourceName} onChange={handleChange} className="border-[#E9E9E9] h-[51px] border-[1px] w-full rounded-md"  />
          </div>
        </div>

        <div className="flex flex-col bg-white my-3 h-[247px] w-full rounded-lg">
          <div className="m-[24px] my-auto">
            <p className="text-[#808080] text-[16px] font-raleway my-2">{capitalizeString(resourceType)} Description</p>
            <textarea name="description" value={currentResource?.description} onChange={handleChange} className="border-[#E9E9E9] h-[120px] border-[1px] w-full rounded-md" />
          </div>
        </div>

        <div className="flex flex-col bg-white my-3 h-[178px] w-full rounded-lg">
          <div className='flex flex-row my-auto m-[24px]'>
            <div className='my-4 mr-1 w-1/4'>
              <p className="text-[#808080] text-[16px] font-raleway my-2">City</p>
              <input 
                type='text' 
                placeholder='Enter City' 
                className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
                name="city"
                value={currentResource?.city ? currentResource?.city : ''}
                onChange={handleChange}
              />
            </div>
            <div className='my-4 mr-1 w-1/4'>
              <p className="text-[#808080] text-[16px] font-raleway my-2">State</p>
              <select 
                name="state" 
                value={currentResource?.state} 
                className="border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]"
                onChange={handleChange}
              >
                <option value="">-- Select State --</option>
                <option value="nationwide">All</option>
                {
                    (usStates && usStates.length > 0) && usStates.map((state, i) => (
                        <option value={state} key={i}>{USStatesEnum[state]?.fullName}</option>
                    ))
                }
              </select>
            </div>
            <div className='my-4 w-1/4'>
              <p className="text-[#808080] text-[16px] font-raleway my-2">Zipcode</p>
              <input 
                type='text' 
                placeholder='Enter Zipcode' 
                className='border-[#E9E9E9] border-[1px] w-full rounded-md h-[50px] text-[16px]'
                name="zipcode"
                value={currentResource?.zipcode ? currentResource?.zipcode : ''}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>

      { 
        (resourceType === "kitchen") && (
          <div className="flex flex-col w-4/12 mx-auto font-open-sans h-auto">
            <div className="flex flex-col bg-white my-3 h-auto w-full rounded-lg">
              <div className="m-[24px]">
                <p className="text-[#808080] text-[18px] font-raleway">Operating Hours</p>
                <OperatingHoursList 
                  resourceOptions={currentResourceOptions} 
                  setResourceOptions={setResourceOptions} 
                  selectedDays={selectedDays}
                  setSelectedDays={setSelectedDays}
                />
              </div>
            </div>
          </div>
        )
      }

    </div>
  </>
  )
}


type ResourcePlanFormProps = {
  // communityId?: string
  resourceId?: string
  resourcePlans: PlanSchema[]
  setIsOpenPlan: React.Dispatch<React.SetStateAction<boolean>>
}
export const ResourcePlansForm: React.FC<ResourcePlanFormProps> = ({resourceId, resourcePlans, setIsOpenPlan}) => {

  const { plans } = useLoadPlans()
  // const {  primaryCommunity }= useLoadCommunity()

  
  const [selectedPlans, setSelectedPlans] = useState<PlanSchema[]>(resourcePlans)

  const { updateResourcePlans, isLoading } = useResourceHook({resourceId})

  const handleUpdateResourcePlans = () => {
    // console.log("selectedPlans", selectedPlans)
    if(selectedPlans.length > 0){
      var planIds = selectedPlans.map(plan => plan.plan_id ? plan.plan_id : "");
      updateResourcePlans(planIds)
    } else {
      updateResourcePlans([])
    }
  }

  // Check if item already exists in the array & add item if it doesn't exist
  const addSelection = (addedPlan: PlanSchema) => {
    const { plan_name, plan_id } = addedPlan
    const exists = selectedPlans.find((item: PlanSchema) => (item.plan_name === plan_name || item.plan_id === plan_id));
    if (!exists) {
      addedPlan.resource_id = resourceId
      setSelectedPlans(
        [
          ...selectedPlans, 
          addedPlan
        ]
      )
    }
  };

  // Remove item by filtering out the item with the given name
  const removeSelection = (planId: string) => {
    if (planId){
      setSelectedPlans(selectedPlans.filter((item: PlanSchema) => item.plan_id !== planId))
    }
  };


  useEffect(() => {
    document.title = "HomeCook | Member Plans";
  }, []);

  


  const planChangesExist = (): boolean => {
    // const resourcePlanIds = resourcePlans.map(plan => plan?.id ? plan.id : "");
    const resourcePlanIds = resourcePlans.map(plan => plan?.plan_id).filter((id): id is string => id !== undefined);
    // const selectedPlanIds = selectedPlans.map(plan => plan?.id ? plan.id : "");
    const selectedPlanIds = selectedPlans.map(plan => plan?.plan_id).filter((id): id is string => id !== undefined);
    const set1 = new Set(resourcePlanIds);
    const set2 = new Set(selectedPlanIds);
    return set1.size === set2.size && Array.from(set1).every(value => set2.has(value));
  };

  if (isLoading){
    return (
      <LoadingScreen bgColor="white" height="h-auto" />
    )
  }

  return (
    <>
      <div className="flex flex-row bg-white h-[75px] w-full border-t-[#E6E6E6] border-t-[1px] ">
      {
        (!planChangesExist()) ? (
        <div className="flex ml-auto mx-[24px] h-auto font-open-sans">
          <button 
              className="text-primary-200 font-open-sans h-[43px] px-2 border-[1px] border-[#E9E9E9] ml-auto rounded-lg my-auto text-[16px]"
              onClick={handleUpdateResourcePlans}
            >
              Save Changes
          </button>
        </div>
        ) : (
          <></>
        )
      }
      </div>
      <div className="flex flex-row bg-white my-3  rounded-lg w-11/12 mx-auto font-open-sans h-auto">
        <div className="m-[24px] w-full my-auto">
          <p className="text-[#808080] text-[16px] font-raleway my-2">Membership Plans</p>
          {
            (plans && plans.length > 0) ? (
              plans.map((plan: PlanSchema, i: number)=> (
                  (selectedPlans && selectedPlans.length > 0 && selectedPlans.some((p: PlanSchema) => plan.plan_name === p.plan_name)) ? (
                    <div 
                      key={i} 
                      className={`flex flex-row bg-primary-200 text-white text-[16px] font-open-sans rounded-md my-1 p-2 h-[59px] cursor-pointer`}
                      onClick={() => removeSelection(plan.plan_id ? plan.plan_id : "")}
                    >
                        <CheckBox className="mx-2 my-auto" style={{height: 20, width: 20}} />
                        <p className="my-auto">
                          {plan.plan_name}
                          <span className="text-white/40 mx-1 text-xs">
                            ({plan.plan_recurrence && capitalizeString(plan.plan_recurrence)}: ${plan.plan_rate})
                          </span>
                        </p>
                    </div>
                  ) : (
                      <div 
                        key={i} 
                        className={`flex flex-row border-[1px] border-[#E9E9E9] text-[16px] font-open-sans rounded-md my-1 p-2 h-[59px] cursor-pointer`}
                        onClick={() => addSelection(plan)}
                      >
                        <CheckBoxOutlineBlank className="mx-2 text-[#CFCFCF] my-auto" style={{height: 20, width: 20}} />
                        <p className="my-auto">
                          {plan.plan_name} 
                          <span className="text-[#CFCFCF] mx-1 text-xs">
                            ({plan.plan_recurrence && capitalizeString(plan.plan_recurrence)}: ${plan.plan_rate})
                          </span>
                        </p>
                    </div>
                  )
                ))
            ) : (<></>)
          }
          <div 
            className={`flex flex-row bg-[#F8F8F8] text-[16px] font-open-sans rounded-lg my-1 p-2 h-[59px] cursor-pointer`}
            onClick={()=>setIsOpenPlan(true)}
          >
            <AddCircle className="my-auto mx-2" style={{height: 17, width: 17}} />
            <p className="my-auto text-[16px] font-open-sans text-black">Add Custom Plan</p>
          </div>
        </div>
      </div>
    </>
  )
}



type ResourceRequirementsFormProps = {
  // communityId: string
  resourceId: string
  resourceRequirements: RequirementSchema[]
  communityRequirements: RequirementSchema[]
  setIsAddingCustomRequirement: React.Dispatch<React.SetStateAction<boolean>>
}

export const ResourceRequirementsForm: React.FC<ResourceRequirementsFormProps> = ({resourceId, communityRequirements, resourceRequirements, setIsAddingCustomRequirement}) => {

  
  // const { requirements } = useLoadRequirements()
  const [selectedRequirements, setSelectedRequirements] = useState<RequirementSchema[]>(resourceRequirements)

  const { updateResourceRequirements, isLoading, isError, isSuccess } = useResourceHook({resourceId})

  const handleUpdateResourceRequirements = () => {
    if(selectedRequirements.length > 0){
      var requirementIds = selectedRequirements.map(requirement => requirement.code ? requirement.code : undefined).filter((code): code is string => code !== undefined);
      updateResourceRequirements(requirementIds)
    } else {
      updateResourceRequirements([])
    }
  }


  const requirementChangesExist = (): boolean => {
    // console.log("resourceRequirements", resourceRequirements)
    const resourceRequirementIds = resourceRequirements.map(req => req?.code).filter((code): code is string => code !== undefined);
    // console.log("resourceRequirementIds", resourceRequirementIds)
    // console.log("selectedRequirements", selectedRequirements)
    const selectedRequirementIds = selectedRequirements.map(req => req?.code).filter((code): code is string => code !== undefined);
    // console.log("selectedRequirementIds", selectedRequirementIds)
    const set1 = new Set(resourceRequirementIds);
    const set2 = new Set(selectedRequirementIds);
    return set1.size === set2.size && Array.from(set1).every(value => set2.has(value));
  };

  // Check if item already exists in the array & add item if it doesn't exist
  const addSelection = (addedRequirement: RequirementSchema) => {
    const { name, code } = addedRequirement
    const exists = selectedRequirements.find((item: RequirementSchema) => item.name === name);
    if (!exists) {
      setSelectedRequirements(
        [
          ...selectedRequirements, 
          {
            name, 
            code,
            // community_id: communityId,
            resource_id: resourceId ? resourceId : ""
          }
        ]
      )
    }
  };

  // Remove item by filtering out the item with the given name
  const removeSelection = (requirementName: string) => {
    setSelectedRequirements(selectedRequirements.filter((item: RequirementSchema) => item.name !== requirementName))
  };


  useEffect(() => {
    document.title = "HomeCook | Resource Requirements";
  }, []);

  if (isLoading){
    return (
      <LoadingScreen bgColor="white" height="h-auto" />
    )
  } 
  return (
    <div className="flex flex-col w-11/12 bg-white mx-auto my-6 rounded-md h-[677px]">

      <div className="flex flex-row w-auto mx-[24px] my-4">
        <p className="text-[#4D4D4D] text-[16px] font-open-sans w-[313px]">
          Choose requirements from the list below, or add your own.
        </p>
        {        
          (
            (!requirementChangesExist())
          ) ? (
            <button 
              className="bg-primary-200 text-white ml-auto w-[210px] rounded-md text-[16px] font-raleway"
              onClick={handleUpdateResourceRequirements}
            >
              <CheckCircle className="my-auto mx-2 text-white" style={{height: 17, width: 17}} />
              Save Changes
            </button>
          ) : (<></>)
        }
      </div>
      {
        (isSuccess && !isError && !isLoading) && (
          <div className="flex flex-row w-auto font-raleway text-gray-600 text-center mx-[24px] my-4 h-[50px] bg-green-200 rounded-md">
            <p className="m-auto">Update Successful</p>
          </div>
        )
      }
      {
        (isError) && (
          <div className="flex flex-row w-auto font-raleway text-gray-700 text-center mx-[24px] my-4 h-[50px] bg-red-200 rounded-md">
            <p className="m-auto">Update Failed</p>
          </div>
        )
      }
      <div className="flex flex-col w-auto mx-[24px] my-4 h-[777px] overflow-y-scroll">
          {
          (communityRequirements && communityRequirements?.length > 0) ? (
            communityRequirements.map((req, i: number)=> (

              (selectedRequirements && selectedRequirements.length > 0 && selectedRequirements.some((requirement: RequirementSchema) => requirement.code === req.code)) ? (
                <div 
                  key={i} 
                  className={`flex flex-row bg-primary-200 text-white text-[16px] font-open-sans rounded-md my-1 p-2 h-[59px] cursor-pointer`}
                  onClick={() => removeSelection(req?.name as string)}
                >
                    <CheckBox className="mx-2 my-auto" style={{height: 20, width: 20}} />
                    <p className="my-auto">{req?.name}</p>
                </div>
              ) : (
                  <div 
                    key={i} 
                    className={`flex flex-row border-[1px] hover:bg-[#E9E9E9] border-[#E9E9E9] text-[16px] font-open-sans rounded-md my-1 p-2 h-[59px] cursor-pointer`}
                    onClick={() => addSelection(req)}
                  >
                    <CheckBoxOutlineBlank 
                      className="mx-2 text-[#CFCFCF] my-auto" 
                      style={{height: 20, width: 20}} 
                    />
                  <p className="my-auto">{req?.name}</p>
                </div>
              )
            ))
          ) : (<></>)
          }
      </div>
      <div onClick={() => setIsAddingCustomRequirement(true)} className={`flex flex-row bg-[#F8F8F8] text-[16px] font-open-sans rounded-lg mx-2 p-2 h-[59px] cursor-pointer`}>
            <AddCircle className="my-auto mx-2" style={{height: 17, width: 17}} />
            <p className="my-auto text-[16px] font-open-sans text-black">Add Custom Requirement</p>
          </div>
    </div>
  )
}



type ResourceImagesFormProps = {
  resource: ResourceSchema
  setResource: React.Dispatch<React.SetStateAction<ResourceSchema>>
  currentResource: ResourceSchema
  resourceType: ResourceType
  updateResourceData?: (resource: ResourceSchema, resourceOptionTypes?: ResourceOptionsPropsType) => void
  // handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>)=> void
}
export const ResourceImagesForm: React.FC<ResourceImagesFormProps> = ({resourceType, resource, currentResource, setResource, updateResourceData}) => {

  resource = resource ? resource : currentResource

  const [selectedbgPhoto, setSelectedbgPhoto] = useState(resource.bgPhoto);
  const [selectedLogo, setSelectedLogo] = useState(resource.logo);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, files} = event.target
    const file = files?.[0]
    if (file) {
      // Read the selected image file as a data URL
      const reader = new FileReader();
      reader.onload = (e) => {
        if (name === "logo") {
          setSelectedLogo(e.target?.result as string);
        } else if (name === "bgPhoto") {
          setSelectedbgPhoto(e.target?.result as string);
        }
      };
      reader.readAsDataURL(file);
    } else {
      if (name === "logo") {
        setSelectedLogo("");
      } else if (name === "bgPhoto") {
        setSelectedbgPhoto("");
      }
    }
    handleFileChange(event);
  };


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;
    if (name && files && files.length > 0) {
      let file_data = files[0];
      setResource((prevResourceData) => ({
        ...prevResourceData,
        [name]: file_data,
      }));
    }
  };

  const handleSaveChanges = () => {
    resource.logo = selectedLogo
    resource.bgPhoto = selectedbgPhoto

    if (!currentResource?.logo){
      currentResource.logo = ""
    }
    if (!currentResource?.bgPhoto){
      currentResource.bgPhoto = ""
    }

    var changes = getChangedAttributes<ResourceSchema>(currentResource, resource);
    if (Object.keys(changes).length > 0 && updateResourceData !== undefined){
      updateResourceData(changes)
    }
  }

  useEffect(() => {
    document.title = "HomeCook | Logo & Images";
  }, [resource]);

  return ( 
    <>
      <div className="flex flex-row bg-white h-[75px] w-full border-t-[#E6E6E6] border-t-[1px] ">
        <p className="mx-[24px] my-auto font-open-sans text-[16px] text-[#4D4D4D]/70">
            View and edit your resource images.
        </p>
        {
          (
            ((currentResource?.bgPhoto !== resource?.bgPhoto)) ||
            ((currentResource?.logo !== resource?.logo))
          ) ? (
            <div className="flex ml-auto mx-[24px] h-auto font-open-sans">
              <button
                className="text-primary-200 h-[43px] px-2 border-[1px] border-[#E9E9E9] ml-auto rounded-lg my-auto text-[16px]"
                onClick={handleSaveChanges}
              >
                Save Changes
              </button>
            </div>
          ) : (
            <></>
          )
        }
      </div> 
      <div className="flex flex-col w-11/12 mx-auto font-open-sans h-auto">
        <div className="flex flex-col bg-white my-3 h-[324px] w-full rounded-lg">
          <div className="flex flex-col m-[24px]">
            <p className="text-[16px] font-raleway font-semibold text-black">Upload Logo</p>
            <p className="text-[16px] font-open-sans font-light text-[#4D4D4D]">Upload a logo to represent your {resourceType}.</p>
            <>
            <div className="flex flex-row text-2xl text-[#000] h-[206px] border-[1px] rounded-lg border-[#E9E9E9] my-4">
              <CustomAddImageBox name="logo" height="158px" width="200px" selectedImage={selectedLogo} setSelectedImage={setSelectedLogo} handleChange={handleImageChange} />
              <div className="flex flex-col font-open-sans text-[16px] text-[#4D4D4D] my-auto mx-2">
                <span><CheckCircle className="text-primary-400 mx-1" /> Accepted formats: JPEG (.jpg, .jpeg), PNG (.png), SVG (.svg)</span>
                <span><CheckCircle className="text-primary-400 mx-1" /> At least 500x500 pixels</span>
                <span><CheckCircle className="text-primary-400 mx-1" /> Maximum file size: 5MB</span>
                <span><CheckCircle className="text-primary-400 mx-1" /> Transparent background preferred for versatility</span>
              </div>
            </div>
            </>
          </div>
        </div>

        <div className="flex flex-col bg-white my-3 h-[324px] w-full rounded-lg">
          <div className="flex flex-col m-[24px]">
          <p className="text-[16px] font-raleway font-semibold text-black">Upload Resource Images</p>
          <p className="text-[16px] font-open-sans font-light text-[#4D4D4D]">Upload clear images that showcase your resource.</p>
            <>
            <div className="flex flex-row text-2xl text-[#000] w-full h-[206px] border-[1px] rounded-lg border-[#E9E9E9] my-4">
              <CustomAddImageBox name="bgPhoto" height="158px" width="340px" selectedImage={selectedbgPhoto} setSelectedImage={setSelectedbgPhoto} handleChange={handleImageChange} />
            </div>
            </>
          </div>
        </div>
      </div>
    </>
  )
}

export {
  AddKitchenForm,
  AddReservationForm,
  DeleteConfirmationForm,
  NewMessageForm,
  AddMemberForm,
  AddPlanForm,
  AddInventoryForm,
  AddRequirementForm,
  AddCredentialForm,
};
