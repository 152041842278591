import React from "react";
import { useNavigate } from "react-router-dom";

import { ResourceSchema, ResourceType } from "../data/types";
import { useMemberState } from "../contexts/membercontext";
import { getDirectLinkFromGoogleDriveSharedLink } from '../utils/helpers';

type ResourceRecordProps = {
  resource: ResourceSchema;
  resourceType: ResourceType
};

const ResourceRecord: React.FC<ResourceRecordProps> = (props) => {
  var navigate = useNavigate();

  var { memberships } = useMemberState()
  var { resource, resourceType  } = props;

  const openResourceView = () => {
    navigate(`/resources/${resourceType}/${resource.id}`);
  };


  return resource ? (
    <div className="font-open-sans grid grid-cols-1 w-96 h-auto bg-white rounded-xl p-2 " >
    <div className="">
      {
        getDirectLinkFromGoogleDriveSharedLink(resource?.bgPhoto) ? (
          <img className='col-span-1 w-96 h-52 rounded-2xl shadow-lg position-absolute object-cover' src={getDirectLinkFromGoogleDriveSharedLink(resource?.bgPhoto)} alt={resource?.name} /> 
        ) : (
            <>
              <div className='w-90 h-52 col-span-1 rounded-2xl position-absolute bg-[#D9D9D9]/70' ></div>
            </>
        )
      }
    </div>
    
    <span className="flex flex-row w-full">
      <h1 className="text-[20px] font-raleway m-1 text-left font-medium">
        {resource.name}
      </h1>

      <span className={`flex flex-row rounded-xl ml-auto my-2 p-1 ${resource?.active ? 'bg-[#E7F5EC]' : 'bg-red-100'} `}>
        <div className={`${resource?.active ? 'bg-[#0F973D]' : 'bg-red-800'} h-2 w-2 rounded-full my-auto m-1`} />
        <p className={`${resource?.active ? 'text-[#0F973D]/90' : 'text-red-900/90'} m-auto text-[12px] font-open-sans font-light`}>{resource?.active ? "Active": "Inactive"}</p>
      </span>
    </span>

    <div className="contain text-left">
      {
        (resource.address || resource.city || resource.state || resource.zipcode) && (
          <p className="text-[#4D4D4D] text-[16px] m-1 font-open-sans">
            {resource.address ? `${resource.address}, `: ``} 
            {resource.city ? `${resource.city}, `: ``} 
            {resource.state ? `${resource.state} `: ``}  
            {resource.zipcode} 
          </p>
        )
      }

      <div className="text-[#333333] text-[16px] my-2 m-1 p-1 text-left bg-[#F5F5F5] w-[116px] font-raleway rounded-lg">
        <p className="text-center">{(memberships && memberships.length > 0) ? (memberships.length) : 0} Member{memberships.length ? '' : 's'}</p>
      </div>
    </div>
    <div className="flex bg-primary-200 h-20 text-[16px] font-raleway rounded-b-xl  text-white ">
      <button className="mx-auto bg-white text-primary-200 h-10 my-auto rounded-lg p-2 font-semibold"  onClick={openResourceView}>Manage Resource</button>
    </div>
  </div>
  ) : null;
};

export default ResourceRecord;
